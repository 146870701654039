import React, { createContext, useContext } from 'react';
import { useLocalStorageState } from '../common/common.hook';
import { UserInterface } from '../interface/user.interface';
import { throwContextError } from '../common/util.common';
import { UserNotificationSubscriptionInterface } from '../interface/user-notification-subscription.interface';
import { ActivationEnum } from '../enum/activation.enum';
import { changeSubscriptionStatus } from '../service/notification.service';

const businessContext = 'Auth';

interface AuthContextInterface {
  authenticatedUser: UserInterface;
  setAuthenticatedUser: (authenticatedUser: UserInterface) => void;

  lastUsersSearched: UserInterface[];
  setLastUsersSearched: (lastUsersSearched: UserInterface[]) => void;

  token: string;
  setToken: (token: string) => void;
  setAuthenticatedUserAndToken: (
    authenticatedUser: UserInterface,
    token: string,
  ) => void;
  logout: () => Promise<void>;

  userNotificationSubscription: UserNotificationSubscriptionInterface;
  setUserNotificationSubscription: (
    userNotifcationSubscription: UserNotificationSubscriptionInterface,
  ) => void;
  setAskForNotificationPermission: (askForPermission: boolean) => void;
}

const AuthContext = createContext<AuthContextInterface>(null);

export function AuthProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const [authenticatedUser, setAuthenticatedUser] = useLocalStorageState<
    UserInterface
  >(null, 'AUTHENTICATED_USER', (value): UserInterface => JSON.parse(value));

  const [lastUsersSearched, setLastUsersSearched] = useLocalStorageState<
    UserInterface[]
  >([], 'LAST_USERS_SEARCHED', (value): UserInterface[] => JSON.parse(value));

  const [
    userNotificationSubscription,
    setUserNotificationSubscription,
  ] = useLocalStorageState<UserNotificationSubscriptionInterface>(
    {
      userId: null,
      subscriptionId: null,
      askForPermission: true,
    },
    'USER_NOTIFICATION_SUBSCRIPTION',
    (value): UserNotificationSubscriptionInterface => JSON.parse(value),
  );

  const [token, setToken] = useLocalStorageState<string>(
    null,
    'TOKEN',
    (value): string => value,
  );

  const setAskForNotificationPermission = (askForPermission: boolean): void => {
    const { subscriptionId, userId } = userNotificationSubscription;
    setUserNotificationSubscription({
      askForPermission,
      subscriptionId,
      userId,
    });
  };

  const setAuthenticatedUserAndToken = (
    authenticatedUser: UserInterface,
    token: string,
  ): void => {
    setAuthenticatedUser(authenticatedUser);
    setToken(token);
  };

  const logout = async (): Promise<void> => {
    const { subscriptionId, userId } = userNotificationSubscription;
    setAskForNotificationPermission(true);
    setAuthenticatedUser(null);
    if (userId === authenticatedUser?.id)
      await changeSubscriptionStatus(
        token,
        subscriptionId,
        ActivationEnum.INACTIVE,
      );
    setToken(null);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        authenticatedUser,
        setAuthenticatedUser,
        lastUsersSearched,
        setLastUsersSearched,
        userNotificationSubscription,
        setUserNotificationSubscription,
        setAskForNotificationPermission,
        token,
        setToken,
        setAuthenticatedUserAndToken,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext(): AuthContextInterface {
  const context = useContext(AuthContext);
  if (!context) throwContextError(businessContext);
  return context;
}
