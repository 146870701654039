import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { ContainerComponent } from '../component/container.component';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { useMenuContext } from '../context/menu.context';
import { useSnackbarContext } from '../context/snackbar.context';
import { useAuthContext } from '../context/auth.context';
import { updateUser } from '../service/user.service';
import { phoneRegExp } from '../common/regex.common';
import InputMask from 'react-input-mask';
import { VagasIcon } from '../icons';

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      marginTop: '1em',
    },
    logo: {
      display: 'block',
      marginTop: '35px',
      marginBottom: '30px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    saveButton: {
      marginTop: '2em',
      paddingLeft: 1,
    },
    changePasswordButton: {
      marginTop: '1em',
      paddingLeft: 1,
    },
  }),
);

const schema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório'),
  telephone: yup
    .string()
    .matches(phoneRegExp, {
      message: 'O número deve estar no formato (00) 00000-0000',
    })
    .max(15)
    .required('O telefone é obrigatório'),
});

interface MyDataInputInterface {
  name: string;
  telephone: string;
}

export function MyDataPage(): JSX.Element {
  const classes = useStyles(),
    [disabled, setDisabled] = useState<boolean>(true),
    { setMenuPageById, goToPageRouteById } = useMenuContext(),
    { authenticatedUser, token, setAuthenticatedUser } = useAuthContext(),
    { displaySnack } = useSnackbarContext(),
    { register, handleSubmit, errors, setValue } = useForm<
      MyDataInputInterface
    >({
      resolver: yupResolver(schema),
      mode: 'all',
      shouldFocusError: true,
    });

  useEffect(() => { setMenuPageById(MenuPageIdEnum.MY_DATA); },[]);

  useEffect((): void => {
    setValue('name', authenticatedUser?.name);
    setValue('telephone', authenticatedUser.telephone);
  }, [authenticatedUser, setValue]);

  const onInputChange = (): void => {
    setDisabled(false);
  };

  const handleChangePassword = (): void => {
    goToPageRouteById(MenuPageIdEnum.CHANGE_PASSWORD);
  };

  const onSubmit = async (myData: MyDataInputInterface): Promise<void> => {
    setDisabled(true);
    try {
      const response = await updateUser(token, myData.name, myData.telephone);

      if (response.id && response.name) {
        setAuthenticatedUser(response);
        displaySnack('Dados atualizados!', 'success', true);
      }
    } catch (message) {
      displaySnack(`${message}`, 'error', true);
    }
  };

  return (
    <ContainerComponent>
      <div>
        <VagasIcon className={classes.logo} fill="lightgray" fontSize={125} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            className={classes.textField}
            label="Nome"
            fullWidth
            name="name"
            onChange={onInputChange}
            error={!!errors.name}
            helperText={errors?.name?.message}
            inputRef={register}
          />
          <InputMask
            mask="(99) 99999-9999"
            defaultValue={authenticatedUser.telephone}
            onChange={onInputChange}
          >
            {(): JSX.Element => (
              <TextField
                className={classes.textField}
                label="Telefone"
                fullWidth
                name="telephone"
                error={!!errors.telephone}
                helperText={errors?.telephone?.message}
                inputRef={register}
              />
            )}
          </InputMask>

          <Button
            disabled={disabled}
            className={classes.saveButton}
            color="primary"
            type="submit"
          >
            SALVAR
          </Button>
        </form>
        <Button
          onClick={handleChangePassword}
          className={classes.changePasswordButton}
          color="primary"
        >
          ALTERAR SENHA
        </Button>
      </div>
    </ContainerComponent>
  );
}
