import React, { useEffect } from 'react';
import { useAuthContext } from '../context/auth.context';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { sendMail } from '../service/auth.service';
import { useMenuContext } from '../context/menu.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { TextField, makeStyles, Button } from '@material-ui/core';
import { ContainerComponent } from '../component/container.component';
import { useSnackbarContext } from '../context/snackbar.context';
import { VagasIcon } from '../icons';
interface MessageInterface {
  message: string;
}

const schema = yup.object().shape({
  message: yup.string().required('Campo obrigatório'),
});

const useStyles = makeStyles({
  textField: {
    marginTop: '1em',
    marginLeft: 1,
    marginRight: 1,
  },
  button: {
    marginTop: '1em',
    justifyContent: 'center',
  },
  logo: {
    display: 'block',
    marginTop: '45px',
    marginBottom: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export function TalkToUsPage(): JSX.Element {
  const classes = useStyles(),
    { authenticatedUser } = useAuthContext(),
    { token } = useAuthContext(),
    { displaySnack } = useSnackbarContext(),
    { setMenuPageById, setHeaderLoading } = useMenuContext(),
    { register, handleSubmit, errors, setValue } = useForm<MessageInterface>({
      resolver: yupResolver(schema),
      mode: 'all',
      shouldFocusError: true,
    });

  useEffect(() => { setMenuPageById(MenuPageIdEnum.CONTACT); },[]);

  const submit = async ({ message }: MessageInterface): Promise<void> => {
    setHeaderLoading(true);
    try {
      await sendMail(
        authenticatedUser.email,
        authenticatedUser.name,
        message,
        token,
      );
      displaySnack(
        'Mensagem enviada com sucesso. Em breve você receberá o retorno por e-mail',
        'success',
      );
      setValue('message', '');
    } catch (errorMessage) {
      displaySnack(errorMessage, 'error');
    } finally {
      setHeaderLoading(false);
    }
  };
  return (
    <ContainerComponent>
      <form onSubmit={handleSubmit(submit)}>
        <VagasIcon className={classes.logo} fill="lightgray" fontSize={125} />
        <TextField
          multiline
          className={classes.textField}
          label="Escreva sua mensagem"
          name="message"
          fullWidth
          type="text"
          error={!!errors.message}
          helperText={errors?.message?.message}
          inputRef={register}
        />
        <Button color="primary" className={classes.button} type="submit">
          Enviar
        </Button>
      </form>
    </ContainerComponent>
  );
}
