import React, { useState } from 'react';
import {
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  withStyles,
  makeStyles,
  createStyles,
  Grid,
  CircularProgress,
  Modal,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordion from '@material-ui/core/Accordion';
import { CondominiumInterface } from '../interface/condominium.interface';
import { ApartmentInterface } from '../interface/apartment.interface';
import { ParkingSpotInterface } from '../interface/parking-spot.interface';
import { useAuthContext } from '../context/auth.context';
import { useSnackbarContext } from '../context/snackbar.context';
import { approveOrRejectRequest } from '../service/user-request.service';
import { ApprovementBodyInterface } from '../interface/approvement-body.interface';
import { RequestTypeEnum } from '../enum/request-type.enum';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

interface RequestInterface {
  id: number;
  requestDate: string;
  requestType: string;
  requestStatus: { id: number; name: string; description: string };
  ownerCpf: string;
  ownerName: string;
  isTheLegalGuardian: boolean;
  condominium: Partial<CondominiumInterface>;
  apartment?: Partial<ApartmentInterface>;
  parkingSpot?: Partial<ParkingSpotInterface>;
}

interface Props {
  reloadPage: () => Promise<void>;
  userId: number;
  request: RequestInterface;
}

enum Action {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}

const useStyles = makeStyles(() =>
  createStyles({
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    accordionContainer: {
      flex: 1,
    },
    circularProgress: {
      position: 'fixed',
      bottom: 300,
      marginLeft: '50%',
      fontSize: 70,
    },
    accordion: {
      backgroundColor: '#f5f5f5',
    },
  }),
);

const ApartmentRequisitionComponet: React.FC<Partial<ApartmentInterface>> = (
  apartment: Partial<ApartmentInterface>,
) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography>Apartamento:</Typography>
        </Grid>
        <Grid item xs>
          <Typography>{apartment.apartment}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography>Andar:</Typography>
        </Grid>
        <Grid item xs>
          <Typography>{apartment.floor}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography>Bloco:</Typography>
        </Grid>
        <Grid item xs>
          <Typography>{apartment.block}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

const ParkingSpotRequisitionComponet: React.FC<Partial<
  ParkingSpotInterface
>> = (spot: Partial<ParkingSpotInterface>) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography>Vaga:</Typography>
        </Grid>
        <Grid item xs>
          <Typography>{spot.code}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography>Acesso:</Typography>
        </Grid>
        <Grid item xs>
          <Typography>{spot.gate}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography>Bloco:</Typography>
        </Grid>
        <Grid item xs>
          <Typography>{spot.block}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography>Andar:</Typography>
        </Grid>
        <Grid item xs>
          <Typography>{spot.pavement}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

const RequestItemComponent: React.FC<Props> = (prop) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const { token } = useAuthContext();
  const { displaySnack } = useSnackbarContext();

  const mountBodyForRequest = (): ApprovementBodyInterface => {
    const {
      apartment,
      isTheLegalGuardian,
      ownerName,
      ownerCpf,
      parkingSpot,
    } = prop.request;

    let approvementBody: ApprovementBodyInterface = {
      userRequestId: prop.request.id,
      userId: prop.userId,
      condominiumId: prop.request.condominium.id,
    };

    if (prop.request.requestType === RequestTypeEnum.APARTMENT)
      approvementBody = {
        ...approvementBody,
        apartment: {
          id: apartment.id,
          isTheLegalGuardian,
          ownerCpf,
          ownerName,
        },
      };
    else
      approvementBody = { ...approvementBody, parkingSpotId: parkingSpot.id };

    return approvementBody;
  };

  const handleApprove = async (action: Action): Promise<void> => {
    setLoading(true);
    const approvementBody = mountBodyForRequest();
    try {
      await approveOrRejectRequest(action, token, approvementBody);
      displaySnack(
        `${
          action === Action.APPROVE ? 'Aprovação' : 'Rejeição'
        } feita com sucesso!`,
        'success',
      );
    } catch (errorMessage) {
      displaySnack(errorMessage, 'error');
    }
    prop.reloadPage();
    setLoading(false);
  };

  const { request } = prop;
  return (
    <>
      <React.Fragment key={request.ownerCpf}>
        {isLoading ? (
          <Modal open>
            <div>
              <CircularProgress className={classes.circularProgress} />
            </div>
          </Modal>
        ) : null}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordion}
          >
            <Typography>
              {request.requestType === RequestTypeEnum.APARTMENT
                ? 'APARTAMENTO'
                : 'VAGA'}
              : {request.requestStatus.description}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.accordionContainer}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Typography>Data da requisição:</Typography>
                </Grid>
                <Grid item xs>
                  <Typography>
                    {new Date(request.requestDate).toLocaleDateString('pt')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Typography>CPF do proprietário:</Typography>
                </Grid>
                <Grid item xs>
                  <Typography>{request.ownerCpf}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Typography>Nome do proprietário:</Typography>
                </Grid>
                <Grid item xs>
                  <Typography>{request.ownerName}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Typography>Guardião legal:</Typography>
                </Grid>
                <Grid item xs>
                  <Typography>
                    {request.isTheLegalGuardian ? 'Sim' : 'Não'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Typography>Condomínio:</Typography>
                </Grid>
                <Grid item xs>
                  <Typography>{request.condominium.name}</Typography>
                </Grid>
              </Grid>
              {!!request.apartment ? (
                <ApartmentRequisitionComponet {...request.apartment} />
              ) : null}
              {!!request.parkingSpot ? (
                <ParkingSpotRequisitionComponet {...request.parkingSpot} />
              ) : null}

              <Grid container spacing={1}>
                <Grid item xs>
                  <div className={classes.buttons}>
                    <Button
                      disabled={isLoading}
                      variant="outlined"
                      color="primary"
                      onClick={(): Promise<void> =>
                        handleApprove(Action.APPROVE)
                      }
                    >
                      Aprovar
                    </Button>
                    <Button
                      disabled={isLoading}
                      variant="outlined"
                      color="secondary"
                      onClick={(): Promise<void> =>
                        handleApprove(Action.REJECT)
                      }
                    >
                      Reprovar
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    </>
  );
};

export default RequestItemComponent;
