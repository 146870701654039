/* eslint-disable complexity */
import React, { useState, ChangeEvent, useEffect } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { PaymentMethodEnum } from '../enum/paymeny-method.enum';
import { PicpayAccountBodyInterface } from '../interface/picpay-account-register.interface';
import { BankAccountBodyInterface } from '../interface/bank-account-register.interface';
import { cpfRegExp } from '../common/regex.common';
import InputMask from 'react-input-mask';
import { FormData } from '../common/form.type';
import { validateCPF } from '../common/util.common';

const useStyles = makeStyles({
  input: {
    marginTop: 5,
    marginBottom: 5,
  },
  inputPicPay: {
    marginTop: 5,
    marginBottom: 5,
    width: '100%',
  },
  button: {
    width: '100%',
    marginBottom: 5,
  },
  containedButton: {
    backgroundColor: '#5abefc',
    color: 'white',
  },
});

const picpaySchema = yup.object().shape({
  username: yup
    .string()
    .min(3, 'Usuário inválido')
    .required('Por favor informe seu usuário do Picpay'),
});

const bankTransferSchema = yup.object().shape({
  owner: yup
    .string()
    .min(5, 'O nome deve ser maior que 5 letras')
    .required('Por favor informe o titular'),
  cpf: yup
    .string()
    .length(14, 'Mínimo de 11 caracteres')
    .matches(cpfRegExp, {
      message: 'O CPF deve estar no formato 000.000.000-00',
    })
    .test('test-cpf', 'CPF inválido', (cpfValue) => {
      return validateCPF(cpfValue);
    })
    .required('Por favor informe CPF'),
  bankCode: yup
    .string()
    .max(30, 'Código bancário pode ter no máximo 30 dígitos')
    .required('Por favor informe o código'),
  bankName: yup.string().required('Por favor informe o nome do banco'),
  agency: yup
    .string()
    .min(3, 'Agência bancário deve ter no mínimo 3 dígitos')
    .max(30, 'Agência bancário pode ter no máximo 30 dígitos')
    .required('Por favor informe a agência'),
  account: yup
    .string()
    .min(3, 'Conta inválida')
    .max(30, 'A conta pode ter no máximo 30 caracteres')
    .required('Por favor informe a conta'),
});

const ButtonsComponent: React.FC<Pick<
  ReceivingMethodProps,
  'onAbort' | 'disabled'
>> = ({
  onAbort,
  disabled,
}: Pick<ReceivingMethodProps, 'onAbort' | 'disabled'>) => {
  const classes = useStyles();
  return (
    <Grid item container justify="space-between">
      <>
        <Button
          className={classes.button}
          variant="outlined"
          color="secondary"
          onClick={onAbort}
        >
          Cancelar
        </Button>
        {disabled || (
          <Button
            disabled={!!disabled || false}
            variant="contained"
            className={classes.containedButton + ' ' + classes.button}
            type="submit"
          >
            Confirmar
          </Button>
        )}
      </>
    </Grid>
  );
};

interface InputsProps {
  disabled: boolean;
  onConfirmSubmit: (data?: FormData) => void;
  onAbort: () => void;
  data?: FormData;
}

const PicpayInputsComponent: React.FC<InputsProps> = ({
  disabled,
  onAbort,
  onConfirmSubmit,
  data,
}: InputsProps) => {
  const classes = useStyles();
  const { register, handleSubmit, errors, setValue } = useForm<
    PicpayAccountBodyInterface
  >({
    resolver: yupResolver(picpaySchema),
    mode: 'all',
    shouldFocusError: true,
  });

  useEffect(() => {
    const auxiliaryData = data as PicpayAccountBodyInterface;
    if (!auxiliaryData?.username) return;
    setValue('username', auxiliaryData?.username);
  }, [data]);

  return (
    <form onSubmit={handleSubmit(onConfirmSubmit)}>
      <Grid container justify="center" direction="row">
        <TextField
          name="username"
          inputRef={register}
          error={!!errors.username}
          helperText={errors?.username?.message}
          disabled={!!disabled || false}
          variant="outlined"
          className={classes.inputPicPay}
          label="@UsuárioPicpay"
          InputLabelProps={{ shrink: true }} 
        />
        <ButtonsComponent onAbort={onAbort} disabled={disabled} />
      </Grid>
    </form>
  );
};

const BankTransferInputsComponent: React.FC<InputsProps> = ({
  disabled,
  onConfirmSubmit,
  onAbort,
  data,
}: InputsProps) => {
  const classes = useStyles();
  const { register, handleSubmit, errors, setValue } = useForm<
    BankAccountBodyInterface
  >({
    resolver: yupResolver(bankTransferSchema),
    mode: 'all',
    shouldFocusError: true,
  });

  useEffect(() => {
    const auxiliaryData = data as BankAccountBodyInterface;
    if (!auxiliaryData?.owner) return;
    setValue('cpf', auxiliaryData?.cpf);
    setValue('owner', auxiliaryData?.owner);
    setValue('account', auxiliaryData?.account);
    setValue('agency', auxiliaryData?.agency);
    setValue('bankCode', auxiliaryData?.bankCode);
    setValue('bankName', auxiliaryData?.bankName);
  }, [data]);

  return (
    <form onSubmit={handleSubmit(onConfirmSubmit)}>
      <Grid container justify="center" direction="column">
        <TextField
          name="owner"
          inputRef={register}
          error={!!errors.owner}
          helperText={errors?.owner?.message}
          disabled={!!disabled || false}
          variant="outlined"
          className={classes.input}
          label="Titular"
          InputLabelProps={{ shrink: true }} 
        />

        <TextField
          name="bankName"
          inputRef={register}
          error={!!errors.bankName}
          helperText={errors?.bankName?.message}
          disabled={!!disabled || false}
          variant="outlined"
          className={classes.input}
          label="Nome do banco"
          InputLabelProps={{ shrink: true }} 
        />
        <InputMask mask="999.999.999-99" disabled={!!disabled || false}>
          {(): JSX.Element => (
            <TextField
              name="cpf"
              inputRef={register}
              error={!!errors.cpf}
              helperText={errors?.cpf?.message}
              disabled={!!disabled || false}
              variant="outlined"
              className={classes.input}
              label="CPF"
              InputLabelProps={{ shrink: true }} 
            />
          )}
        </InputMask>
        <Grid
          item
          container
          justify="space-between"
          direction="row"
          spacing={1}
        >
          <Grid item xs={6}>
            <TextField
              name="bankCode"
              inputRef={register}
              error={!!errors.bankCode}
              helperText={errors?.bankCode?.message}
              disabled={!!disabled || false}
              variant="outlined"
              className={classes.input}
              type="number"
              label="Cód. do Banco"
              InputLabelProps={{ shrink: true }} 
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="agency"
              inputRef={register}
              error={!!errors.agency}
              helperText={errors?.agency?.message}
              disabled={!!disabled || false}
              variant="outlined"
              className={classes.input}
              type="number"
              label="Agência"
              InputLabelProps={{ shrink: true }} 
            />
          </Grid>
        </Grid>

        <TextField
          name="account"
          inputRef={register}
          error={!!errors.account}
          helperText={errors?.account?.message}
          disabled={!!disabled || false}
          variant="outlined"
          className={classes.input}
          label="Conta"
          InputLabelProps={{ shrink: true }} 
        />

        <ButtonsComponent onAbort={onAbort} disabled={disabled} />
      </Grid>
    </form>
  );
};

interface ReceivingMethodProps {
  onAbort: () => void;
  onConfirmSubmit: (data?: FormData) => void;
  type?: PaymentMethodEnum;
  disabled?: boolean;
  data?: FormData;
}

const ReceivingMethodFormComponent: React.FC<ReceivingMethodProps> = ({
  onAbort,
  onConfirmSubmit,
  disabled,
  type,
  data,
}: ReceivingMethodProps) => {
  const classes = useStyles();
  const [method, setMethod] = useState<string>(type);
  const [formData, setFormData] = useState<FormData>(data);
  const handleMethodChange = ({
    target,
  }: ChangeEvent<{ value: unknown }>): void => setMethod(String(target.value));

  useEffect(() => setMethod(type), [type, data]);
  useEffect(() => setFormData(data), [method, data]);

  return (
    <Grid container justify="center" direction="row">
      <>
        {!!type || (
          <FormControl variant="outlined" required>
            <InputLabel htmlFor="receiving-method-select">
              Método de recebimento
            </InputLabel>
            <Select
              onChange={handleMethodChange}
              className={classes.input}
              defaultValue={'first'}
              value={method ? method : 'first'}
              label="Método de recebimento"
              inputProps={{
                id: 'receiving-method-select',
                name: 'receiving-method',
              }}
            >
              <MenuItem disabled value={'first'}>
                Selecione um método
              </MenuItem>
              <MenuItem value={PaymentMethodEnum.PICPAY}>PicPay</MenuItem>
              <MenuItem value={PaymentMethodEnum.BANK_TRANSFER}>
                Transferência bancária
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </>
      <Grid>
        {method === PaymentMethodEnum.PICPAY ? (
          <PicpayInputsComponent
            disabled={disabled}
            onConfirmSubmit={onConfirmSubmit}
            onAbort={onAbort}
            data={formData}
          />
        ) : null}
        {method === PaymentMethodEnum.BANK_TRANSFER ? (
          <BankTransferInputsComponent
            disabled={disabled}
            onConfirmSubmit={onConfirmSubmit}
            onAbort={onAbort}
            data={formData}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ReceivingMethodFormComponent;
