import { makeStyles, Typography, Grid } from '@material-ui/core';
import React from 'react';
import { useMenuContext } from '../context/menu.context';

interface BalanceDisplayProps {
  balance: number;
}

const useStyles = makeStyles({
  balanceBackground: {
    color: 'white',
    padding: 18,
    marginBottom: 20,
    backgroundColor: '#5abefc',
    WebkitBoxShadow: '2px 2px 10px rgba(0,0,0,0.4)',
    MozBoxShadow: '2px 2px 10px rgba(0,0,0,0.4)',
  },
  title: {
    fontSize: 27,
  },
});

const BalanceDisplayComponent: React.FC<BalanceDisplayProps> = ({
  balance,
}: BalanceDisplayProps) => {
  const classes = useStyles(),
    { isHeaderLoading } = useMenuContext();

  return (
    <Grid
      container
      justify="center"
      direction="column"
      className={classes.balanceBackground}
    >
      <Typography className={classes.title} align="center">
        Saldo
      </Typography>
      <Typography
        style={isHeaderLoading ? { filter: 'blur(5px)' } : null}
        className={classes.title}
        align="center"
      >
        {Number(balance)?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}
      </Typography>
    </Grid>
  );
};

export default BalanceDisplayComponent;
