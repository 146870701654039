import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { ContainerComponent } from '../component/container.component';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { useMenuContext } from '../context/menu.context';
import { useSnackbarContext } from '../context/snackbar.context';
import { useAuthContext } from '../context/auth.context';
import { changeMyPassword } from '../service/auth.service';
import { VagasIcon } from '../icons';

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      marginTop: '1em',
    },
    logo: {
      display: 'block',
      marginTop: '35px',
      marginBottom: '30px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    saveButton: {
      marginTop: '2em',
      paddingLeft: 1,
    },
  }),
);

const schema = yup.object().shape({
  currentPassword: yup
    .string()
    .min(6, 'A senha possui no mínimo 6 dígitos')
    .required('A senha atual é obrigatória.'),
  newPassword: yup
    .string()
    .min(6, 'A senha deve possuir no mínimo 6 dígitos')
    .required('A senha atual é obrigatória.'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'As senhas são diferentes')
    .required('A confirmação da nova senha é obrigatória.'),
});

interface InputDataInterface {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export function ChangePasswordPage(): JSX.Element {
  const classes = useStyles(),
    { setMenuPageById } = useMenuContext(),
    { token, setAuthenticatedUser } = useAuthContext(),
    { displaySnack } = useSnackbarContext(),
    { register, handleSubmit, errors, setValue } = useForm<InputDataInterface>({
      resolver: yupResolver(schema),
      mode: 'all',
      shouldFocusError: true,
    });

  useEffect(() => { setMenuPageById(MenuPageIdEnum.CHANGE_PASSWORD); },[]);

  const onSubmit = async ({
    currentPassword,
    newPassword,
  }: InputDataInterface): Promise<void> => {
    try {
      const response = await changeMyPassword(
        token,
        currentPassword,
        newPassword,
      );
      if (response.id && response.name) {
        setAuthenticatedUser(response);
        setValue('currentPassword', '');
        setValue('newPassword', '');
        setValue('confirmNewPassword', '');
        displaySnack('Senha atualizada!', 'success', true);
      }
    } catch (message) {
      displaySnack(`${message}`, 'error', true);
    }
  };

  return (
    <ContainerComponent>
      <div>
        <VagasIcon className={classes.logo} fill="lightgray" fontSize={125} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            className={classes.textField}
            label="Senha atual"
            name="currentPassword"
            fullWidth
            type="password"
            autoComplete="current-password"
            error={!!errors.currentPassword}
            helperText={errors?.currentPassword?.message}
            inputRef={register}
          />
          <TextField
            className={classes.textField}
            label="Nova senha"
            name="newPassword"
            fullWidth
            type="password"
            autoComplete="current-password"
            error={!!errors.newPassword}
            helperText={errors?.newPassword?.message}
            inputRef={register}
          />
          <TextField
            className={classes.textField}
            label="Confirmar nova senha"
            name="confirmNewPassword"
            fullWidth
            type="password"
            autoComplete="confirm-new-password"
            error={!!errors.confirmNewPassword}
            helperText={errors?.confirmNewPassword?.message}
            inputRef={register}
          />
          <Button className={classes.saveButton} color="primary" type="submit">
            ALTERAR SENHA
          </Button>
        </form>
      </div>
    </ContainerComponent>
  );
}
