import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { ContainerComponent } from '../component/container.component';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import TimesAvailableComponent from '../component/times-available-card.component';
import { SpeedButtonComponent } from '../component/speed-button.component';
import useHomeStyles from '../style/home-page.style';
import {
  SpeedButtonActionInterface,
  SpeedButtonPropsInterface,
} from '../interface/speed-button.interface';
import { NotificationComponent } from '../component/notification.component';
import moment from 'moment';
import MySpotsCardComponent from '../component/my-spots-card.component';
import { ParkingSpotInterface } from '../interface/parking-spot.interface';

export function HomePage(): JSX.Element {
  const classes = useHomeStyles(),
    { authenticatedUser } = useAuthContext(),
    { setMenuPageById, condominium } = useMenuContext(),
    [parkingSpots, setParkingSpots] = useState<ParkingSpotInterface[]>(),
    floatingButtonActions: SpeedButtonActionInterface[] = [
      {
        name: `Disponibilizar agora`,
        icon: <AddCircleIcon />,
        menuPageId:
          parkingSpots?.length === 1
            ? MenuPageIdEnum.MANAGE_AVAILABILITY
            : MenuPageIdEnum.MY_SPOTS,
        param: parkingSpots?.length === 1 ? parkingSpots[0].id : null,
        queries:
          parkingSpots?.length === 1
            ? [{ makeAvailabilityNow: 'true' }]
            : [{ makeAvailabilityNow: 'false' }],
      },
      {
        name: `Reservar agora`,
        icon: <DirectionsCar />,
        menuPageId: MenuPageIdEnum.NEW_RESERVATION_PAGE,
        queries: [
          { date: moment().format('yyyy-MM-DD') },
          { time: moment().format('HH:mm') },
        ],
      },
    ],
    floatingButtonProps: SpeedButtonPropsInterface = {
      icon: <SpeedDialIcon />,
      actions: floatingButtonActions,
    };
  useEffect((): void => {
    if (condominium) {
      setParkingSpots([]);
      const condominiumId = condominium
        ? condominium.id
        : authenticatedUser.condominiums[0].id;
      const condominiumParkingSpot = authenticatedUser.parkingSpots.filter(
        (parkingspot) => parkingspot.condominiumId === condominiumId,
      );
      setParkingSpots(condominiumParkingSpot);
    }
  }, [condominium]);

  useEffect(() => { setMenuPageById(MenuPageIdEnum.HOME); },[]);

  return (
    <ContainerComponent>
      <>
        <Typography className={classes.title}>Avisos</Typography>
        <NotificationComponent itsAtHomePage={true} />
        {authenticatedUser.condominiums.length ? (
          <TimesAvailableComponent />
        ) : null}

        {condominium ? (
          <div className={classes.spotCardComponent}>
            <MySpotsCardComponent
              condominiumId={
                condominium
                  ? condominium.id
                  : authenticatedUser.condominiums[0].id
              }
            />
          </div>
        ) : null}

        <SpeedButtonComponent {...floatingButtonProps} />
      </>
    </ContainerComponent>
  );
}
