import React from 'react';
import { Typography, Button, Grid, makeStyles } from '@material-ui/core';

interface PaginationProps {
  previousPageFuntion: () => void;
  nextPageFuntion: () => void;
  currentPage: number;
  totalPages: number;
}

const useStyles = makeStyles({
  grid: {
    marginTop: '2%',
    marginBottom: '2%',
  },
});

const PaginationComponent: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  nextPageFuntion,
  previousPageFuntion,
}: PaginationProps) => {
  const classes = useStyles();
  return (
    <>
      {' '}
      <Grid className={classes.grid} container justify="space-between">
        <Grid item xs={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={previousPageFuntion}
          >
            {'<'}
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Typography align="center" variant="h6" color="primary">
            {currentPage} - {totalPages}
          </Typography>
        </Grid>
        <Grid container item xs={3} justify="flex-end">
          <Button variant="outlined" color="primary" onClick={nextPageFuntion}>
            {'>'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PaginationComponent;
