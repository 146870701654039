import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';
import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { useSnackbarContext } from '../context/snackbar.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { getParkingSpotWithCurrentReservation } from '../service/parking-spot.service';
import { ParkingSpotStatusEnum } from '../enum/parking-spot-status.enum';
import { ParkingSpotCurrentReservationInterface } from '../interface/parking-spot-current-reservation.interface';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  cardHeader: {
    background: 'linear-gradient(90deg, #1B46FF 40%, #6abeff 100%);',
    color: '#FFF',
  },
  cardHeaderIcon: {
    color: '#FFF',
    display: 'flex',
    fontSize: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  line: {
    borderLeft: `0.5px solid #e6e6e6`,
    paddingLeft: 5,
    paddingRight: 5,
  },
  spotName: {
    color: 'grey',
    fontSize: 15,
    fontWeight: 'bold',
  },
  spotInformation: {
    fontSize: 14,
    color: '#3E3D3D',
    marginLeft: '10px',
    marginTop: '10px',
  },
  spotInformationAvailable: {
    fontSize: 14,
    color: '#6abeff',
    fontWeight: 'bold',
    marginLeft: '10px',
    marginBottom: '10px',
  },
  hourOClock: {
    borderRadius: '100%',
    width: '12px',
    height: '12px',
    marginRight: '3px',
    marginTop: 3,
    border: `2.2px solid #8A8A8A`,
  },
  hourOClockAvailable: {
    borderRadius: '100%',
    width: '12px',
    height: '12px',
    marginRight: '3px',
    marginTop: 3,
    border: `2.2px solid #6abeff`,
  },
  hourOClockUnavailable: {
    borderRadius: '100%',
    width: '12px',
    height: '12px',
    marginRight: '3px',
    marginTop: 3,
    border: `2.2px solid #1B46FF`,
  },
  spotInformationStatus: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    color: '#8A8A8A',
    marginLeft: '10px',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  spotInformationStatusAvailable: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    color: '#6abeff',
    fontWeight: 'bold',
    marginLeft: '10px',
    marginBottom: '10px',
  },
  spotInformationUnavailable: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    color: '#1B46FF',
    fontWeight: 'bold',
    marginLeft: '10px',
    marginBottom: '10px',
  },
  timeUnavailable: {
    fontSize: 14,
    color: '#8A8A8A',
    marginLeft: '4px',
  },
}));

interface ParkingSpotProps {
  id: number;
  code: string;
  gate: string;
  pavement: string;
  description: string;
  block: string;
  lastReservation: Date | string;
  length: number;
  width: number;
  height: number;
  isCovered: boolean;
  propertyRegistry: string;
  ownerUserId: number;
  condominiumId: number;
}

const SpotsInformation: React.FC<ParkingSpotProps> = ({
  id,
}: ParkingSpotProps) => {
  const classes = useStyles();
  const [userParkingSpots, setUserParkingSpots] = useState<
    ParkingSpotCurrentReservationInterface
  >();
  const { token } = useAuthContext();
  const { displaySnack } = useSnackbarContext();

  const transformDateTime = (dateTime: string): string => {
    const currentDate = moment(new Date()).format('DD/MM/YYYY');
    const newDate = new Date(dateTime);
    const formatedDate = moment(newDate).format('DD/MM/YYYY');
    const formatedTime = moment(newDate).format('HH:mm');
    if (currentDate === formatedDate) return `${formatedTime} de Hoje`;
    else return `${formatedTime} do dia ${formatedDate}`;
  };

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const currentTime = moment(new Date()).format('HH:mm');
        const parkingSpots = await getParkingSpotWithCurrentReservation(
          token,
          currentTime,
          id,
        );
        setUserParkingSpots(parkingSpots);
      } catch (errorMessage) {
        displaySnack(errorMessage, 'error');
      }
    })();
  }, [displaySnack, token]);

  return (
    <>
      <Grid key={userParkingSpots?.id} item xs={3}>
        <Typography className={classes.spotName} align="center">
          {userParkingSpots?.code}
        </Typography>
      </Grid>
      <Grid item xs={9} className={classes.line}>
        <Typography className={classes.spotInformation}>
          {userParkingSpots?.gate}
        </Typography>
        {userParkingSpots?.status === ParkingSpotStatusEnum.AVAILABLE ? (
          <Typography className={classes.spotInformationStatusAvailable}>
            <div className={classes.hourOClockAvailable} /> Disponibilizada
          </Typography>
        ) : userParkingSpots?.status === ParkingSpotStatusEnum.UNAVAILABLE ? (
          <Typography className={classes.spotInformationStatus}>
            <div className={classes.hourOClock} />
            Vaga não disponibilizada
          </Typography>
        ) : (
          <Typography className={classes.spotInformationUnavailable}>
            <div className={classes.hourOClockUnavailable} />
            Ocupada{' '}
            {userParkingSpots?.currentReservation?.finalDatetime ? (
              <Typography className={classes.timeUnavailable}>
                até{' '}
                {transformDateTime(
                  userParkingSpots?.currentReservation?.finalDatetime,
                )}
              </Typography>
            ) : null}
          </Typography>
        )}
      </Grid>
    </>
  );
};

interface MySpotsCardComponentProps {
  condominiumId: number;
}

const MySpotsCardComponent: React.FC<MySpotsCardComponentProps> = ({
  condominiumId,
}: MySpotsCardComponentProps) => {
  const classes = useStyles();
  const { goToPageRouteById } = useMenuContext();
  const { authenticatedUser } = useAuthContext();

  return (
    <Card>
      <CardHeader
        title={'Minhas vagas'}
        className={classes.cardHeader}
        titleTypographyProps={{ variant: 'h6' }}
        action={
          <IconButton
            onClick={(): void => {
              const selectedCondominiumParkingSpots = authenticatedUser.parkingSpots.filter(
                (parkingSpot) => parkingSpot.condominiumId === condominiumId,
              );

              selectedCondominiumParkingSpots.length === 1
                ? goToPageRouteById(
                    MenuPageIdEnum.MANAGE_AVAILABILITY,
                    selectedCondominiumParkingSpots[0].id,
                  )
                : goToPageRouteById(MenuPageIdEnum.MY_SPOTS);
            }}
          >
            <ArrowIcon className={classes.cardHeaderIcon} />
          </IconButton>
        }
      />
      <Grid
        item
        container
        direction="row"
        justify="center"
        alignItems="center"
        xs={12}
      >
        {authenticatedUser.parkingSpots.map((parkingSpot, index) => {
          if (parkingSpot.condominiumId === condominiumId) {
            return <SpotsInformation key={index} {...parkingSpot} />;
          }
        })}
      </Grid>
    </Card>
  );
};

export default MySpotsCardComponent;
