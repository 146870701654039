/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { useSnackbarContext } from '../context/snackbar.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { BlockInterface } from '../interface/block.interface';
import { CondominiumInterface } from '../interface/condominium.interface';
import { ApartmentInterface } from '../interface/apartment.interface';
import { UserRequestFileInterface } from '../interface/user-request-file.interface';
import { cpfRegExp } from '../common/regex.common';
import { cpfMock } from '../mock/auth.mock';
import { RequestTypeEnum } from '../enum/request-type.enum';
import { ContainerComponent } from '../component/container.component';
import { DynamicSelectComponent } from '../component/dynamic-select.component';
import { FormOwnerUserDataComponent } from '../component/form-owner-user-data.component';
import UploadFileComponent from '../component/upload-file.component';
import { ConfirmDialogComponent } from '../component/confirm-dialog.component';
import { Button, Box } from '@material-ui/core';
import {
  getApartments,
  getApartmentBlocks,
  getCondominiums,
} from '../service/condominium.service';
import {
  createUserRequestApartment,
  uploadUserRequestFile,
  confirmUserRequestApartment,
  deleteUserRequestFile,
} from '../service/user-request.service';
import style from '../style/user-request.style';
import { AvailableFor } from '../enum/available-for.enum';
import { sortByBlock, sortByApartment, sortByName, validateCPF } from '../common/util.common';
import { fi } from 'date-fns/esm/locale';

const schema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  cpf: yup
    .string()
    .required('Campo obrigatório')
    .matches(cpfRegExp, {
      message: 'O CPF deve estar no formato 000.000.000-00',
    })
    .min(14, 'Mínimo de 11 caracteres')
    .max(14)
    .test('test-cpf', 'CPF inválido', (cpfValue) => {
      return validateCPF(cpfValue);
    }),
  condominiumId: yup.string().required('O campo é obrigatório'),
  blockIndex: yup.string().required('O campo é obrigatório'),
  apartmentId: yup.string().required('O campo é obrigatório'),
});

interface UserDataInterface {
  name: string;
  cpf: string;
  isTheLegalGuardian: boolean;
  condominiumId: string;
  blockIndex: string;
  apartmentId: string;
}

export function UserRequestApartmentPage(): JSX.Element {
  const classes = style(),
    { authenticatedUser, token } = useAuthContext(),
    { displaySnack } = useSnackbarContext(),
    {
      setMenuPageById,
      goToPageRouteById,
      setHeaderLoading,
      isHeaderLoading,
    } = useMenuContext(),
    [isSending, setIsSending] = useState<boolean>(false),
    [condominium, setCondominium] = useState<CondominiumInterface>(),
    [block, setBlock] = useState<BlockInterface>(),
    [apartment, setApartment] = useState<ApartmentInterface>(),
    [userRequestId, setUserRequestId] = useState<number>(),
    [userData, setUserData] = useState<UserDataInterface>(),
    [
      openRequestConfirmationDialog,
      setOpenRequestConfirmationDialog,
    ] = React.useState(false),
    [
      userRequestCreationIsInProcess,
      setUserRequestCreationIsInProcess,
    ] = useState<boolean>(false),
    [uploadedFiles, setUploadedFiles] = useState<UserRequestFileInterface[]>(
      [],
    ),
    useFormMethods = useForm<UserDataInterface>({
      resolver: yupResolver(schema),
      mode: 'all',
      shouldFocusError: true,
    }),
    { watch, handleSubmit, setValue, clearErrors } = useFormMethods,
    isTheLegalGuardian = watch('isTheLegalGuardian');

  useEffect(() => { setMenuPageById(MenuPageIdEnum.USER_REQUEST_APARTMENT); },[]);

  const clearApartment = useCallback((): void => {
    setApartment(null);
    setValue('apartmentId', '');
    clearErrors('apartmentId');
  }, [clearErrors, setValue]);

  const clearBlock = useCallback((): void => {
    setBlock(null);
    setValue('blockIndex', '');
    clearErrors('blockIndex');
    clearApartment();
  }, [clearApartment, clearErrors, setValue]);

  const onLoadingSelectError = useCallback(
    (errorMessage: string): void => {
      setHeaderLoading(false);
      // goToPageRouteById(MenuPageIdEnum.CONDOMINIUMS);
      displaySnack(errorMessage, 'error');
    },
    [displaySnack],
  );

  const getCondominiumsForSelect = useCallback(async (): Promise<
    CondominiumInterface[]
  > => {
    setHeaderLoading(true);
    const conds = await getCondominiums(token, true, AvailableFor.APARTMENT);
    return conds.sort(sortByName);
  }, [token]);

  const onChangingCondominium = useCallback(
    (condominium: CondominiumInterface): void => {
      if (!condominium) setHeaderLoading(false);
      setCondominium(condominium);
      clearBlock();
    },
    [clearBlock],
  );

  const getBlocksForSelect = useCallback(async (): Promise<
    BlockInterface[]
  > => {
    setHeaderLoading(true);
    const blocks = await getApartmentBlocks(condominium.id, token);
    if (!blocks || !blocks.length)
      throw Error(
        'Não existem apartamentos disponíveis para vínculo nesse condomínio',
      );
    blocks.forEach((block, index) => (block.index = index + 1));
    return await blocks.sort(sortByBlock);
  }, [condominium, token]);

  const onChangingBlock = useCallback((block: BlockInterface): void => {
    if (!block) setHeaderLoading(false);
    setBlock(block);
    setApartment(null);
  }, []);

  const getApartmentsForSelect = useCallback(async (): Promise<
    ApartmentInterface[]
  > => {
    setHeaderLoading(true);
    const apartments = await getApartments(condominium.id, block.block, token);
    if (!apartments || !apartments.length)
      throw Error(
        'Não existem apartamentos disponíveis para vínculo nesse bloco',
      );
    return apartments.sort(sortByApartment)
  }, [block, condominium, token]);

  const onChangingApartment = useCallback(
    (apartment: ApartmentInterface): void => {
      if (!apartment) setHeaderLoading(false);
      setApartment(apartment);
      setHeaderLoading(false);
    },
    [],
  );

  const uploadFile = useCallback(
    async (file: File): Promise<UserRequestFileInterface> => {
      try {
        setHeaderLoading(true);
        const data = new FormData();
        data.append('file', file);
        data.append('userRequestId', String(userRequestId));
        return await uploadUserRequestFile(data, token);
      } catch (errorMessage) {
        throw errorMessage;
      }
    },
    [displaySnack, token, userRequestId],
  );

  const uploadFiles = useCallback(
    async (files: File[]): Promise<void> => {
      try {
        const newFilesUploaded: UserRequestFileInterface[] = [];
        for (const file of files) {
          const uploadResponseFile = await uploadFile(file);
          newFilesUploaded.push(uploadResponseFile);
        }
        setUploadedFiles((oldFiles) => [...oldFiles, ...newFilesUploaded]);
        displaySnack('Arquivos adicionados com sucesso', 'success');
      } catch (errorMessage) {
        displaySnack(errorMessage, 'error');
      } finally {
        setHeaderLoading(false);
      }
    },
    [uploadFile, uploadedFiles],
  );

  async function onFileDelete(data: File): Promise<void> {
    if (!uploadedFiles.length || isHeaderLoading) return;

    const { id }: UserRequestFileInterface = uploadedFiles.find(
      (file: UserRequestFileInterface) =>
        file?.name === data?.name && file?.type === data?.type,
    );

    try {
      setHeaderLoading(true);
      await deleteUserRequestFile(id, token);
      setUploadedFiles((oldFiles) =>
        oldFiles.filter((file: UserRequestFileInterface) => file.id !== id),
      );
      displaySnack('Arquivo removido', 'success', false);
    } catch (error) {
      displaySnack(error, 'error');
    } finally {
      setHeaderLoading(false);
    }
  }

  useEffect(() => {
    if (!apartment || userRequestCreationIsInProcess) return;
    setUserRequestCreationIsInProcess(true);
    (async (): Promise<void> => {
      try {
        setHeaderLoading(true);
        const { id } = await createUserRequestApartment(
          RequestTypeEnum.APARTMENT,
          new Date(),
          authenticatedUser.id,
          condominium.id,
          apartment.id,
          false,
          authenticatedUser.cpf || cpfMock,
          authenticatedUser.name,
          0,
          token,
        );
        setUserRequestId(id);
      } catch (error) {
        displaySnack(error, 'error');
      } finally {
        setHeaderLoading(false);
      }
    })();
  }, [
    apartment,
    authenticatedUser,
    condominium,
    token,
    userRequestCreationIsInProcess,
  ]);

  const confirmCondominiumRegistration = async (): Promise<void> => {

    setIsSending(true);
    const sendFilesToEmail = uploadedFiles.map(
      (file: UserRequestFileInterface) => ({
        name: file?.name,
        path: file?.path,
      }),
    );

    try {
      await confirmUserRequestApartment(
        userRequestId,
        authenticatedUser.id,
        condominium.id,
        apartment.id,
        isTheLegalGuardian,
        userData.cpf,
        userData.name,
        uploadedFiles.length,
        authenticatedUser.email,
        authenticatedUser.name,
        sendFilesToEmail,
        RequestTypeEnum.APARTMENT,
        apartment.apartment,
        condominium.name,
        token,
      );
      setIsSending(false);
      goToPageRouteById(MenuPageIdEnum.USER_REQUEST_PARKING_SPOT);
    } catch (errorMessage) {
      setIsSending(false);
      setOpenRequestConfirmationDialog(false);
      displaySnack(errorMessage, 'error');
    }
  };

  function submit(userData: UserDataInterface): Promise<void> {
    setUserData(userData);

    if(uploadedFiles.length <= 0){
      displaySnack('Pelo menos um arquivo deve ser anexado!', 'error');
      return;
    }

    if(!isTheLegalGuardian){
      displaySnack('A opção "Sou o responsável por este apartamento" deve ser marcada!', 'error');
      return;
    }

    // if (!apartment || uploadedFiles.length === 0) {
    //   displaySnack('Todos os dados são necessários!', 'error');
    //   return;
    // }
    
    if (isHeaderLoading) return;
    setOpenRequestConfirmationDialog(true);
  }

  return (
    <>
      <ContainerComponent>
        <form className={classes.form} onSubmit={handleSubmit(submit)}>
          {useMemo(
            () => (
              <DynamicSelectComponent
                inputName="condominiumId"
                title="Selecione o condomínio"
                attributeValue="id"
                attributeToDisplay="name"
                useFormMethods={useFormMethods}
                shouldLoadData={true}
                fullWidth={true}
                disabled={false}
                onLoadingError={onLoadingSelectError}
                onChange={onChangingCondominium}
                getEntityData={getCondominiumsForSelect}
              ></DynamicSelectComponent>
            ),
            [
              useFormMethods,
              onLoadingSelectError,
              onChangingCondominium,
              getCondominiumsForSelect,
            ],
          )}

          {useMemo(
            () => (
              <DynamicSelectComponent
                inputName="blockIndex"
                title="Selecione o bloco"
                attributeValue="index"
                attributeToDisplay="block"
                useFormMethods={useFormMethods}
                shouldLoadData={!!condominium}
                fullWidth={true}
                disabled={!condominium}
                onLoadingError={onLoadingSelectError}
                onChange={onChangingBlock}
                getEntityData={getBlocksForSelect}
              ></DynamicSelectComponent>
            ),
            [
              useFormMethods,
              condominium,
              onLoadingSelectError,
              onChangingBlock,
              getBlocksForSelect,
            ],
          )}

          {useMemo(
            () => (
              <DynamicSelectComponent
                inputName="apartmentId"
                title="Selecione o apartamento"
                attributeValue="id"
                attributeToDisplay="apartment"
                useFormMethods={useFormMethods}
                shouldLoadData={!!block}
                fullWidth={true}
                disabled={!block}
                onLoadingError={onLoadingSelectError}
                onChange={onChangingApartment}
                getEntityData={getApartmentsForSelect}
              ></DynamicSelectComponent>
            ),
            [
              useFormMethods,
              block,
              onLoadingSelectError,
              onChangingApartment,
              getApartmentsForSelect,
            ],
          )}
          <FormOwnerUserDataComponent
            useFormMethods={useFormMethods}
            title="Informe os dados do responsável"
            checkBoxText="Sou o responsável por este apartamento"
          />
          <Box className={classes.uploadFilesBox}>
            <UploadFileComponent
              onUpload={uploadFiles}
              onDelete={onFileDelete}
              disabled={!userRequestId || isHeaderLoading}
              text={
                !!userRequestId
                  ? 'Toque para adicionar até 3 arquivos que comprovem o vínculo com o apartamento indicado. Sugerimos o DOC do condomínio ou outro documento que demonstre sua relação com o imóvel'
                  : 'Preencha todos os dados acima'
              }
            />
          </Box>
          <div className={classes.buttonDiv}>
            <Button
              type="submit"
              disabled={isHeaderLoading}
              className={classes.buttonSubmit}
            >
              Enviar Dados
            </Button>
          </div>
          <ConfirmDialogComponent
            acceptButtonText={'Aceitar'}
            declineButtonText={'Cancelar'}
            open={openRequestConfirmationDialog}
            onAccept={confirmCondominiumRegistration}
            onClose={(): void => setOpenRequestConfirmationDialog(false)}
            viewProgressBar={isSending}
            text={
              isSending
                ? 'Sua solicitação está sendo enviada...'
                : 'Sua solicitação de vínculo será enviada e você receberá uma resposta em breve. Outros apartamentos poderão ser vinculados posteriormente, acessando o menu da aplicação.'
            }
            title={'Confirmar solicitação de vínculo'}
          />
        </form>
      </ContainerComponent>
    </>
  );
}
