import api from './api';
import { ReservationStatusEnum } from '../enum/reservation-status.enum';
import { ReservationWithParkingSpotsInterface } from '../interface/reservation-with-parking-spots.interface.dto';
import { PreReservationInterface } from '../interface/pre-reservation.interface';
// import { preReservationResponseDtoMock } from '../mock/reservation.mock';
// import reservationsMock from '../mock/reservations.mock.json';

export const getReservations = async (
  token: string,
  condominiumId?: number,
): Promise<ReservationWithParkingSpotsInterface[]> => {
  try {
    let queryString = '';
    if (!!condominiumId) queryString += `?condominiumId=${condominiumId}`;

    const { data } = await api.get<ReservationWithParkingSpotsInterface[]>(
      `/reservations${queryString}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    // if (!!condominiumId)
    //   return reservationsMock.filter(
    //     (mock) => mock.condominiumId === condominiumId,
    //   ) as ReservationWithParkingSpotsInterface[];
    // else return reservationsMock as ReservationWithParkingSpotsInterface[];

    return data;
  } catch ({ response }) {
    if (response?.data?.message) throw response?.data?.message;
    throw 'Não foi possível buscar suas reservas';
  }
};

export const registerSpotArrival = async (
  token: string,
  reservationParkingSpotId: number,
  status: ReservationStatusEnum.CHECK_IN,
): Promise<void> => {
  try {
    const { data } = await api.patch(
      `/reservation-parking-spots/${reservationParkingSpotId}`,
      { status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch ({ response }) {
    if (response?.data?.message) throw response?.data?.message;
    throw 'Não foi possível registrar sua chegada';
  }
};

export const cancelSpotArrival = async (
  token: string,
  reservationId: number,
): Promise<void> => {
  try {
    await api.delete(`/reservation/${reservationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return;
  } catch ({ response }) {
    if (response?.data?.message) throw response?.data?.message;
    throw 'Não foi possível cancelar';
  }
};

export const createPreReservation = async (
  condominiumId: number,
  gate: string,
  initialDate: string,
  initialTime: string,
  finalDate: string,
  finalTime: string,
  token: string,
  isReserveNow = false,
): Promise<PreReservationInterface> => {
  try {
    const { data } = await api.post<PreReservationInterface>(
      `pre-bookings?isReserveNow=${isReserveNow}`,
      {
        condominiumId,
        gate,
        startDate: initialDate,
        startTime: initialTime,
        endDate: finalDate,
        endTime: finalTime,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return data;
  } catch ({ response }) {
    if (response?.data?.message) throw response?.data?.message;
    throw 'Não foi possível buscar as vagas disponíveis para reserva';
  }
};

export const createReservation = async (
  availabilityParkingSpot: PreReservationInterface,
  token: string,
  isReserveNow = false,
): Promise<ReservationWithParkingSpotsInterface> => {
  try {
    let queryString = '';
    if (!!isReserveNow) queryString += `?isReserveNow=${isReserveNow}`;
    const { data } = await api.post<ReservationWithParkingSpotsInterface>(
      'reservations' + queryString,
      availabilityParkingSpot,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return data;
  } catch ({ response }) {
    if (response?.data?.message) throw response?.data?.message;
    throw 'Não foi possível buscar as vagas';
  }
};
