export enum MenuPageIdEnum {
  HOME = 'HOME',
  NOTIFICATIONS = 'NOTIFICATIONS',
  CONDOMINIUMS = 'CONDOMINIUMS',
  USER_REQUEST_APARTMENT = 'USER_REQUEST_APARTMENT',
  USER_REQUEST_PARKING_SPOT = 'USER_REQUEST_PARKING_SPOT',
  MY_RESERVATIONS = 'MY_RESERVATIONS',
  MY_SPOTS = 'MY_SPOTS',
  TRANSACTIONS_STATEMENT = 'TRANSACTIONS_STATEMENT',
  WITHDRAWAL_REQUEST = 'WITHDRAWAL_REQUEST',
  CONTACT = 'CONTACT',
  MY_DATA = 'MY_DATA',
  ADMIN = 'ADMIN',
  TERMS = 'TERMS',
  LOGOUT = 'LOGOUT',
  AUTH = 'AUTH',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  DEFAULT = 'DEFAULT',
  ACCOUNT_VERIFICATION = 'ACCOUNT_VERIFICATION',
  MANAGE_AVAILABILITY = 'MANAGE_AVAILABILITY',
  USERS = 'USERS',
  USER_DATA = 'USER_DATA',
  NEW_RESERVATION_PAGE = 'NEW_RESERVATION_PAGE',
  BUY_CREDITS = 'BUY_CREDITS',
}
