import React from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 250,
  },
  dropzone: {
    display: 'flex',
    fontSize: '16px',
    padding: 10,
    color: 'grey',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'grey',
  },
});

interface Props {
  onUpload: (files: File[]) => void;
  onDelete: (file: File) => void;
  disabled: boolean;
  text: string;
}

const UploadFileComponent: React.FC<Props> = ({
  onUpload,
  onDelete,
  disabled,
  text,
}: Props) => {
  const classes = useStyles();
  return (
    <DropzoneArea
      showAlerts={['error']}
      useChipsForPreview
      showPreviews={!disabled}
      showPreviewsInDropzone={false}
      previewText={'Pré-visualização'}
      onDrop={onUpload}
      acceptedFiles={['application/pdf, .doc, .docx, image/*']}
      maxFileSize={50000000}
      filesLimit={3}
      dropzoneClass={classes.dropzone}
      dropzoneText={text}
      onDelete={onDelete}
      getDropRejectMessage={(): string => 'Arquivo não suportado'}
      getFileLimitExceedMessage={(): string => 'Limite de 3 arquivos'}
      previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
      previewChipProps={{ classes: { root: classes.previewChip } }}
      dropzoneProps={{ disabled: disabled, multiple: false }}
      //Icon={<AttachFile />}
    />
  );
};

export default UploadFileComponent;
