import React, { createContext, useContext } from 'react';
import { VariantType, useSnackbar } from 'notistack';

import { throwContextError } from '../common/util.common';

const businessContext = 'Snackbar';

interface SnackContextInterface {
  displaySnack(
    message: string,
    variant: VariantType,
    preventDuplicate?: boolean,
  ): void;
}

const SnackContext = createContext<SnackContextInterface>(null);

export function SnackProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const displaySnack = (
    message: string,
    variant: VariantType = 'default',
    preventDuplicate = true,
  ): void => {
    enqueueSnackbar(message, {
      variant,
      preventDuplicate,
      autoHideDuration: 10000,
    });
  };

  return (
    <SnackContext.Provider
      value={{
        displaySnack,
      }}
    >
      {children}
    </SnackContext.Provider>
  );
}

export function useSnackbarContext(): SnackContextInterface {
  const context = useContext(SnackContext);
  if (!context) throwContextError(businessContext);
  return context;
}
