import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthContext } from '../context/auth.context';
import {
  makeStyles,
  Button,
  Dialog,
  Grid,
  DialogTitle,
  TextField,
  createStyles,
  Backdrop,
  Theme,
  CircularProgress,
} from '@material-ui/core';
import { registerBonuses } from '../service/wallet.service';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useSnackbarContext } from '../context/snackbar.context';

import { moneyRegExp } from '../common/regex.common';
interface AddBonusModalProps {
  walletId: number;
  userId: number;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
interface AddBonusModalInterface {
  value: number;
  reason: string;
}

const schema = yup.object().shape({
  value: yup.string().required('O valor é obrigatório').matches(moneyRegExp, {
    message: 'O valor informado deve estar no formato 00,00 ou 000.000,00',
  }),
  reason: yup.string().max(240, 'Máximo de 240 caracteres'),
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    textField: {
      marginBottom: '4%',
    },
    container: {
      paddingBottom: '3%',
      paddingLeft: '6%',
      paddingRight: '6%',
    },
    buttonConfirm: {
      color: '#ffff',
      backgroundColor: '#6abeff',
    },
    buttonCancel: {
      color: 'red',
    },
  }),
);

export const AddBonusModalComponent: React.FC<AddBonusModalProps> = ({
  walletId,
  userId,
  open,
  onClose,
  onConfirm,
}: AddBonusModalProps) => {
  const classes = useStyles();
  const { token } = useAuthContext();
  const { displaySnack } = useSnackbarContext();
  const [loading, setLoading] = useState<boolean>(false);
  const { register, handleSubmit, errors } = useForm<AddBonusModalInterface>({
    resolver: yupResolver(schema),
    mode: 'all',
    shouldFocusError: true,
  });

  const transformCommaValueToDotValue = (value: number): string => {
    const newValue = String(value).replace(',', '.');
    return newValue;
  };

  const onSubmit = async ({
    value,
    reason,
  }: AddBonusModalInterface): Promise<void> => {
    setLoading(true);
    if (reason === '') reason = 'Não informado';
    if (Number(value) <= 0) {
      displaySnack('Digite um valor válido!', 'error');
      setLoading(false);
    } else {
      const newValue = await transformCommaValueToDotValue(value);
      try {
        await registerBonuses(
          walletId,
          userId,
          Number(newValue),
          reason,
          token,
        );
        onConfirm();
        displaySnack('Bônus adicionado com sucesso', 'success');
      } catch (message) {
        displaySnack(`${message}`, 'error', true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <DialogTitle>{'Adição de Bônus'}</DialogTitle>
      <Grid container className={classes.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                className={classes.textField}
                label="Valor do Bônus"
                name="value"
                error={!!errors.value}
                helperText={errors?.value?.message}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                className={classes.textField}
                label="Motivo (opcional)"
                name="reason"
                error={!!errors.reason}
                helperText={errors?.reason?.message}
                inputRef={register}
              />
            </Grid>
          </Grid>
          <Grid item container justify="flex-end">
            <Button onClick={(): void => onClose()}>Cancelar</Button>
            <Button type="submit" className={classes.buttonConfirm}>
              Adicionar
            </Button>
          </Grid>
        </form>
      </Grid>
    </Dialog>
  );
};
