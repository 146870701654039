import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  makeStyles,
  ListItem,
  ListItemAvatar,
  Grid,
  Avatar,
  AccordionDetails,
  ListItemText,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useAuthContext } from '../context/auth.context';
import Accordion from '@material-ui/core/Accordion';
import { UserInterface } from '../interface/user.interface';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { useMenuContext } from '../context/menu.context';
import { useSnackbarContext } from '../context/snackbar.context';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TransactionStatementComponent from '../component/transaction-statement.component';
import { ContainerComponent } from '../component/container.component';
interface MatchParams {
  userId: string;
}

const useStyles = makeStyles({
  accordionDetailsExpanded: (isMobile: boolean) => ({
    marginLeft: isMobile ? 46 : 0,
    padding: 10,
  }),
  accordionSummary: {
    flexWrap: 'wrap',
    paddingLeft: 0,
  },
  accordionListSummary: {
    flexWrap: 'wrap',
  },
  userName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  userEmail: {
    fontSize: 16,
  },
  userDataCondominium: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  userDataTitle: {
    marginLeft: 10,
    fontSize: 16,
  },
  userDataContent: {
    fontSize: 16,
    color: 'grey',
  },
  userWithoutData: {
    fontSize: 16,
    color: 'grey',
    marginBottom: 10,
  },
});

export function UserDataPage(
  props: RouteComponentProps<MatchParams>,
): JSX.Element {
  const isMobile = useMediaQuery('(min-width: 700px)');
  const classes = useStyles(isMobile),
    { lastUsersSearched } = useAuthContext(),
    { setMenuPageById, setHeaderLoading, goToPageRouteById } = useMenuContext(),
    { displaySnack } = useSnackbarContext(),
    [user, setUser] = useState<UserInterface>();

  useEffect(() => { setMenuPageById(MenuPageIdEnum.USER_DATA); },[]);
  useEffect(() => {
    setHeaderLoading(true);
    const selectedUser = lastUsersSearched.find((lastUserSearched) => {
      if (lastUserSearched.id == Number(props.match.params.userId))
        return lastUserSearched;
    });

    if (selectedUser) {
      setUser(selectedUser);
      setHeaderLoading(false);
    } else {
      displaySnack('Não foi possível buscar este usuário', 'error', true);
      goToPageRouteById(MenuPageIdEnum.USERS);
    }
  }, []);

  return (
    <ContainerComponent>
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordionSummary}
            aria-controls="user-content"
            id="user-header"
          >
            <ListItem
              className={classes.accordionListSummary}
              alignItems="center"
            >
              <ListItemAvatar>
                <Avatar alt={user?.name} src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText>
                <Typography className={classes.userName} color="textPrimary">
                  {user?.name}
                </Typography>
                <Typography className={classes.userEmail} color="textPrimary">
                  {user?.email}
                </Typography>
              </ListItemText>
            </ListItem>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetailsExpanded}>
            <ListItem>
              {user?.condominiums[0] ? (
                user?.condominiums.map((condominium) => (
                  <Grid container key={condominium?.id}>
                    <Grid item xs={12}>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                        className={classes.userDataCondominium}
                      >
                        {`Condomínio ${condominium?.name}`}
                      </Typography>
                    </Grid>
                    {user?.apartments[0] ? (
                      <Grid item xs={12}>
                        <Typography
                          className={classes.userDataTitle}
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          - Apartamento(s):
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.userDataContent}
                        >
                          {user?.apartments
                            ?.filter(
                              (apartment) =>
                                apartment?.condominiumId === condominium?.id,
                            )
                            .map((apartment) => (
                              <Typography
                                key={apartment?.apartmentId}
                                component="span"
                                variant="body2"
                                className={classes.userDataContent}
                              >
                                {user?.apartments?.indexOf(apartment) !==
                                user?.apartments?.length - 1
                                  ? ' ' + apartment?.apartment + ','
                                  : ' ' + apartment?.apartment + '.'}
                              </Typography>
                            ))}
                        </Typography>
                      </Grid>
                    ) : null}

                    {user?.parkingSpots[0] ? (
                      <Grid item xs={12}>
                        <Typography
                          className={classes.userDataTitle}
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          - Vaga(s):
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.userDataContent}
                        >
                          {user?.parkingSpots
                            ?.filter(
                              (parkingSpot) =>
                                parkingSpot?.condominiumId === condominium?.id,
                            )
                            .map((parkingSpot) => (
                              <Typography
                                key={parkingSpot?.id}
                                component="span"
                                variant="body2"
                                className={classes.userDataContent}
                              >
                                {user?.parkingSpots?.indexOf(parkingSpot) !==
                                user?.parkingSpots?.length - 1
                                  ? ' ' + parkingSpot?.code + ','
                                  : ' ' + parkingSpot?.code + '.'}
                              </Typography>
                            ))}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                ))
              ) : (
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.userWithoutData}
                >
                  Não possui nenhum condomínio.
                </Typography>
              )}
            </ListItem>
          </AccordionDetails>
        </Accordion>
        <TransactionStatementComponent
          walletId={user?.wallet?.id}
          userId={user?.id}
        />
      </>
    </ContainerComponent>
  );
}
