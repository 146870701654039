import React, { useEffect, useState } from 'react';
import { useMenuContext } from '../context/menu.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { RouteComponentProps } from 'react-router-dom';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { AuthSubRouteEnum } from '../enum/auth-sub-route.enum';
import { AuthTabConfigInterface } from '../interface/auth-tab-config.interface';
import { ContainerComponent } from '../component/container.component';
import { LoginComponent } from '../component/login.component';
import { SignupComponent } from '../component/signup.component';

const authTabs: AuthTabConfigInterface[] = [];

authTabs.push({
  authSubroute: AuthSubRouteEnum.LOGIN,
  title: 'LOGIN',
  component: LoginComponent,
  tabIndex: authTabs.length,
});

authTabs.push({
  authSubroute: AuthSubRouteEnum.SIGNUP,
  title: 'CADASTRE-SE',
  component: SignupComponent,
  tabIndex: authTabs.length,
});

export function AuthPage(
  props: RouteComponentProps<{
    subroute: string;
  }>,
): JSX.Element {
  const { subroute } = props.match.params;
  const { setMenuPageById } = useMenuContext();

  const [authTab, setAuthTab] = useState<AuthTabConfigInterface>();

  const [tabIndex, setTabIndex] = useState<number>(0);

  useEffect(() => {
    setMenuPageById(MenuPageIdEnum.AUTH);
  },[]);

  useEffect(() => {
    const authTabFound =
      authTabs.find((authTabFound) => authTabFound.authSubroute === subroute) ||
      authTabs[0];
    setTabIndex(authTabFound.tabIndex);
  }, [subroute]);

  useEffect(() => {
    const authTabFound =
      authTabs.find((authTabFound) => authTabFound.tabIndex === tabIndex) ||
      authTabs[0];
    setAuthTab(authTabFound);
  }, [tabIndex]);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: unknown,
  ): void => {
    setTabIndex(newValue as number);
  };

  return (
    <div>
      <Paper>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {authTabs.map((authTab) => (
            <Tab key={authTab.tabIndex} label={authTab.title} />
          ))}
        </Tabs>
      </Paper>
      {authTab && (
        <ContainerComponent>
          <div>
            {authTab.component && React.createElement(authTab?.component)}
          </div>
        </ContainerComponent>
      )}
    </div>
  );
}
