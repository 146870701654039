import { UserRequestListInterface } from '../interface/user-request.interface';
import api from './api';
import { ApprovementBodyInterface } from '../interface/approvement-body.interface';
import { UserRequestApartmentInterface } from '../interface/user-request-apartment.interface';
import { UserRequestFileInterface } from '../interface/user-request-file.interface';
import { UserRequestInterface } from '../interface/user-request.interface';
import { UserRequestSendFileInterface } from '../interface/user-request-send-file.interface';
import { UserRequestParkingSpotInterface } from '../interface/user-request-parking-spot.interface';

const prefix = 'user-requests';

export const getUserRequests = async (
  page: number,
  token: string,
): Promise<UserRequestListInterface> => {
  try {
    const { data } = await api.get<UserRequestListInterface>(
      `/${prefix}?page=${page}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );

    return data;
  } catch (error) {
    if (error.response) throw error?.response?.data?.message;
    throw 'Verifique sua conexão com a internet!';
  }
};

export const approveOrRejectRequest = async (
  action: string,
  token: string,
  approvementBody: ApprovementBodyInterface,
): Promise<void> => {
  try {
    const { data } = await api.patch<number[]>(
      `/${prefix}?action=${action}`,
      approvementBody,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );

    if (data?.length) return;
  } catch (error) {
    if (error.response) throw error?.response?.data?.message;
    throw 'Verifique sua conexão com a internet!';
  }
};

export const createUserRequestApartment = async (
  requestType: string,
  requestDate: Date,
  userId: number,
  condominiumId: number,
  apartmentId: number,
  isTheLegalGuardian: boolean,
  ownerCpf: string,
  ownerName: string,
  filesNumber: number,
  token: string,
): Promise<UserRequestApartmentInterface> => {
  try {
    const { data } = await api.post<UserRequestApartmentInterface>(
      `user-requests`,
      {
        requestType,
        requestDate,
        userId,
        condominiumId,
        apartmentId,
        isTheLegalGuardian,
        ownerCpf,
        ownerName,
        filesNumber,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return data;
  } catch (error) {
    if (error.response) throw error?.response?.data?.message;
    throw 'Não foi possível buscar as vagas';
  }
};

export const createUserRequestParkingSpot = async (
  requestType: string,
  requestDate: Date,
  userId: number,
  condominiumId: number,
  parkingSpotId: number,
  isTheLegalGuardian: boolean,
  ownerCpf: string,
  ownerName: string,
  filesNumber: number,
  token: string,
): Promise<UserRequestParkingSpotInterface> => {
  try {
    const { data } = await api.post<UserRequestParkingSpotInterface>(
      `user-requests`,
      {
        requestType,
        requestDate,
        userId,
        condominiumId,
        parkingSpotId,
        isTheLegalGuardian,
        ownerCpf,
        ownerName,
        filesNumber,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return data;
  } catch (error) {
    if (error?.response) throw error?.response?.data?.message;
    throw 'Não foi possível enviar a solicitação';
  }
};

export const uploadUserRequestFile = async (
  file: FormData,
  token: string,
): Promise<UserRequestFileInterface> => {
  try {
    const { data } = await api.post<UserRequestFileInterface>(
      'user-request-file/upload',
      file,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${file}`,
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch (error) {
    if (error?.response) throw error?.response?.data?.message;
    throw 'Não foi possível enviar arquivos';
  }
};

export const deleteUserRequestFile = async (
  id: number,
  token: string,
): Promise<void> => {
  try {
    await api.delete<void>(`user-request-file/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    if (error?.response) throw error?.response?.data?.message;
    throw 'Não foi possível excluir';
  }
};

export const confirmUserRequestApartment = async (
  userRequestId: number,
  userId: number,
  condominiumId: number,
  apartmentId: number,
  isTheLegalGuardian: boolean,
  ownerCpf: string,
  ownerName: string,
  filesNumber: number,
  email: string,
  username: string,
  files: UserRequestSendFileInterface[],
  requestType: string,
  apartmentNumber: string,
  condominiumName: string,
  token: string,
): Promise<UserRequestInterface> => {
  try {
    const { data } = await api.put<UserRequestInterface>(
      'user-requests',
      {
        userRequestId,
        userId,
        condominiumId,
        apartmentId,
        isTheLegalGuardian,
        ownerCpf,
        ownerName,
        filesNumber,
        email,
        username,
        files,
        requestType,
        apartmentNumber,
        condominiumName,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return data;
  } catch (error) {
    if (error.response) throw error?.response?.data?.message;
    throw 'Não foi possível buscar as vagas';
  }
};

export const confirmUserRequestParkingSpot = async (
  userRequestId: number,
  userId: number,
  condominiumId: number,
  parkingSpotId: number,
  isTheLegalGuardian: boolean,
  ownerCpf: string,
  ownerName: string,
  filesNumber: number,
  email: string,
  username: string,
  files: UserRequestSendFileInterface[],
  requestType: string,
  parkingSpotNumber: string,
  gate: string,
  condominiumName: string,
  token: string,
): Promise<UserRequestInterface> => {
  try {
    const { data } = await api.put<UserRequestInterface>(
      'user-requests',
      {
        userRequestId,
        userId,
        condominiumId,
        parkingSpotId,
        isTheLegalGuardian,
        ownerCpf,
        ownerName,
        filesNumber,
        email,
        username,
        files,
        requestType,
        gate,
        parkingSpotNumber,
        condominiumName,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return data;
  } catch (error) {
    if (error.response) throw error?.response?.data?.message;
    throw 'Não foi possível buscar as vagas';
  }
};
