import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';

import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
  Backdrop,
  CircularProgress,
  createStyles,
} from '@material-ui/core';
import { createCheckoutCode } from '../service/wallet.service';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useSnackbarContext } from '../context/snackbar.context';

import { moneyRegExp } from '../common/regex.common';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';

interface AddCreditModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
interface AddCreditModalInterface {
  value: number;
}

const schema = yup.object().shape({
  value: yup.string().required('O valor é obrigatório').matches(moneyRegExp, {
    message: 'O valor informado deve estar no formato 00,00 ou 000.000,00',
  }),
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginBottom: '10px',
    },
    buttonConfirm: {
      color: '#ffff',
      backgroundColor: '#6abeff',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: '#5ABEFC',
        '@media (hover: none)': {
          backgroundColor: '#5ABEFC'
        },
      },
    },
    buttonCancel: {
      color: 'red',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

export const AddCreditModalComponent: React.FC<AddCreditModalProps> = ({
  open,
  onClose,
}: AddCreditModalProps) => {
  const classes = useStyles();
  const { token } = useAuthContext();
  const { displaySnack } = useSnackbarContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [transactionCodePag, setTransactionCodePag] = useState<string>('');
  const [transactionStatus, setTransactionStatus] = useState<boolean>(null);
  const { register, handleSubmit, errors } = useForm<AddCreditModalInterface>({
    resolver: yupResolver(schema),
    mode: 'all',
    shouldFocusError: true,
  });

  const transformCommaValueToDotValue = (value: number): string => {
    const newValue = String(value).replace(',', '.');
    return newValue;
  };

  const openLightbox = async (value: string): Promise<void> => {
    setLoading(true);
    const { code } = await createCheckoutCode(Number(value), token);

    const callback = {
      success: (transactionCode: string): void => {
        console.log("Compra feita com sucesso, código de transação: " + transactionCode);
        setTransactionCodePag(transactionCode);
        setTransactionStatus(true);
      },
      abort: (): void => {
        setTransactionCodePag('');
        setTransactionStatus(false);
        console.log('abort');
      }
    };

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      // eslint-disable-next-line no-restricted-globals
      window.location.href =
        process.env.REACT_APP_NODE_ENV !== 'production'
          ? process.env.REACT_APP_PAG_SEGURO_URL_DEV + code
          : process.env.REACT_APP_PAG_SEGURO_URL_PRD + code;
      setLoading(false);  
    } else{
      const isOpenLightbox = await window.PagSeguroLightbox(code, callback);
      setLoading(false);

      if(!isOpenLightbox){
        displaySnack('Ocorreu um erro tentando realizar o pagamento via PagSeguro', 'error');
      }
    }
  };

  const onSubmit = async ({
    value,
  }: AddCreditModalInterface): Promise<void> => {
    if (Number(value) <= 0) {
      displaySnack('Digite um valor válido!', 'error');
    } else {
      const newValue = transformCommaValueToDotValue(value);
      openLightbox(newValue);
    }
  };

  useEffect(() => {
    if(transactionStatus != null){
      if(transactionStatus){
        displaySnack('Processo de compra finalizado!', 'info');
      }else{
        displaySnack('Processo de compra cancelado!', 'error');
      }
    }
  }, [transactionStatus]);

  return (
    <>
      <Dialog disableEnforceFocus open={open} onClose={(): void => onClose()}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{'Compra de Crédito'}</DialogTitle>
          <DialogContent>
            <TextField
              className={classes.textField}
              label="Valor crédito"
              name="value"
              error={!!errors.value}
              helperText={errors?.value?.message}
              inputRef={register}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={(): void => onClose()}>Cancelar</Button>
            <Button type="submit" className={classes.buttonConfirm}>
              Adicionar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
