/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  createStyles,
  makeStyles,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { useAuthContext } from '../context/auth.context';
import { Controller, UseFormMethods } from 'react-hook-form';

type DynamicAttributeType = (entity: any, entities: any[]) => string;

interface DynamicSelectComponentProps {
  inputName: string;
  title?: string;
  getEntityData: () => Promise<any>;
  useFormMethods: UseFormMethods<any>;
  attributeToDisplay: string | DynamicAttributeType;
  attributeValue: string | DynamicAttributeType;
  disabled?: boolean;
  fullWidth?: boolean;
  shouldLoadData: boolean;
  onLoadingError: (errorMessage: string) => void;
  onChange?: (entity: any, entities: any[]) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120,
      marginTop: '10px',
    },
  }),
);

export const DynamicSelectComponent: React.FC<DynamicSelectComponentProps> = ({
  inputName,
  title,
  getEntityData,
  useFormMethods,
  disabled,
  fullWidth,
  shouldLoadData,
  onLoadingError,
  attributeValue,
  attributeToDisplay,
  onChange,
}: DynamicSelectComponentProps) => {
  const classes = useStyles();
  const { token } = useAuthContext();
  const {
    control,
    watch,
    errors,
    setError,
    setValue,
    clearErrors,
  } = useFormMethods;
  const [wasDataLoaded, setWasDataLoaded] = useState<boolean>(false);
  const [hasDataLoadFinished, setHasDataLoadFinished] = useState<boolean>(
    false,
  );
  const [entities, setEntities] = useState<any[]>([]);
  const entityId = watch(inputName, null);

  const clearInput = useCallback(
    (shouldClearError?: boolean): void => {
      setValue(inputName, '');
      setEntities([]);
      if (shouldClearError) clearErrors(inputName);
    },
    [clearErrors, inputName, setValue],
  );

  useEffect(() => {
    if (!onChange || !hasDataLoadFinished) return;
    const entity = entities.find(
      (entity) =>
        (typeof attributeValue === 'function'
          ? attributeValue(entity, entities)
          : String(entity[attributeValue])) === String(entityId),
    );
    onChange(entity, entities);
  }, [onChange, entityId, entities, hasDataLoadFinished, attributeValue]);

  useEffect(() => {
    setWasDataLoaded(false);
  }, [getEntityData]);

  useEffect(() => {
    if (!shouldLoadData || !getEntityData || wasDataLoaded) return;

    clearInput();
    setWasDataLoaded(true);

    (async (): Promise<void> => {
      try {
        const entities = await getEntityData();
        setEntities(entities);
        setHasDataLoadFinished(true);
      } catch (error) {
        clearInput();
        const errorMessage = typeof error === 'string' ? error : error.message;
        setError(inputName, { type: 'validate', message: errorMessage });
        onLoadingError(errorMessage);
      }
    })();
  }, [
    shouldLoadData,
    onLoadingError,
    token,
    wasDataLoaded,
    setWasDataLoaded,
    setError,
    inputName,
    getEntityData,
    setValue,
    clearErrors,
    clearInput,
  ]);

  return (
    <div>
      <FormControl
        className={classes.formControl}
        fullWidth={fullWidth}
        error={!!errors?.[inputName]}
      >
        <InputLabel htmlFor={entityId}>{title}</InputLabel>
        <Controller
          as={
            <Select fullWidth={fullWidth} disabled={disabled}>
              {entities.map((entity) => (
                <MenuItem
                  key={
                    typeof attributeValue === 'function'
                      ? attributeValue(entity, entities)
                      : entity[attributeValue]
                  }
                  value={
                    typeof attributeValue === 'function'
                      ? attributeValue(entity, entities)
                      : entity[attributeValue]
                  }
                >
                  {typeof attributeToDisplay === 'function'
                    ? attributeToDisplay(entity, entities)
                    : entity[attributeToDisplay]}
                </MenuItem>
              ))}
            </Select>
          }
          name={inputName}
          control={control}
          defaultValue={''}
        />
        <FormHelperText>{errors?.[inputName]?.message}</FormHelperText>
      </FormControl>
    </div>
  );
};
