import api from './api';
import { LoginInterface } from '../interface/login.interface';
import { SignupInterface } from '../interface/signup.interface';
import { UserInterface } from '../interface/user.interface';
import { TalkToUsInterface } from '../interface/talk-tu-us.interface';

const prefix = '/auth';
export async function login(
  email: string,
  password: string,
): Promise<LoginInterface> {
  try {
    const { data } = await api.post<LoginInterface>(`${prefix}/login`, {
      email,
      password,
    });
    return data;
  } catch ({ response }) {
    if (response?.data?.message) throw response.data.message;
    throw 'Não foi possível fazer login';
  }
}
export async function forgotMyPassword(email: string): Promise<void> {
  try {
    await api.post<string>(`${prefix}/password-recovery`, {
      email,
    });
  } catch ({ response }) {
    if (response?.data?.message) throw response.data.message;
    throw 'Não foi possível fazer entrar em contato';
  }
}

export async function signup(
  name: string,
  email: string,
  password: string,
  telephone: string,
): Promise<SignupInterface> {
  try {
    const { data } = await api.post<SignupInterface>(`${prefix}/signup`, {
      name,
      email,
      password,
      telephone,
    });
    return data;
  } catch ({ response }) {
    if (response?.data?.message) throw response.data.message;
    throw 'Não foi possível efetuar o cadastro';
  }
}

export async function emailConfirm(
  userId: number,
  userHash: string,
): Promise<number> {
  try {
    await api.patch<string>(`${prefix}/email-confirm`, {
      userId,
      userHash,
    });
    return 200;
  } catch ({ response }) {
    if (response?.status) throw response?.status;
    throw 'Não foi possível confirmar';
  }
}

export async function changeMyPassword(
  token: string,
  currentPassword: string,
  newPassword: string,
): Promise<UserInterface> {
  try {
    const { data } = await api.patch<UserInterface>(
      `${prefix}/change-my-password`,
      { currentPassword, newPassword },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch ({ response }) {
    if (response?.data?.message) throw response.data.message;
    throw 'Não foi possível trocar sua senha';
  }
}
export const sendMail = async (
  email: string,
  username: string,
  message: string,
  token: string,
): Promise<TalkToUsInterface> => {
  try {
    const { data } = await api.post<TalkToUsInterface>(
      `${prefix}/contact-us`,
      {
        email,
        username,
        message,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return data;
  } catch ({ response }) {
    if (response?.data?.message) throw response.data.message;
    throw 'Não foi possível enviar o email';
  }
};
