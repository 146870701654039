import * as React from 'react';

function CarIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 384 384" width="1em" height="1em" {...props}>
      <path d="M339.627 42.88c-4.373-12.587-16.32-21.547-30.293-21.547H74.667c-13.973 0-25.92 8.96-30.293 21.547L0 170.667v170.667c0 11.733 9.6 21.333 21.333 21.333h21.333c11.84 0 21.333-9.6 21.333-21.333V320h256v21.333c0 11.733 9.6 21.333 21.333 21.333h21.333c11.84 0 21.333-9.6 21.333-21.333V170.667L339.627 42.88zM74.667 256c-17.707 0-32-14.293-32-32s14.293-32 32-32 32 14.293 32 32-14.294 32-32 32zm234.666 0c-17.707 0-32-14.293-32-32s14.293-32 32-32 32 14.293 32 32-14.293 32-32 32zM42.667 149.333l32-96h234.667l32 96H42.667z" />
    </svg>
  );
}

export default CarIcon;
