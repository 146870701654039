import React from 'react';
import {
  makeStyles,
  List,
  ListItem,
  Divider,
  ListItemAvatar,
  Avatar,
  Typography,
  Grid,
  ButtonBase,
} from '@material-ui/core';
import { UserInterface } from '../interface/user.interface';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { useMenuContext } from '../context/menu.context';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
  },
  userData: {
    flexDirection: 'column',
  },
  userDataContent: {
    color: 'grey',
  },
  userName: {
    fontSize: 20,
  },
}));

interface UserPreviewComponentProps {
  user: UserInterface;
}
const UserPreviewComponent: React.FC<UserPreviewComponentProps> = ({
  user,
}: UserPreviewComponentProps) => {
  const { goToPageRouteById } = useMenuContext();

  const classes = useStyles();

  const handleClick = (): void => {
    goToPageRouteById(MenuPageIdEnum.USER_DATA, user.id);
  };
  return (
    <>
      <ButtonBase focusRipple onClick={handleClick}>
        <List className={classes.root}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={user?.name} src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.userName} color="textPrimary">
                  {user?.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {user?.condominiums[0] ? (
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    Condomínio(s):
                  </Typography>
                ) : (
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.userDataContent}
                  >
                    Não possui nenhum vínculo.
                  </Typography>
                )}
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.userDataContent}
                >
                  {user?.condominiums[0]
                    ? user?.condominiums.map((condominium) => {
                        if (
                          user?.condominiums?.indexOf(condominium) !==
                          user?.condominiums?.length - 1
                        )
                          return ' ' + condominium?.name + ',';
                        else return ' ' + condominium?.name + '.';
                      })
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {user?.apartments[0] ? (
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    Apartamento(s):
                  </Typography>
                ) : null}
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.userDataContent}
                >
                  {user?.apartments[0]
                    ? user?.apartments?.map((apartment) => {
                        if (
                          user?.apartments?.indexOf(apartment) !==
                          user?.apartments?.length - 1
                        )
                          return ' ' + apartment.apartment + ',';
                        else return ' ' + apartment.apartment + '.';
                      })
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {user?.parkingSpots[0] ? (
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    Vaga(s):
                  </Typography>
                ) : null}
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.userDataContent}
                >
                  {user?.parkingSpots[0]
                    ? user?.parkingSpots?.map((parkingSpot) => {
                        if (
                          user?.parkingSpots?.indexOf(parkingSpot) !==
                          user?.parkingSpots?.length - 1
                        )
                          return ' ' + parkingSpot.code + ',';
                        else return ' ' + parkingSpot.code + '.';
                      })
                    : null}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </ButtonBase>
      <Divider variant="fullWidth" />
    </>
  );
};

export default UserPreviewComponent;
