import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { useMenuContext } from '../context/menu.context';
import { useAuthContext } from '../context/auth.context';
import { MenuPageInterface } from '../interface/menu-page.interface';

interface StylesInterface {
  isSmall: boolean;
}

const useStyles = makeStyles({
  title: (props: StylesInterface) => ({
    fontSize: props.isSmall ? 13 : 17,
    '& span, & svg': {
      fontSize: props.isSmall ? 13 : 17,
    },
  }),
});

export function MenuListItemLinkComponent(props: {
  menuPage: MenuPageInterface;
}): JSX.Element {
  const {
    icon,
    path,
    menuRedirectTo,
    title,
    restrictedToUserTypes,
    showTopDivider,
    hideMenuListItem,
    isSmall,
  } = props.menuPage;
  const { goToPath } = useMenuContext();
  const { authenticatedUser } = useAuthContext();
  const classes = useStyles({ isSmall });

  const showComponent =
    !restrictedToUserTypes ||
    !restrictedToUserTypes.length ||
    (authenticatedUser &&
      restrictedToUserTypes.includes(authenticatedUser.type));

  return (
    <div>
      {!hideMenuListItem && showComponent && (
        <div>
          {showTopDivider && <Divider />}
          <ListItem
            button
            onClick={(): void => goToPath(menuRedirectTo || path)}
          >
            <ListItemIcon className={classes.title}>
              <i className="material-icons">{icon}</i>
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.title }}
              primary={title}
            />
          </ListItem>
        </div>
      )}
    </div>
  );
}
