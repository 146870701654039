import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {
  Container,
  Drawer,
  CardContent,
  List,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
} from '@material-ui/core';
import { useMenuContext } from '../context/menu.context';
import { useAuthContext } from '../context/auth.context';
import { MenuListItemLinkComponent } from './menu-list-item-link.component';
import { VagasIcon } from '../icons';
import { sortByName } from '../common/util.common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      flexGrow: 1,
    },
    toolbar: {
      backgroundColor: '#fff',
      top: 0,
    },
    appBarContainer: {
      padding: '0',
    },
    menuButton: {
      marginRight: 0,
    },
    condominiumSelect: {
      textDecoration: 'none',
      border: 'none',
      minWidth: '80px',
      fontSize: 20,
      fontWeight: 'bold',
    },
    formControl: {
      marginLeft: -12,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      flexGrow: 1,
    },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    userName: {
      marginBottom: '5%',
      fontSize: 14.5,
    },
    userEmail: {
      fontSize: 14.5,
    },
    vagaIconContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    vagaIcon: {
      marginTop: '7%',
    },
    drawerHeader: {
      backgroundColor: '#6abeff',
      justifyContent: 'flex-end',
      width: '100%',
      alignItems: 'center',
      color: '#fff',
    },
  }),
);

export default function MenuAppBarComponent(): JSX.Element {
  const {
    openMenu,
    toogleMenu,
    closeMenu,
    condominium,
    condominiums,
    setCondominium,
    menuPages,
    menuPage,
    isHeaderLoading,
  } = useMenuContext();
  const classes = useStyles();
  const { authenticatedUser } = useAuthContext();
  const [emailStrings, setEmailStrings] = useState<string[]>([
    authenticatedUser?.email,
  ]);
  const handleCondominiumChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    const condominiumId = event.target.value as number;
    const condominium = condominiums.find(
      (condominium) => condominium.id === condominiumId,
    );
    setCondominium(condominium);
  };

  useEffect(() => {
    if (authenticatedUser?.email?.length > 30) {
      const wrappedEmailTextStart = authenticatedUser?.email?.substring(0, 30);
      const wrappedEmailTextEnd = authenticatedUser?.email?.substring(30);
      setEmailStrings([wrappedEmailTextStart, wrappedEmailTextEnd]);
    }
  }, []);

  return (
    <div className={classes.root}>
      <AppBar
        position={menuPage?.fixHeader ? 'fixed' : 'relative'}
        color="default"
        className={classes.toolbar}
      >
        <Container maxWidth="md" className={classes.appBarContainer}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="default"
              aria-label="menu"
              onClick={toogleMenu}
            >
              <MenuIcon />
            </IconButton>
            {condominiums.length > 0 && menuPage?.showCondominumSelect ? (
              <FormControl className={classes.formControl}>
                <Select
                  className={classes.condominiumSelect}
                  labelId="condominium-select-label"
                  id="condominium-select"
                  displayEmpty
                  disableUnderline={true}
                  value={condominium?.id || ''}
                  onChange={handleCondominiumChange}
                >
                  {condominiums.sort(sortByName).map((condominium) => (
                    <MenuItem key={condominium.id} value={condominium.id}>
                      {condominium.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Typography variant="h6" className={classes.title}>
                {menuPage?.title}
              </Typography>
            )}
          </Toolbar>
        </Container>
        {isHeaderLoading ? <LinearProgress /> : null}
      </AppBar>
      <Drawer anchor="left" open={openMenu} onClose={closeMenu}>
        <div className={classes.drawerHeader}>
          <div className={classes.vagaIconContainer}>
            <VagasIcon
              className={classes.vagaIcon}
              fill="white"
              fontSize={75}
            />
          </div>
          <CardContent>
            <Typography align="center" className={classes.userName}>
              {authenticatedUser?.name}
            </Typography>

            {emailStrings?.map((emailText, index) => (
              <Typography
                key={index}
                align="center"
                className={classes.userEmail}
              >
                {emailText}
              </Typography>
            ))}
          </CardContent>
        </div>
        <List>
          {menuPages.map((menuPage) => (
            <MenuListItemLinkComponent
              key={menuPage.id}
              menuPage={menuPage}
            ></MenuListItemLinkComponent>
          ))}
        </List>
      </Drawer>
    </div>
  );
}
