import { makeStyles } from '@material-ui/core';
import React from 'react';

import { getStatusColorByAvailabilityAndReservation } from '../common/util.common';
import { IsAvailablePropInterface } from '../interface/is-available-props.interface';

const useStyles = makeStyles({
  brokenHour: (props: { color: string }) => ({
    fontSize: 20,
    margin: 1,
    color: props.color,
  }),
});

const BrokenHourDotComponent: React.FC<IsAvailablePropInterface> = ({
  isAvailable,
  isReserved,
  style,
}: IsAvailablePropInterface) => {
  const color = getStatusColorByAvailabilityAndReservation(
    isAvailable || false,
    isReserved || false,
  );
  const classes = useStyles({
    color,
  });
  return (
    <p className={classes.brokenHour} style={style}>
      &#8226;
    </p>
  );
};

export default BrokenHourDotComponent;
