import React, { useCallback, useEffect, useState } from 'react';
import { Typography, makeStyles, Grid } from '@material-ui/core';
import { getNotifications } from '../service/notification.service';
import { useMenuContext } from '../context/menu.context';
import { NotificationResponseInterface } from '../interface/notification-response.interface';
import { formatMonthByMonthId } from '../common/util.common';
import { useAuthContext } from '../context/auth.context';
import { useSnackbarContext } from '../context/snackbar.context';
import { VagasIcon } from '../icons';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import PaginationComponent from './pagination.component';

interface NotificationComponentProps {
  itsAtHomePage: boolean;
}

const useStyles = makeStyles({
  notificationsContainer: (itsAtHomePage: boolean) => ({
    marginTop: itsAtHomePage ? 0 : '3%',
    marginBottom: itsAtHomePage ? 0 : '4%',
  }),
  title: {
    paddingTop: 15,
    paddingBottom: 5,
    fontSize: 20,
  },
  dateDay: {
    color: '#6abeff',
    textAlign: 'center',
    marginBottom: -9,
  },
  dateMouth: {
    color: '#6abeff',
    textAlign: 'center',
    fontSize: 15,
  },
  notificationMessage: {
    flexWrap: 'wrap',
    color: 'gray',
    textAlign: 'left',
    fontSize: 17,
  },
  notificationInformation: {
    color: 'gray',
    textAlign: 'left',
    fontSize: 14,
  },
  notificationViewMore: {
    color: 'gray',
    textAlign: 'left',
    marginTop: 10,
    marginBottom: 15,
    fontSize: 15,
    cursor: 'pointer',
    width: '70px',
  },
  thereNotNotifications: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 25,
  },
  thereNotNotificationsImage: {
    margin: 25,
  },
  thereNotNotificationsMessage: {
    flexWrap: 'wrap',
    color: 'gray',
    textAlign: 'center',
    fontSize: 18,
  },
});

export const NotificationComponent: React.FC<NotificationComponentProps> = ({
  itsAtHomePage,
}: NotificationComponentProps) => {
  const [notifications, setNotifications] = useState<
      NotificationResponseInterface[]
    >(),
    [currentPage, setCurrentPage] = useState<number>(1),
    [totalPages, setTotalPages] = useState<number>(1),
    { token } = useAuthContext(),
    { displaySnack } = useSnackbarContext(),
    { goToPageRouteById, setHeaderLoading } = useMenuContext(),
    classes = useStyles(itsAtHomePage);

  const getData = useCallback((page: number) => {
    const getNotificationsData = async (): Promise<void> => {
      try {
        const { count, rows, limit } = itsAtHomePage
          ? await getNotifications(token, 3)
          : await getNotifications(token, 10, page);
        setNotifications(rows);
        if (count) {
          const totalPages = Math.ceil(count / limit);
          setTotalPages(totalPages);
        }
      } catch (message) {
        displaySnack(`${message}`, 'error', true);
      } finally {
        setHeaderLoading(false);
      }
    };
    getNotificationsData();
  }, []);

  const handleClick = (menuPageId: MenuPageIdEnum): void => {
    goToPageRouteById(menuPageId);
  };

  const nextPage = (): void => {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = (): void => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect((): void => {
    setHeaderLoading(true);
    getData(currentPage);
  }, [currentPage]);

  return (
    <div className={classes.notificationsContainer}>
      {itsAtHomePage ? null : (
        <PaginationComponent
          nextPageFuntion={nextPage}
          previousPageFuntion={previousPage}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      )}
      {notifications && notifications[0] ? (
        <div>
          {notifications.map((notification, index) => {
            return (
              <Grid
                key={index}
                spacing={2}
                alignItems={itsAtHomePage ? 'center' : 'flex-start'}
                container
              >
                <Grid item xs={'auto'}>
                  <Typography variant={'h5'} className={classes.dateDay}>
                    {new Date(notification?.notificationCreatedAt).getDate()}
                  </Typography>
                  <Typography className={classes.dateMouth}>
                    {formatMonthByMonthId(
                      new Date(notification?.notificationCreatedAt).getMonth(),
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography noWrap className={classes.notificationMessage}>
                    {notification?.title}
                  </Typography>
                  <Typography
                    noWrap={itsAtHomePage}
                    className={classes.notificationInformation}
                  >
                    {notification?.message}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
          {itsAtHomePage ? (
            <Typography
              onClick={
                itsAtHomePage
                  ? (): void => handleClick(MenuPageIdEnum.NOTIFICATIONS)
                  : null
              }
              className={classes.notificationViewMore}
            >
              Ver mais
            </Typography>
          ) : (
            <PaginationComponent
              nextPageFuntion={nextPage}
              previousPageFuntion={previousPage}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          )}
        </div>
      ) : (
        <div className={classes.thereNotNotifications}>
          <VagasIcon fontSize={125} fill="lightgray" />
          <Typography className={classes.thereNotNotificationsMessage}>
            Você não tem nenhuma notificação
          </Typography>
        </div>
      )}
    </div>
  );
};
