import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { MenuPageInterface } from '../interface/menu-page.interface';
import { BuildingPage } from '../page/building.page';

export function RouterSwitchComponent(): JSX.Element {
  const [routeMenuPages, setRouteMenuPages] = useState<MenuPageInterface[]>([]);
  const { authenticatedUser } = useAuthContext();
  const { menuPages } = useMenuContext();

  useEffect((): void => {
    setRouteMenuPages(
      menuPages.filter(
        (menuPage) =>
          (!menuPage.restrictedToUserTypes ||
            (authenticatedUser &&
              menuPage.restrictedToUserTypes.includes(
                authenticatedUser.type,
              ))) &&
          (!menuPage.isPrivate || authenticatedUser),
      ),
    );
  }, [menuPages, authenticatedUser]);

  return (
    <Switch>
      {routeMenuPages.map(({ id, component, path }) => (
        <Route
          key={id}
          path={path}
          exact={false}
          component={component || BuildingPage}
        ></Route>
      ))}
    </Switch>
  );
}
