import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';
import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { useSnackbarContext } from '../context/snackbar.context';
import { GateAvailabilityInterface } from '../interface/gate-availability.interface';
import { ParkingSpotRentalTimeInterface } from '../interface/parking-spot-rental-time.interface';
import {
  getAvailabilityByGateAndCondominium,
  getGatesAvailabilities,
} from '../service/parking-spot.service';
import TimeListComponent from './time-list.component';
import moment, { Moment } from 'moment';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { sortByGate } from '../common/util.common';

const useStyles = makeStyles(() => ({
  cardHeader: {
    background: 'linear-gradient(90deg, #1B46FF 40%, #6abeff 100%);',
    color: '#FFF',
  },
  cardHeaderIcon: {
    color: '#FFF',
    display: 'flex',
    fontSize: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  line: {
    borderLeft: `0.5px solid #e6e6e6`,
    paddingLeft: 5,
    paddingRight: 5,
  },
  data: {
    color: 'grey',
    fontSize: 15,
    fontWeight: 'bold',
    marginLeft: 5,
  },
  gate: {
    color: 'grey',
    fontSize: 15,
    marginLeft: 5,
  },
}));

interface TimeListItemProps {
  gate: string;
  lastAvailabilityDate: string;
  firstAvailabilityDate: string;
  parkingSpotRentalTimes: ParkingSpotRentalTimeInterface[];
}

const TimesListItem: React.FC<TimeListItemProps> = ({
  gate,
  parkingSpotRentalTimes,
  firstAvailabilityDate,
  lastAvailabilityDate,
}: TimeListItemProps) => {
  const [currentComponentDate, setCurrentComponentDate] = useState<Moment>(
    moment(firstAvailabilityDate),
  );
  const [rentalTimes, setRentalTimes] = useState<
    ParkingSpotRentalTimeInterface[]
  >(parkingSpotRentalTimes);
  const [totalPageNumber, setTotalPageNumber] = useState<number>(1);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const { condominium, setHeaderLoading, isHeaderLoading } = useMenuContext();
  const { displaySnack } = useSnackbarContext();
  const { token } = useAuthContext();
  const classes = useStyles();

  useEffect((): void => {
    const daysDiff = moment(lastAvailabilityDate).diff(
      currentComponentDate,
      'days',
      true,
    );
    setTotalPageNumber(Math.abs(Math.ceil(daysDiff)) + 1);
  }, []);

  const nextPage = async (): Promise<void> => {
    if (currentPageNumber === totalPageNumber || isHeaderLoading) return;
    setHeaderLoading(true);
    setCurrentComponentDate(currentComponentDate.add(1, 'days'));
    setCurrentPageNumber(currentPageNumber + 1);
    try {
      const rentalTimesResponse = await getAvailabilityByGateAndCondominium(
        condominium.id,
        gate,
        currentComponentDate.format('yyyy-MM-DD'),
        token,
      );
      setRentalTimes(rentalTimesResponse);
    } catch (message) {
      displaySnack(`${message}`, 'error', true);
    } finally {
      setHeaderLoading(false);
    }
  };

  const previousPage = async (): Promise<void> => {
    if (currentPageNumber === 1 || isHeaderLoading) return;
    setHeaderLoading(true);
    setCurrentComponentDate(currentComponentDate.subtract(1, 'days'));
    setCurrentPageNumber(currentPageNumber - 1);
    try {
      const rentalTimesResponse = await getAvailabilityByGateAndCondominium(
        condominium.id,
        gate,
        currentComponentDate.format('yyyy-MM-DD'),
        token,
      );
      setRentalTimes(rentalTimesResponse);
    } catch (message) {
      displaySnack(`${message}`, 'error', true);
    } finally {
      setHeaderLoading(false);
    }
  };

  return (
    <>
      <Grid item xs={3}>
        <Typography className={classes.data} align="center">
          {currentComponentDate.format('DD/MM/YYYY') ===
          moment().format('DD/MM/YYYY')
            ? 'Hoje'
            : currentComponentDate.format('DD/MM')}
        </Typography>
        <Typography className={classes.gate} align="center">
          {gate}
        </Typography>
      </Grid>
      <Grid item xs={9} className={classes.line}>
        <TimeListComponent
          date={currentComponentDate.format('yyyy-MM-DD')}
          parkingSpotRentalTimes={rentalTimes}
          hidePreviousHoursIfCurrentDay={false}
          previousPage={currentPageNumber !== 1 ? previousPage : null}
          nextPage={
            currentPageNumber < totalPageNumber
              ? nextPage
              : (): void =>
                  displaySnack('Não há mais disponibilidades', 'info', false)
          }
        />
      </Grid>
    </>
  );
};

const TimesAvailableComponent: React.FC = () => {
  const [gatesAvailabilities, setGatesAvailabilities] = useState<
    GateAvailabilityInterface[]
  >([]);
  const classes = useStyles();
  const { condominium, setHeaderLoading } = useMenuContext();
  const { displaySnack } = useSnackbarContext();
  const { token } = useAuthContext();
  const { goToPageRouteById } = useMenuContext();

  useEffect((): void => {
    (async (): Promise<void> => {
      try {
        setGatesAvailabilities([]);
        setHeaderLoading(true);

        if (!!condominium) {
          const gates = await getGatesAvailabilities(condominium.id, token);
          setGatesAvailabilities(gates);
        }

        setHeaderLoading(false);
      } catch (message) {
        displaySnack(`${message}`, 'error', true);
        setHeaderLoading(false);
      }
    })();
  }, [condominium]);

  return (
    <Card>
      <CardHeader
        title={'Horários disponíveis'}
        className={classes.cardHeader}
        titleTypographyProps={{ variant: 'h6' }}
        action={
          <IconButton
            onClick={(): void =>
              goToPageRouteById(MenuPageIdEnum.NEW_RESERVATION_PAGE)
            }
          >
            <ArrowIcon className={classes.cardHeaderIcon} />
          </IconButton>
        }
      />
      <Grid
        item
        container
        direction="row"
        justify="center"
        alignItems="center"
        xs={12}
      >
        {gatesAvailabilities.sort(sortByGate).map((gate, index) => (
          <TimesListItem key={index} {...gate} />
        ))}
      </Grid>
    </Card>
  );
};

export default TimesAvailableComponent;
