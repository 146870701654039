import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React, { Fragment } from 'react';

const useStyles = makeStyles({
  root: {
    marginTop: '4%',
    marginBottom: '4%',
  },
  sideBar: {
    borderRadius: '2px 0px 0px 2px',
    backgroundColor: '#3FB7F8',
    color: 'white',
    padding: 20,
  },
  mainContent: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
  },
  title: {
    lineHeight: 1,
    marginBottom: 5,
    marginTop: 10,
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 1,
  },
});

interface CardContainerProps {
  headerTitle?: string;
  headerSubtitle?: string;
  sideText?: string;
  children?: React.ReactNode;
}

const CardContainerComponent: React.FC<CardContainerProps> = ({
  headerTitle,
  headerSubtitle,
  children,
  sideText,
}: CardContainerProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(min-width:380px)');
  return (
    <Paper elevation={3} className={classes.root}>
      <Grid container direction="row">
        <Grid
          item
          container
          justify="center"
          xs={3}
          sm={2}
          className={classes.sideBar}
        >
          <Typography variant={isMobile ? 'h5' : 'body1'} component="p">
            {sideText}
          </Typography>
        </Grid>
        <Grid item xs={9} sm={10} className={classes.mainContent}>
          <>
            {(!headerTitle && !headerSubtitle) || (
              <>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  className={classes.title}
                >
                  {headerTitle}
                </Typography>
                <Typography
                  className={classes.subtitle}
                  variant="body1"
                  color="textSecondary"
                >
                  {headerSubtitle}
                </Typography>
                <hr style={{ opacity: 0.2 }} />
              </>
            )}

            {React.Children.map(children, (child) => (
              <Fragment>{child}</Fragment>
            ))}
          </>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CardContainerComponent;
