import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  createStyles,
  Button,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { ContainerComponent } from '../component/container.component';
import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import BookingAvailabilityModalComponent from '../component/booking-availability-modal.component';
import { PreReservationInterface } from '../interface/pre-reservation.interface';
import CardContainerComponent from '../component/card-container.component';
import { useSnackbarContext } from '../context/snackbar.context';
import { createReservation } from '../service/reservation.service';
import ReserveSpotDatesComponent from '../component/reserve-spot-dates.component';
import BalanceDisplayComponent from '../component/balance-display.component';
import { consultWalletBalance } from '../service/wallet.service';
import CondominiumFloorPlanModalComponent from '../component/condominium-floor-plan-modal.component';
import { useUrlQuery } from '../common/common.hook';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: '100%',
      color: '#6abeff',
    },
    imageButtonBase: {
      width: '100%',
      maxWidth: '500px',
    },
    infoText: {
      marginLeft: 17,
    },
  }),
);

const CreateNewReservationPage: React.FC = () => {
  const queryTime = useUrlQuery('time');
  const queryDate = useUrlQuery('date');
  const isReserveNow = !!queryTime && !!queryDate;
  const isMobile = useMediaQuery('(max-width:800px)');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const classes = useStyles(),
    { token, authenticatedUser } = useAuthContext(),
    {
      setMenuPageById,
      setHeaderLoading,
      isHeaderLoading,
      goToPageRouteById,
    } = useMenuContext(),
    { displaySnack } = useSnackbarContext(),
    [
      availabilitySpotToReservation,
      setAvailabilitySpotToReservation,
    ] = useState<PreReservationInterface>(null),
    [userTotalAmount, setUserTotalAmount] = useState<number>(0);

  useEffect(() => { setMenuPageById(MenuPageIdEnum.NEW_RESERVATION_PAGE); },[]);

  useEffect(() => {
    (async (): Promise<void> => {
      setHeaderLoading(true);
      setModalOpen(false);
      const { totalAmount } = authenticatedUser?.wallet ? await consultWalletBalance(
        authenticatedUser?.wallet?.id,
        token,
        false,
      ) : {totalAmount: 0.00};
      setUserTotalAmount(totalAmount);
      setHeaderLoading(false);
    })();
  }, []);

  const onReserve = async (
    availableParkingSpotsToReservation: PreReservationInterface,
  ): Promise<void> => {
    setHeaderLoading(true);
    try {
      await createReservation(
        availableParkingSpotsToReservation,
        token,
        isReserveNow,
      );
      displaySnack('Reserva criada com sucesso!', 'success', true);
      goToPageRouteById(MenuPageIdEnum.MY_RESERVATIONS);
      setAvailabilitySpotToReservation(null);
    } catch (message) {
      displaySnack(message, 'error', true);
    } finally {
      setModalOpen(false);
      setHeaderLoading(false);
    }
  };

  return (
    <>
      {isMobile ? (
        <BalanceDisplayComponent
          balance={userTotalAmount}
        />
      ) : (
        <ContainerComponent>
          <BalanceDisplayComponent
            balance={userTotalAmount}
          />
        </ContainerComponent>
      )}

      <ContainerComponent>
        <>
          <BookingAvailabilityModalComponent
            setParkingSpot={(value: PreReservationInterface): void =>
              setAvailabilitySpotToReservation(value)
            }
            isReserveNow={isReserveNow}
          />
          {availabilitySpotToReservation ? (
            <CardContainerComponent
              sideText={Number(
                availabilitySpotToReservation.totalAmount,
              ).toLocaleString('pt-br', {
                currency: 'BRL',
                style: 'currency',
              })}
            >
              <>
                <ReserveSpotDatesComponent
                  startDate={availabilitySpotToReservation.startDate}
                  endDate={availabilitySpotToReservation.endDate}
                  parkingSpotsBooking={availabilitySpotToReservation.parkingSpots.map(
                    (availability) => ({
                      startDate: availability.startDate,
                      startTime: availability.startTime,
                      endDate: availability.endDate,
                      endTime: availability.endTime,
                    }),
                  )}
                />
                <div className={classes.infoText}>
                  <Typography color="textSecondary" variant="caption">
                    ATENÇÃO: 30 minutos antes da hora inicial você saberá em
                    qual vaga deve estacionar e não poderá mais cancelar a
                    reserva
                  </Typography>
                </div>
                <CondominiumFloorPlanModalComponent
                  open={modalOpen}
                  setOpen={setModalOpen}
                  condominiumParam={null}
                />
                <Button
                  disabled={isHeaderLoading}
                  className={classes.button}
                  onClick={(): Promise<void> =>
                    onReserve(availabilitySpotToReservation)
                  }
                >
                  Reservar
                </Button>
              </>
            </CardContainerComponent>
          ) : null}
        </>
      </ContainerComponent>
    </>
  );
};
export default CreateNewReservationPage;
