import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form';
import InputMask from 'react-input-mask';

interface FormOwnerUserDataComponentProps {
  title: string;
  checkBoxText: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useFormMethods: UseFormMethods<any>;
}

const useStyles = makeStyles({
  title: {
    color: '#6abeff',
    fontSize: 18,
    marginTop: '5%',
  },
  checkboxContainer: {
    marginTop: '10px',
  },
  userInformation: {
    width: '100%',
    minWidth: 120,
    marginTop: '10px',
  },
});

export const FormOwnerUserDataComponent: React.FC<FormOwnerUserDataComponentProps> = ({
  title,
  checkBoxText,
  useFormMethods,
}: FormOwnerUserDataComponentProps) => {
  const classes = useStyles();

  const { register, errors } = useFormMethods;

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name="isTheLegalGuardian"
            inputRef={register}
            color="primary"
          />
        }
        label={checkBoxText}
        className={classes.checkboxContainer}
      />
      <Typography className={classes.title}>{title}</Typography>
      <TextField
        label="Nome completo"
        name="name"
        inputRef={register}
        error={!!errors.name}
        helperText={errors?.name?.message}
        className={classes.userInformation}
      />
      <InputMask mask="999.999.999-99">
        {(): JSX.Element => (
          <TextField
            label="CPF"
            name="cpf"
            error={!!errors.cpf}
            helperText={errors?.cpf?.message}
            inputRef={register}
            className={classes.userInformation}
          />
        )}
      </InputMask>
    </>
  );
};
