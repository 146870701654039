import React, { FunctionComponent, SVGProps, useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  GridDirection,
  makeStyles,
  PropTypes,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { WalletTransactionResponseInterface } from '../interface/wallet-transaction-response.interface';
import { WalletTransactionsPagedResponseInterface } from '../interface/wallet-transactions-paged-response.interface';
import { WalletTransactionOriginEnum } from '../enum/wallet-transaction-origin.enum';
import { WalletTransactionOperationEnum } from '../enum/wallet-transaction-operation.enum';
import { CarIcon, DollarSignIcon, GarageIcon } from '../icons';
import { useMenuContext } from '../context/menu.context';
import { getTransactionsByWalletId } from '../service/wallet.service';
import { useAuthContext } from '../context/auth.context';
import { useSnackbarContext } from '../context/snackbar.context';

class DebitOrigins {
  public gridDirection: GridDirection = 'row';
  public textAlign: PropTypes.Alignment = 'left';
  constructor(
    public title: string,
    public origin: WalletTransactionOriginEnum,
    public SvgIcon: FunctionComponent<
      SVGProps<SVGSVGElement> & {
        title?: string;
      }
    >,
  ) {}
}

class CreditOrigins {
  public gridDirection: GridDirection = 'row-reverse';
  public textAlign: PropTypes.Alignment = 'right';
  constructor(
    public title: string,
    public origin: WalletTransactionOriginEnum,
    public SvgIcon: FunctionComponent<
      SVGProps<SVGSVGElement> & {
        title?: string;
      }
    >,
  ) {}
}

const debitOrigins: DebitOrigins[] = [
  new DebitOrigins(
    'Reserva',
    WalletTransactionOriginEnum.RESERVATION_PAYMENT,
    CarIcon,
  ),
  new DebitOrigins(
    'Saque',
    WalletTransactionOriginEnum.WITHDRAWAL,
    DollarSignIcon,
  ),
];

const creditOrigins: CreditOrigins[] = [
  new CreditOrigins(
    'Receita do aplicativo',
    WalletTransactionOriginEnum.APP_INCOME_BY_RESERVATION,
    GarageIcon,
  ),
  new CreditOrigins(
    'Reserva cancelada',
    WalletTransactionOriginEnum.REFUND_OF_RESERVATION_PAYMENT,
    CarIcon,
  ),
  new CreditOrigins(
    'Receita de reserva',
    WalletTransactionOriginEnum.RESERVATION_INCOME,
    GarageIcon,
  ),
  new CreditOrigins(
    'Compra de crédito',
    WalletTransactionOriginEnum.CREDIT_PURCHASE,
    DollarSignIcon,
  ),
  new CreditOrigins('Bônus', WalletTransactionOriginEnum.BONUS, DollarSignIcon),
];

interface TransactionHistoryProps {
  initialTransactionList: WalletTransactionsPagedResponseInterface;
  walletId: number;
}

const useStyles = makeStyles({
  icons: { margin: 10 },
  grayLine: {
    borderLeft: '2px solid gray',
    height: 100,
    opacity: 0.2,
    borderRadius: 100,
  },
  showMoreButton: {
    width: '100%',
    height: 70,
    color: 'white',
    backgroundColor: '#5abefc',
    margin: 0,
  },
});

const TransactionItemComponent: React.FC<WalletTransactionResponseInterface> = (
  transaction,
) => {
  const classes = useStyles();
  let data: DebitOrigins;

  if (transaction.operation === WalletTransactionOperationEnum.DEBIT)
    data = debitOrigins.find(({ origin }) => origin === transaction.origin);
  else data = creditOrigins.find(({ origin }) => origin === transaction.origin);
  return (
    <Grid
      container
      direction={data.gridDirection}
      justify="flex-start"
      alignItems="center"
      spacing={0}
    >
      <Grid
        item
        container
        direction={data.gridDirection}
        justify="flex-start"
        alignItems="center"
        xs={10}
      >
        <data.SvgIcon fill="gray" fontSize={28} className={classes.icons} />

        <div>
          <Typography
            align={data.textAlign}
            variant="subtitle2"
            color="textSecondary"
          >
            {data.title}
          </Typography>
          <Typography
            align={data.textAlign}
            variant="body2"
            color="textSecondary"
          >
            {transaction?.operation !==
              WalletTransactionOperationEnum.DEBIT || <>&#8722; </>}
            {Number(transaction?.total)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
          <Typography
            align={data.textAlign}
            variant="caption"
            component="p"
            color="textSecondary"
          >
            {moment(transaction.createdAt).format('DD/MM/YYYY')}
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="center"
        alignItems="center"
        xs={2}
      >
        <div className={classes.grayLine} />
      </Grid>
    </Grid>
  );
};

const TransactionHistoryComponent: React.FC<TransactionHistoryProps> = ({
  walletId,
  initialTransactionList,
}: TransactionHistoryProps) => {
  const { token } = useAuthContext();
  const { displaySnack } = useSnackbarContext();
  const { setHeaderLoading, isHeaderLoading } = useMenuContext();
  const classes = useStyles();
  const [transactions, setTransactions] = useState<
    WalletTransactionResponseInterface[]
  >([]);
  const [limit, setLimit] = useState<number>(40);
  useEffect(() => setTransactions(initialTransactionList?.rows), [
    initialTransactionList,
  ]);
  async function loadMoreTransactions(): Promise<void> {
    if (isHeaderLoading) return;
    setHeaderLoading(true);
    try {
      const { rows: transactions, count } = await getTransactionsByWalletId(
        token,
        walletId,
        limit === 0 ? undefined : limit,
      );
      setTransactions(transactions);
      if (limit < count) setLimit(limit + 20);
      else displaySnack('Não há mais dados para serem mostrados', 'info', true);
    } catch (errorMessage) {
      displaySnack(errorMessage, 'error', true);
    } finally {
      setHeaderLoading(false);
    }
  }

  return (
    <>
      <Container maxWidth="sm">
        {transactions.map((transaction) => (
          <TransactionItemComponent key={transaction.id} {...transaction} />
        ))}
      </Container>
      <Button
        onClick={async (): Promise<void> => loadMoreTransactions()}
        variant="contained"
        className={classes.showMoreButton}
      >
        Mostrar Mais
      </Button>
    </>
  );
};

export default TransactionHistoryComponent;
