import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { ParkingSpotStatusEnum } from '../enum/parking-spot-status.enum';

type AvaibilityPointProp = {
  status: ParkingSpotStatusEnum;
};

const useStyles = makeStyles(() =>
  createStyles({
    foo: (props: { backgroundColor: string }) => ({
      backgroundColor: props.backgroundColor,
      width: 12,
      height: 12,
      borderRadius: 10,
      margin: 1,
    }),
    fii: {
      border: '1px solid #bbb',
      width: 12,
      height: 12,
      borderRadius: 10,
      margin: 1,
    },
  }),
);

const AvailabilityPoint: React.FC<AvaibilityPointProp> = (prop) => {
  let pointColor = '';

  switch (prop.status) {
    case ParkingSpotStatusEnum.AVAILABLE:
      pointColor = '#5ABEFC';
      break;
    case ParkingSpotStatusEnum.HAS_RESERVATION:
      pointColor = '#1B46FF';
      break;
    case ParkingSpotStatusEnum.UNAVAILABLE:
      break;
  }

  const classes = useStyles({ backgroundColor: pointColor });
  if (pointColor !== '') return <div className={`${classes.foo}`} />;

  return <div className={classes.fii} />;
};

export default AvailabilityPoint;
