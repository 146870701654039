import { UserCondominiumInterface } from './user-condominium.interface';
import { UserApartmentInterface } from './user-apartment.interface';
import { ParkingSpotInterface } from './parking-spot.interface';
import { UserWalletInterface } from './user-wallet.interface';
export enum UserType {
  ADMIN = 'ADMIN',
  RESIDENT = 'RESIDENT',
}

export interface UserInterface {
  id: number;
  name: string;
  type: UserType;
  email: string;
  telephone: string;
  cpf: string;
  birthday: string;
  wallet?: UserWalletInterface;
  condominiums?: UserCondominiumInterface[];
  apartments?: UserApartmentInterface[];
  parkingSpots?: ParkingSpotInterface[];
}
