import React from 'react';
import {
  Card,
  Grid,
  makeStyles,
  useMediaQuery,
  Typography,
  Button,
} from '@material-ui/core';
import {
  detectDayOfWeekLongString,
  formatMonthByMonthId,
} from '../common/util.common';
import TimeListComponent from './time-list.component';
import { RentalTimesGroupInterface } from '../interface/rental-times-group.interface';
import { ParkingSpotRentalTimeInterface } from '../interface/parking-spot-rental-time.interface';

interface StylesInterface {
  bgColor: string;
  isMobile: boolean;
  cardHeight: number;
}

interface TimesAndReservationsItemProps {
  rentalTimesInfo: RentalTimesGroupInterface;
  onButtonClick: (
    date?: string,
    parkingSpotRentalTimes?: ParkingSpotRentalTimeInterface[],
  ) => void;
}

const useStyles = makeStyles({
  dateContainer: (props: StylesInterface) => ({
    backgroundColor: props.bgColor,
    width: props.isMobile ? '15%' : '30%',
    height: props.cardHeight,
    color: '#FFF',
  }),
  availabilitiesContainer: (props: StylesInterface) => ({
    width: props.isMobile ? '85%' : '70%',
    height: props.cardHeight,
    paddingLeft: 8,
    paddingRight: 8,
  }),
  card: {
    marginBottom: 10,
  },
  button: {
    color: '#6ABEFF',
  },
  timesComponent: {
    marginTop: 6,
    marginLeft: 8,
  },
});

const TimesAndReservationsItemComponent: React.FC<TimesAndReservationsItemProps> = ({
  rentalTimesInfo,
  onButtonClick,
}: TimesAndReservationsItemProps) => {
  const isMobile = useMediaQuery('(min-width: 700px)');
  const classes = useStyles({
    bgColor: rentalTimesInfo.parkingSpotRentalTimes.length
      ? '#6abeff'
      : '#9E9E9E',
    isMobile,
    cardHeight: 80,
  });
  return (
    <Card className={classes.card}>
      <Grid container direction="row" alignItems="stretch">
        <Grid
          container
          className={classes.dateContainer}
          justify="center"
          alignItems="center"
          direction="column"
        >
          <Grid container direction="row" justify="center" alignItems="center">
            <Typography>
              {rentalTimesInfo.date.substring(8, 10) +
                ' ' +
                formatMonthByMonthId(
                  parseInt(rentalTimesInfo.date.substring(5, 7)) - 1,
                )}
            </Typography>
          </Grid>
          <Typography>
            {detectDayOfWeekLongString(
              new Date(rentalTimesInfo?.date + 'T00:00:00').getDay(),
            )}
          </Typography>
        </Grid>
        <Grid
          container
          className={classes.availabilitiesContainer}
          justify="space-between"
          alignItems="flex-start"
          direction="column"
        >
          <TimeListComponent
            parkingSpotRentalTimes={rentalTimesInfo.parkingSpotRentalTimes}
            date={rentalTimesInfo.date}
          />
        </Grid>
        <Button
          variant="contained"
          style={{
            backgroundColor: '#6abeff',
            color: 'white',
            width: '100%',
          }}
          onClick={(): void =>
            onButtonClick(
              rentalTimesInfo.date,
              rentalTimesInfo.parkingSpotRentalTimes,
            )
          }
        >
          Editar
        </Button>
      </Grid>
    </Card>
  );
};

export default TimesAndReservationsItemComponent;
