import React, { useEffect, useState } from 'react';
import { useMenuContext } from '../context/menu.context';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Theme,
  CircularProgress,
  Modal,
} from '@material-ui/core';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { useQueryString } from '../common/common.hook';
import { ContainerComponent } from '../component/container.component';
import { emailConfirm } from '../service/auth.service';
import { VagasIcon } from '../icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    circularProgress: {
      position: 'fixed',
      bottom: 300,
      marginLeft: '50%',
      fontSize: 70,
    },
    logo: {
      display: 'block',
      marginTop: '50px',
      marginBottom: '20px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
);

export function AccountVerificationPage(): JSX.Element {
  const classes = useStyles();
  const { logoutAndRedirect, setMenuPageById } = useMenuContext();
  const [isComponentLoading, setComponentLoading] = useState<boolean>(false);
  const [isAccountActive, setIsAccountActive] = useState<boolean>(false);
  const [inconsistentData, setInconsistentData] = useState<boolean>(false);
  const userId = useQueryString('userId');
  const hash = useQueryString('hash');

  useEffect(() => {
    if (!userId || !hash) {
      setInconsistentData(true);
    }
  }, [userId, hash]);

  useEffect(() => {
    setMenuPageById(MenuPageIdEnum.ACCOUNT_VERIFICATION);
  },[]);

  const activeAccount = async (): Promise<void> => {
    try {
      setComponentLoading(true);
      await emailConfirm(parseInt(userId), hash);
      setIsAccountActive(true);
      setTimeout(async () => await logoutAndRedirect(), 1000);
    } catch (errorCode) {
      setComponentLoading(false);
      switch (errorCode) {
        case 422:
          setIsAccountActive(true);
          setTimeout(async () => await logoutAndRedirect(), 4000);
          break;
        case 404:
          setInconsistentData(true);
          setTimeout(async () => await logoutAndRedirect(), 3000);
          break;
        default:
          setInconsistentData(true);
          setTimeout(async () => await logoutAndRedirect(), 3000);
      }
    }
  };

  return (
    <ContainerComponent>
      <>
        {isComponentLoading ? (
          <Modal open>
            <div>
              <CircularProgress className={classes.circularProgress} />
            </div>
          </Modal>
        ) : null}
        <Grid container justify={'center'} spacing={3}>
          <VagasIcon className={classes.logo} fill="lightgray" fontSize={125} />

          {inconsistentData ? (
            <>
              <Grid item xs={12}>
                <div className={classes.paper}>
                  <h3>
                    Dados inconsistentes, não foi possível ativar nenhuma conta.
                  </h3>
                </div>
              </Grid>
              <Grid item xs={'auto'}>
                <Button
                  color={'primary'}
                  variant={'outlined'}
                  onClick={async (): Promise<void> => await logoutAndRedirect()}
                >
                  Ir para a página de login
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <div className={classes.paper}>
                  {isAccountActive ? (
                    <h3>
                      Sua conta está ativa, estamos lhe redirecionando para a
                      página de login...
                    </h3>
                  ) : (
                    <h3>
                      Para finalizar a ativação de sua conta, pressione o botão
                      abaixo.
                    </h3>
                  )}
                </div>
              </Grid>
              <Grid item xs={'auto'}>
                <Button
                  disabled={isAccountActive}
                  color={'primary'}
                  variant={'outlined'}
                  onClick={activeAccount}
                >
                  Confirmar email
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </>
    </ContainerComponent>
  );
}
