import React, { useEffect } from 'react';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { makeStyles, Typography } from '@material-ui/core';
import { ContainerComponent } from '../component/container.component';
import { useMenuContext } from '../context/menu.context';

const useStyles = makeStyles({
  container: {
    marginTop: '3%',
    marginBottom: '3%',
  },
});

export function TermsOfUsePage(): JSX.Element {
  const classes = useStyles(),
    { setMenuPageById } = useMenuContext();

  useEffect(() => { setMenuPageById(MenuPageIdEnum.TERMS); },[]);

  return (
    <ContainerComponent>
      <div className={classes.container}>
        <Typography variant="h5" gutterBottom paragraph align="justify">
          Regras para uso da plataforma VAGA321 e privacidade dos seus dados
        </Typography>
        <Typography variant="subtitle2" gutterBottom align="justify">
          Leia atentamente os termos a seguir pois eles contêm informações
          importantes sobre seus direitos, recursos e obrigações quanto ao uso
          da plataforma, bem como a responsabilidade do VAGA321 sob seus dados.
          Ao acessar ou utilizar a nossa plataforma, você concorda em cumprir
          com estes termos e em estar sujeito a eles.
        </Typography>
        <Typography variant="body2" gutterBottom paragraph align="right">
          Última atualização: 10 de dezembro de 2020.
        </Typography>
        <Typography variant="body2" gutterBottom paragraph align="justify">
          Obrigado por utilizar VAGA321, plataforma que oferece serviços
          baseados na economia do futuro, a economia compartilhada! Estes termos
          constituem um contrato vinculante entre você e a VAGA321, regendo seu
          acesso e utilização dos serviços oferecidos pela VAGA321, incluindo
          acesso à seus domínios e subdomínios bem como quaisquer outros sites
          nos quais a VAGA321 disponibilize seus serviços. Todos sites,
          aplicativos e serviços vinculados à VAGA321 serão referidos aqui como
          a <strong>“Plataforma VAGA321”</strong>. Quando estes termos
          mencionarem <strong>“VAGA321”</strong>, <strong>“nós”</strong>,
          <strong>“nos”</strong>, <strong>“nosso”</strong>, e outras variações
          gramaticais da primeira pessoa do plural, eles se referem à Plataforma
          VAGA321 com a qual você está firmando um contrato de conduta, com
          direitos e deveres.{' '}
        </Typography>
        <Typography variant="body2" gutterBottom paragraph align="justify">
          Para melhor compreensão deste texto, alguns termos utilizados neste
          documento são definidos a seguir. O responsável pela vaga é também
          chamado de <strong>“Anfitrião”</strong>; seu espaço de garagem
          disponibilizado é denominado <strong>“Vaga”</strong>; o espaço onde os
          imóveis garagem estão inseridos é denominado{' '}
          <strong>“Condomínio”</strong>; o usuário que reserva e ocupa uma vaga
          é chamado de <strong>“Hóspede”</strong>; ambos{' '}
          <strong>“Anfitrião”</strong> e “Hóspede” são identificados como
          <strong>“Usuário”</strong>.
        </Typography>
        <Typography variant="body2" gutterBottom paragraph align="justify">
          Os usuários da plataforma, tanto responsáveis/proprietários das vagas
          quanto os moradores que reservam as vagas de estacionamento, são
          responsáveis por identificar, compreender e cumprir com todas as leis,
          regras e regulamentações aplicáveis à disponibilização de vagas de
          estacionamento dentro de seus condomínios e entre seus condôminos. Por
          exemplo, alguns condomínios solicitam o cadastro prévio de todo
          automóvel que acessa os espaços comuns do condomínio, gerando assim a
          responsabilidade do morador que reservou uma vaga, atentar para este
          critério e cumpri-lo.
        </Typography>
        <Typography variant="h6" gutterBottom paragraph align="justify">
          1. Escopo dos Serviços VAGA321
        </Typography>
        <Typography variant="body2" gutterBottom paragraph align="justify">
          A Plataforma VAGA321 é uma plataforma on-line que permite à usuários
          cadastrados e habilitados como responsáveis por um imóvel do tipo
          garagem, disponibilizarem suas vagas de estacionamento para que outros
          usuários da plataforma, também cadastrados e habilitados, possam
          ocupá-las por períodos diversos, não constantes e por prazos
          determinados.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          A Plataforma VAGA321 não é proprietária, não constrói, vende, revende,
          fornece, controla ou gerencia qualquer vaga de estacionamento em
          qualquer condomínio. Os anfitriões são os únicos responsáveis por suas
          vagas bem como pela administração dos períodos de disponibilização das
          mesmas. Quando os usuários da plataforma disponibilizam uma vaga ou
          realizam uma reserva, eles celebram um contrato diretamente um com o
          outro. A VAGA321 não é e não se torna parte ou outro participante de
          qualquer relacionamento contratual entre os usuários, tampouco a
          VAGA321 é uma corretora de imóveis ou seguradora.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Embora possamos ajudar a facilitar a resolução de discordâncias entre
          usuários da plataforma, a VAGA321 não tem qualquer controle sobre e
          não garante (i) a existência, condição, segurança, sustentabilidade ou
          licitude de qualquer vaga disponibilizada, (ii) a veracidade e a
          precisão de quaisquer descrições da vaga ou outros conteúdos de um
          usuário, ou (iii) o desempenho ou a conduta de qualquer usuário ou
          terceiro. Quaisquer referências a um usuário sendo habilitado a
          reservar e disponibilizar vaga em um condomínio apenas indica que o
          mesmo completou um processo de verificação de cadastros, e nada mais.
          Nenhuma dessas validações significa endosso, certificação ou garantia
          fornecidos pela VAGA321 sobre o usuário, inclusive sobre a identidade
          ou o histórico de conduta do usuário utilizando a plataforma. Imagens
          que possam identificar as vagas de garagem ou do condomínio, visam
          apenas indicar uma representação visual do espaço a ser utilizado, não
          representando nenhuma garantia de veracidade ou comprovação do espaço
          disponibilizado por parte da VAGA321.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Ao optar por utilizar VAGA321 seu relacionamento com a plataforma está
          limitado a ser um usuário, independente, e não um funcionário, agente,
          associado ou parceiro da VAGA321.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          A Plataforma VAGA321 pode conter links para sites ou recursos de
          terceiros, sendo que eles podem estar sujeitos a termos, condições e
          práticas de privacidade diferentes. A VAGA321 não é responsável pela
          disponibilidade ou precisão dos referidos serviços de terceiros,
          tampouco pelo conteúdo, pelos produtos ou serviços disponíveis nestes.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Devido à natureza da Internet, a VAGA321 não pode garantir uma
          disponibilidade e acessibilidade ininterruptas e contínuas da
          plataforma. Sendo assim, pode restringir a qualquer momento, sem
          prévio aviso, a disponibilidade dos serviços ou de certas áreas ou
          recursos a eles relacionados caso seja necessário, considerando os
          limites de capacidade, segurança ou integridade de nossos servidores,
          ou para realizar medidas de manutenção que garantam o funcionamento
          devido ou melhorado da plataforma.
        </Typography>

        <Typography variant="h6" gutterBottom paragraph align="justify">
          2. Utilização e verificação de usuário
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          É necessário que você tenha pelo menos 18 anos e que seja capaz de
          celebrar contratos vinculantes para acessar e utilizar a Plataforma
          VAGA321, ou, registrar uma conta na plataforma.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          É necessário que você tenha pelo menos 18 anos e que seja capaz de
          celebrar contratos vinculantes para acessar e utilizar a Plataforma
          VAGA321, ou, registrar uma conta na plataforma.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          A VAGA321 pode tornar o acesso e o uso da sua plataforma ou certas
          áreas ou recursos desta, sujeitas a determinadas condições ou
          exigências.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Não assumimos a responsabilidade pela confirmação de qualquer
          identidade de um usuário. Para fins de transparência e prevenção de
          fraude, e conforme permitido pelas leis aplicáveis, nós podemos, mas
          não temos a obrigação de (i) solicitar aos usuários que forneçam
          alguma identificação oficial ou outras informações, e (ii) fazer uma
          amostragem de usuários comparada a bancos de dados de terceiros ou
          outras fontes e solicitar relatórios de prestadores de serviço.
        </Typography>

        <Typography variant="h6" gutterBottom paragraph align="justify">
          3. Registro da conta
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Você deve cadastrar uma conta (“Conta VAGA321”) para acessar e
          utilizar os recursos da plataforma. Você pode cadastrar uma Conta
          VAGA321 indicando seu nome, e-mail, telefone celular e criando uma
          senha.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Você deve fornecer informações atuais, precisas e completas durante o
          processo de registro e manter as informações de sua conta atualizadas.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Você é responsável por manter a confidencialidade e a segurança de
          suas credenciais da Conta VAGA321 e não pode divulgar as referidas
          credenciais a terceiros. Você deve notificar a VAGA321 imediatamente
          caso tome conhecimento, ou tenha qualquer motivo para suspeitar de que
          suas credenciais foram extraviadas, furtadas, indevidamente
          apropriadas ou em caso de qualquer uso ou suspeita de uso não
          autorizado de sua conta.
        </Typography>

        <Typography variant="h6" gutterBottom paragraph align="justify">
          4. Conteúdos
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          A Plataforma VAGA321 e seu conteúdo podem, em sua totalidade ou em
          parte, ser protegidos por direitos autorais e marcas comerciais. Você
          reconhece e concorda que a Plataforma VAGA321 e seu conteúdo,
          incluindo todos os direitos de propriedade intelectual associados, são
          propriedade exclusiva da VAGA321 e/ou de seus licenciadores ou
          terceiros autorizados. Você não deve remover, alterar ou ocultar
          nenhum direito autoral, marca comercial, marca de serviço ou outros
          direitos de propriedade incorporados ou que acompanhem a plataforma e
          seu conteúdo. Todas as marcas comerciais, marcas de serviço,
          logotipos, nomes comerciais e quaisquer outros identificadores de
          fonte da VAGA321 usados em, ou em relação à Plataforma VAGA321 são
          marcas de domínio exclusivo da VAGA321 no Brasil e exterior.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Você não deve utilizar, copiar, adaptar, modificar, preparar trabalhos
          derivados, distribuir, licenciar, vender, transferir, exibir
          publicamente, realizar publicamente, transmitir ou explorar a
          Plataforma VAGA321. Nenhuma licença ou direitos são concedidos por
          implicação ou de outra forma sob quaisquer direitos de propriedade
          intelectual pertencentes ou controlados pela VAGA321 ou por seus
          licenciadores.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Sujeito à sua conformidade com estes termos, a VAGA321 lhe concede uma
          licença intransferível, revogável, não exclusiva, limitada para (i)
          acessar e utilizar a Plataforma VAGA321 em seu(s) dispositivo(s)
          pessoal(is); e (ii) acessar e visualizar o conteúdo disponibilizado
          na, ou através da, Plataforma VAGA321 e acessível a você, apenas para
          seu uso pessoal, como pessoa física.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Você é exclusivamente responsável por todo conteúdo de usuário que
          disponibilizar na, ou através da Plataforma VAGA321. Assim, você
          declara e garante que, ou você é o proprietário único e exclusivo de
          toda informação e conteúdo que disponibilizar ou você tem todos os
          direitos, as licenças, autorizações e liberações necessárias para
          conceder à VAGA321 o direito de acesso ao conteúdo disponibilizado,
          concedendo à plataforma, utilizar as informações e divulga-las
          conforme estas regras de privacidade.
        </Typography>

        <Typography variant="h6" gutterBottom paragraph align="justify">
          5. Taxas de Serviço
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          A plataforma VAGA321 poderá cobrar taxas dos Anfitriões (
          <strong>“Taxas do Anfitrião”</strong>) e/ou Hóspedes (
          <strong>“Taxas do Hóspede”</strong>) (coletivamente,{' '}
          <strong>“Taxas de Serviço”</strong>) em consideração ao uso da
          Plataforma VAGA321.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Quaisquer Taxas de Serviço aplicáveis de cobrança (incluindo quaisquer
          impostos aplicáveis) serão apresentadas ao Anfitrião/Hóspede antes da
          publicação ou reserva de uma vaga. As Taxas de Serviço poderão ser
          alteradas a qualquer momento.
        </Typography>

        <Typography variant="h6" gutterBottom paragraph align="justify">
          6. Tratamento e Privacidade de Dados
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          As regras a seguir, quanto ao tratamento da privacidade dos dados que
          você fornece à Plataforma VAGA321, explica como coletamos, utilizamos,
          processamos e divulgamos suas informações na Plataforma. Nós coletamos
          as seguintes informações que você compartilha conosco ao utilizar a
          plataforma VAGA321.
        </Typography>

        <ul>
          <li>
            <Typography variant="body2" gutterBottom paragraph align="justify">
              <strong>Informações de conta. </strong>
              Quando cadastra uma conta no VAGA321, nós solicitamos as seguintes
              informações: nome, endereço de e-mail e telefone celular. Para
              iniciar a utilização do aplicativo em algum condomínio,
              solicitamos que você nos informe: o nome do condomínio, o nome do
              responsável pelo imóvel, seu CPF, o apartamento pelo qual é
              responsável e uma imagem como comprovação da responsabilidade pelo
              imóvel. Além destes dados, caso você tenha o direito de exploração
              de uma ou mais vagas de estacionamento, pode vincular tais vagas
              ao seu cadastro informando a identificação da vaga, por qual
              entrada é possível acessá-la, observações opcionais, além de
              registrar que está responsável pela vaga a ser vinculada à sua
              conta. Certas partes de seu perfil de usuário (como seu nome, seu
              apartamento) podem ser compartilhadas com outros moradores do
              mesmo condomínio que o seu, desde que estejam habilitados na
              plataforma e após terem efetivado alguma transação de reserva com
              você na plataforma.
            </Typography>
          </li>
          <li>
            <Typography variant="body2" gutterBottom paragraph align="justify">
              <strong>
                Informações para recebimento ou compra de créditos/saldo.{' '}
              </strong>
              Nós solicitamos suas informações financeiras (por exemplo,
              informações de dados bancários) quando você solicita receber
              créditos gerados por reservas de suas vagas por esta modalidade.
              Para cada forma de transferência que você escolher utilizar haverá
              dados específicos a serem informados. Para a compra de créditos, a
              plataforma acessa e utiliza serviços de empresa terceira, onde
              esta é a responsável pela coleta dos seus dados (como número, data
              de validade e código de segurança do cartão de crédito) e também
              por toda transação financeira entre você, sua operadora do cartão
              e seu banco. A Plataforma VAGA321 apenas recebe a confirmação e
              liberação do valor monetário escolhido por você.
            </Typography>
          </li>
          <li>
            <Typography variant="body2" gutterBottom paragraph align="justify">
              <strong>Outras informações. </strong>
              Você irá nos informar horários em que sua vaga de estacionamento
              estará disponível para que seus vizinhos possam ocupá-la. Esta
              informação não aparecerá vinculada a você e nem à sua vaga de
              estacionamento para os usuários cadastrados na plataforma. Somente
              após confirmada um reserva da sua vaga, o usuário que realizou a
              reserva terá acesso às informações da vaga e seus dados básicos,
              como nome e apartamento, por exemplo.
            </Typography>

            <Typography variant="body2" gutterBottom paragraph align="justify">
              Você ainda poderá optar por nos fornecer informações quando for
              preencher algum formulário de contato, atualizar ou adicionar
              informações à sua conta, responder pesquisas, publicar em fóruns,
              participar de promoções, ou utilizar outros recursos da Plataforma
              VAGA321.
            </Typography>
          </li>
        </ul>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Quando você acessa a Plataforma VAGA321 nós coletamos informações
          sobre os serviços que você utiliza e como você os utiliza.
        </Typography>

        <ul>
          <li>
            <Typography variant="body2" gutterBottom paragraph align="justify">
              <strong>Informações de uso. </strong>
              Nós coletamos informações sobre suas interações com a Plataforma
              VAGA321, como as páginas ou outro conteúdo que você acessa, suas
              pesquisas de vagas, as reservas que você fez e outras ações na
              plataforma.
            </Typography>
          </li>
          <li>
            <Typography variant="body2" gutterBottom paragraph align="justify">
              <strong>Informações de localização. </strong>
              Quando você utiliza a Plataforma VAGA321, nós podemos coletar
              informações sobre sua localização geral (quando permitido por você
              no seu dispositivo).
            </Typography>
          </li>
          <li>
            <Typography variant="body2" gutterBottom paragraph align="justify">
              <strong>Dados de log. </strong>
              Nós coletamos as informações de log automaticamente quando você
              utiliza a Plataforma VAGA321. Essas informações incluem, entre
              outras coisas: detalhes sobre como você utilizou a plataforma,
              endereço de IP, número de acessos, informações de hardware e
              software, informações do dispositivo e informações de eventos do
              dispositivo (por exemplo, falhas fatais, tipo de navegador).
            </Typography>
          </li>

          <li>
            <Typography variant="body2" gutterBottom paragraph align="justify">
              <strong>Informações de transação. </strong>
              Nós coletamos informações relacionadas a suas transações na
              Plataforma VAGA321, incluindo a data e hora, por exemplo.
            </Typography>
          </li>
        </ul>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Nós podemos coletar informações que outros forneçam e combiná-las com
          as informações que coletamos na Plataforma VAGA321. Quando possível e
          permitido por lei, nós podemos utilizar informações recebidas de você
          para pesquisar informações que venham a ser necessárias para a
          garantia da qualidade e confiabilidade no serviço oferecido pela
          Plataforma VAGA321. Como exemplo nós podemos solicitar a emissão de
          certidões negativas ou imobiliárias para validar as informações que
          nos foram encaminhadas por você.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Nós utilizamos, armazenamos e processamos informações sobre você para
          fornecer, compreender, melhorar e desenvolver a Plataforma VAGA321 e
          criar e manter um ambiente mais seguro e confiável.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Visando a nos ajudar no fornecimento da Plataforma VAGA321, podemos
          compartilhar suas informações com empresas que eventualmente venham a
          integrar o fluxo de atividades de compartilhamento de vagas pela nossa
          plataforma. Essas empresas estarão cobertas também por estas políticas
          de tratamento de dados e privacidade.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          A VAGA321 pode divulgar suas informações ao judiciário, autoridades
          policiais ou governamentais, ou terceiros autorizados, se e na medida
          necessária para atender a lei ou caso essa divulgação seja
          razoavelmente necessária: (i) para cumprir com o processo legal e
          responder a alegações feitas contra a VAGA321, (ii) para responder a
          solicitações verificadas relacionadas a investigações criminais ou
          alegações ou suspeitas de atividade ilegal ou qualquer outra atividade
          que possa nos expor, expor você ou qualquer outro de nossos usuários à
          responsabilidade legal, (iii) para aplicar e administrar nossos termos
          de uso ou outros contratos com os membros, (iv) para investigação e
          prevenção de fraude, avaliação de risco, atendimento ao cliente,
          desenvolvimento de produto e para fins de depuração, ou (v) para
          proteger os direitos, a propriedade ou a segurança pessoal da VAGA321,
          de seus funcionários e membros. Nós tentaremos notificar os membros
          sobre essas solicitações, a menos que: (i) seja proibido fornecer
          aviso por conta do próprio processo legal, por ordem judicial que
          recebemos, ou pela lei aplicável, ou (ii) nós considerarmos que dar
          tal notificação seria fútil, ineficiente, poderia criar um risco de
          lesão ou dano físico a uma pessoa ou a um grupo, ou criaria ou
          aumentaria o risco de fraude sobre a propriedade da Plataforma VAGA321
          e seus membros.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Caso a VAGA321 se comprometa ou esteja envolvida em qualquer fusão,
          aquisição, recuperação, venda de ativos, falência ou caso de
          insolvência, poderemos vender, transferir ou compartilhar alguns ou
          todos os nossos ativos, incluindo suas informações pessoais. Neste
          caso, notificar-lhe-emos antes que suas Informações Pessoais sejam
          transferidas e estejam sujeitas a uma política de privacidade
          diferente.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Nós também podemos compartilhar informações agregadas (informações
          sobre nossos usuários que nós combinemos em conjunto, de modo que não
          mais identifique ou se refira a um usuário pessoa física) e
          informações não identificáveis pessoalmente para a análise de mercado,
          perfil demográfico, marketing e publicidade e outros fins comerciais.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          A Plataforma VAGA321 pode conter links para sites ou serviços de
          terceiros, como integrações de terceiros, serviços de marcas
          associadas, ou serviços de marcas de terceiros. A VAGA321 não é
          proprietária desses Parceiros Terceiros nem os controla, e quando você
          interage com eles, você pode fornecer informações diretamente a esse
          terceiro, à VAGA321, ou a ambos. Esses terceiros parceiros terão suas
          próprias regras sobre a coleta, a utilização e a divulgação de
          informações. Nós recomendamos que você revise as políticas de
          privacidade dos outros sites que você acessa.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          A Plataforma VAGA321 nasce com a ciência da existência e da vigência
          da Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018) e por
          isso se propõe a pautar as relações com seus usuários da maneira mais
          alinhada à esta legislação. Caso alguma relação de troca de
          informações entre você, usuário, e a plataforma, lhe pareça não
          compreensível ou lhe cause dúvida quanto à forma de tratamento dos
          seus dados, não hesitaremos em esclarecer de maneira totalmente
          transparente.
        </Typography>

        <Typography variant="h6" gutterBottom paragraph align="justify">
          7. Responsabilidades
        </Typography>

        <Typography variant="subtitle2" gutterBottom paragraph align="justify">
          É de responsabilidade do usuário todo o risco proveniente de seu
          acesso e uso da Plataforma VAGA321, sua publicação, anúncio, reserva
          ou ocupação de qualquer vaga por meio da Plataforma VAGA321, incluindo
          a responsabilidade civil, trabalhista, tributária e penal, ainda que a
          mesma venha a ser exigida diretamente da VAGA321. O usuário assumirá
          integralmente todo o valor decorrente de eventual condenação que a
          VAGA321 sofra ou venha a sofrer em decorrência de descumprimento por
          parte do usuário do presente termo.
        </Typography>

        <Typography variant="subtitle2" gutterBottom paragraph align="justify">
          Não é de responsabilidade da VAGA321 quaisquer danos materiais, lucros
          cessantes, perda de dados, interrupção do serviço, danos em
          dispositivos ou falhas em sistemas, ou quaisquer danos pessoais,
          corporais ou morais, decorrentes ou relacionados a estas condições, do
          uso ou da impossibilidade de utilizar a plataforma, ou, de seu anúncio
          ou reserva de qualquer vaga, incluindo a disponibilização ou uso de
          vaga.
        </Typography>

        <Typography variant="h6" gutterBottom paragraph align="justify">
          8. Disposições gerais
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Apreciamos e o incentivamos a fornecer comentários e sugestões de
          melhorias para a Plataforma VAGA321. Você pode nos enviar um feedback
          por e-mail ou através da seção Fale Conosco dentro da plataforma.
          Qualquer feedback que você nos enviar será considerado não
          confidencial e não será considerado como propriedade sua.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          A VAGA321 se reserva o direito de modificar estes termos a qualquer
          momento. Caso sejam alterados, publicaremos os termos revisados na
          Plataforma VAGA321 e atualizaremos a data de “Última Atualização”
          indicada neste texto. Também enviaremos a você, por e-mail, uma
          notificação informando a respeito da revisão. Caso discorde dos termos
          alterados você pode solicitar o cancelamento da sua conta, solicitando
          através do nosso endereço eletrônico ou da seção Fale Conosco da
          plataforma.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Caso qualquer disposição destes termos seja considerada inválida ou
          inexequível, a referida disposição será removida e não afetará a
          validade e a efetividade das disposições remanescentes aqui
          constantes.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          A não cobrança por parte da VAGA321 de qualquer direito ou disposição
          destes termos não constituirá uma renúncia de tal direito exceto se
          reconhecido e acordado por nós, por escrito.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Exceto se especificado em contrário, qualquer notificação ou outras
          comunicações com usuários permitidas ou solicitadas por estes termos
          serão feitas por escrito e enviadas por e-mail, por notificação ou por
          serviços de mensagem.
        </Typography>

        <Typography variant="body2" gutterBottom paragraph align="justify">
          Caso tenha alguma dúvida ou reclamação sobre estes termos ou qualquer
          outro questionamento sobre a Plataforma VAGA321, você pode entrar em
          contato conosco através do endereço eletrônico{' '}
          <a href="#">sac@vaga321.com.br</a>.
        </Typography>
      </div>
    </ContainerComponent>
  );
}
