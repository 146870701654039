import { makeStyles } from '@material-ui/core';

export default makeStyles({
  form: {
    position: 'relative',
    padding: '10px 10px 10px 10px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  uploadFilesBox: {
    marginTop: '5%',
  },
  buttonDiv: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5%',
    marginBottom: '5%',
    justifyContent: 'center',
  },
  buttonSubmit: {
    color: '#6abeff',
  },
  buttonBox: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '50px',
  },
  userInformation: {
    width: '100%',
    minWidth: 120,
    marginTop: '10px',
  },
});
