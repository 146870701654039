import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../context/auth.context';

import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import ptBR from 'date-fns/locale/pt-BR';
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import {
  createAvailabilityFromTheRange,
  getMaxDayToReservationsAvailability,
} from '../service/parking-spot.service';
import { useSnackbarContext } from '../context/snackbar.context';

import moment from 'moment';
import { useMenuContext } from '../context/menu.context';

const useStyles = makeStyles({
  title: {
    marginLeft: '12px',
  },
  until: {
    color: 'grey',
    fontSize: 18,
    marginLeft: '12px',
  },
  dataTimepicker: {
    minWidth: '90px',
    margin: '10px',
  },
  timeTimepicker: {
    width: '50px',
    margin: '10px',
  },
  buttonConfirm: {
    color: '#6abeff',
  },
  buttonCancel: {
    color: 'red',
  },
});

interface MakeAvailabilityNowComponentProps {
  parkingSpotId: number;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  setLoading: (value: boolean) => void;
}

export const MakeAvailabilityNowComponent: React.FC<MakeAvailabilityNowComponentProps> = ({
  parkingSpotId,
  open,
  onClose,
  onConfirm,
  setLoading,
}: MakeAvailabilityNowComponentProps) => {
  const classes = useStyles();
  const { token } = useAuthContext();
  const { condominium } = useMenuContext();
  const { displaySnack } = useSnackbarContext();
  const currentDate = moment().format('yyyy-MM-DD');
  const currentTime = moment().format('HH:mm');
  const [finalDate, setFinalDate] = useState<Date | null>(null);
  const [finalTime, setFinalTime] = useState<Date | null>(null);
  const [
    maxDaysToReservationAvaliability,
    setMaxDaysToReservationAvaliability,
  ] = useState<number>(0);
  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const maxDaysResponse = await getMaxDayToReservationsAvailability(
          token,
        );
        maxDaysResponse.map((maxDay) =>
          setMaxDaysToReservationAvaliability(maxDay.numberValue),
        );
      } catch (errorMessage) {
        displaySnack(errorMessage, 'error');
      }
    })();
  }, [displaySnack, token]);

  const onFinalDateChange = (finalDate: Date): void => {
    const maxDate = moment(currentDate)
      .add(maxDaysToReservationAvaliability, 'days')
      .format('yyyy-MM-DD');
    const selectedDate = moment(finalDate).format('yyyy-MM-DD');
    if (selectedDate > maxDate) {
      displaySnack(
        `Você pode disponibilizar sua vaga até ${Number(
          maxDaysToReservationAvaliability,
        ).toLocaleString('pt-BR', {
          style: 'decimal',
          maximumFractionDigits: 0,
        })} dias a frente!`,
        'error',
      );
    } else {
      setFinalDate(finalDate);
      setFinalTime(null);
    }
  };

  const onAccept = async (): Promise<void> => {
    setLoading(true);
    if (finalDate && finalTime) {
      const selectedFinalDate = moment(finalDate).format('yyyy-MM-DD');
      const selectedFinalTime = moment(finalTime).format('HH:mm');
      try {
        const priceMessage = await createAvailabilityFromTheRange(
          parkingSpotId,
          currentDate,
          currentTime,
          selectedFinalDate,
          selectedFinalTime,
          token,
          condominium.id,
        );
        displaySnack(priceMessage, 'success');
      } catch (errorMessage) {
        displaySnack(errorMessage, 'error');
      } finally {
        onConfirm();
        setLoading(false);
      }
    } else {
      displaySnack('Erro ao disponibiliza os horários', 'error');
      onConfirm();
      setLoading(false);
    }
  };
  const onFinalTimeChange = (finalTime: Date): void => {
    if (
      finalDate.getDate() === new Date().getDate() &&
      finalTime.getTime() <= new Date().getTime()
    ) {
      displaySnack(
        'A hora inicial não pode ser menor ou igual a hora atual',
        'error',
      );
    } else {
      setFinalTime(finalTime);
    }
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <Dialog open={open} onClose={(): void => onClose()}>
          <DialogTitle className={classes.title}>
            {'Disponibilizar agora'}
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              item
              xs={12}
              direction="column"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item xs={6}>
                <Typography className={classes.until}>Até:</Typography>
                <DatePicker
                  label="Data"
                  className={classes.dataTimepicker}
                  format="dd/MM/yyyy"
                  lang="pt-br"
                  minDate={new Date()}
                  value={finalDate}
                  initialFocusedDate={new Date()}
                  onChange={(value): void => onFinalDateChange(value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  disabled={!finalDate ? true : false}
                  label="Hora"
                  ampm={false}
                  className={classes.timeTimepicker}
                  format="HH:mm"
                  lang="pt-br"
                  value={finalTime}
                  onChange={(value): void => onFinalTimeChange(value)}
                  minutesStep={15}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(): void => onClose()}
              className={classes.buttonCancel}
            >
              Cancelar
            </Button>
            <Button
              onClick={(): Promise<void> => onAccept()}
              className={classes.buttonConfirm}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </MuiPickersUtilsProvider>
    </>
  );
};
