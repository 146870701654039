import api from './api';
import { UserInterface } from '../interface/user.interface';
import { UserAccountInterface } from '../interface/user-account.interface';
import { BankAccountBodyInterface } from '../interface/bank-account-register.interface';
import { PicpayAccountBodyInterface } from '../interface/picpay-account-register.interface';
import { PagedUsersResponseInterface } from '../interface/paged-users-response.interface';
import { LoginInterface } from '../interface/login.interface';

const prefix = '/users';

export const getUserApartmentRequests = async (
  userId: number,
  token: string,
): Promise<boolean> => {
  try {
    const { data } = await api.get(
      `${prefix}/${userId}/has-user-request-apartment`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return data;
  } catch (error) {
    if (error.response) throw error?.response?.data?.message;
    throw 'Verifique sua conexão com a internet!';
  }
};
export const getUserApartmentRequestsList = async (
  userId: number,
  token: string,
): Promise<[]> => {
  try {
    const { data } = await api.get(
      `${prefix}/${userId}/user-request-apartments`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return data;
  } catch (error) {
    if (error.response) throw error?.response?.data?.message;
    throw 'Verifique sua conexão com a internet!';
  }
};
export async function updateMyData(
  userId: number,
  token: string,
): Promise<LoginInterface> {
  try {
    const { data } = await api.get<LoginInterface>(`${prefix}/${userId}/refresh`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    return data;
  } catch ({ response }) {
    if (response?.data?.message) throw response.data.message;
    throw 'Não foi possível recarregar os dados';
  }
}
export async function updateUser(
  token: string,
  name: string,
  telephone: string,
): Promise<UserInterface> {
  try {
    const { data } = await api.put<UserInterface>(
      `${prefix}`,
      { name, telephone },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch ({ response }) {
    if (response?.data?.message) throw response.data.message;
    throw 'Não foi possível atualizar';
  }
}

export async function getUserAccounts(
  userId: number,
  token: string,
): Promise<UserAccountInterface[]> {
  try {
    const { data } = await api.get<UserAccountInterface[]>(
      `${prefix}/${userId}/accounts`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch ({ response }) {
    if (response.message) throw response.message;
    throw 'Erro inesperado, tente novamente';
  }
}

export async function updateBankAccount(
  userId: number,
  token: string,
  body: BankAccountBodyInterface,
  accountId: number,
): Promise<UserAccountInterface> {
  try {
    const { data } = await api.put<UserAccountInterface>(
      `${prefix}/${userId}/banking-accounts/${accountId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch ({ response }) {
    if (response.message) throw response.message;
    throw 'Não foi possível atualizar a conta';
  }
}

export async function updatePicpayAccount(
  userId: number,
  token: string,
  body: PicpayAccountBodyInterface,
  accountId: number,
): Promise<UserAccountInterface> {
  try {
    const { data } = await api.put<UserAccountInterface>(
      `${prefix}/${userId}/picpay-accounts/${accountId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch ({ response }) {
    if (response.message) throw response.message;
    throw 'Não foi possível atualizar a conta PicPay';
  }
}

export async function registerBankAccount(
  userId: number,
  token: string,
  body: BankAccountBodyInterface,
): Promise<UserAccountInterface> {
  try {
    const { data } = await api.post<UserAccountInterface>(
      `${prefix}/${userId}/banking-accounts`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch ({ response }) {
    if (response.message) throw response.message;
    throw 'Não foi possível criar uma conta';
  }
}

export async function registerPicpayAccount(
  userId: number,
  token: string,
  body: PicpayAccountBodyInterface,
): Promise<UserAccountInterface> {
  try {
    const { data } = await api.post<UserAccountInterface>(
      `${prefix}/${userId}/picpay-accounts`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch ({ response }) {
    if (response.message) throw response.message;
    throw 'Não foi possível criar uma conta PicPay';
  }
}

export async function getUsersPaged(
  token: string,
  page: number,
  filterOption?: number,
  filter?: string,
  condominiumIdFilter?: number,
): Promise<PagedUsersResponseInterface> {
  try {
    const limit = 20;
    let queryString = '';

    queryString += `?limit=${limit}`;

    if (page > 1) {
      queryString += `&offset=${limit * (page - 1)}`;
    }

    if ((filterOption === 0 && filter) || (filterOption === 1 && filter)) {
      if (filterOption === 0) {
        queryString += `&email=${filter}`;
      } else queryString += `&name=${filter}`;
    }

    if (condominiumIdFilter) {
      queryString += `&condominiumId=${condominiumIdFilter}`;
    }

    const { data } = await api.get<PagedUsersResponseInterface>(
      `${prefix}${queryString}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch ({ response }) {
    if (response.message) throw response.message;
    throw 'Erro inesperado, tente novamente';
  }
}
