import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Button } from '@material-ui/core';
import { ContainerComponent } from '../component/container.component';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { useMenuContext } from '../context/menu.context';
import { AddCreditModalComponent } from '../component/add-credit-modal.component';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      marginTop: '1em',
    },
  }),
);

export function BuyCreditsPage(): JSX.Element {
  const classes = useStyles();
  const { setMenuPageById } = useMenuContext();
  const [openAddCreditModal, setOpenAddCreditModal] = useState(false);

  useEffect(() => { setMenuPageById(MenuPageIdEnum.BUY_CREDITS); },[]);

  return (
    <ContainerComponent>
      <>
        <AddCreditModalComponent
          open={openAddCreditModal}
          onConfirm={(): void => setOpenAddCreditModal(false)}
          onClose={(): void => setOpenAddCreditModal(false)}
        />
        <Button
          className={classes.button}
          onClick={async (): Promise<void> => await setOpenAddCreditModal(true)}
        >
          Chamar Modal
        </Button>
      </>
    </ContainerComponent>
  );
}
