import * as React from 'react';

function GarageIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 400 400" width="1em" height="1em" {...props}>
      <path d="M58 292.516h284v42H58zM58 234.516h284v42H58zM58 350.516h284v45H58z" />
      <path d="M200 4.484L0 115.685v279.831h42V168.797c0-4.418 3.248-8.282 7.666-8.282h300.668c4.418 0 7.666 3.864 7.666 8.282v226.718h42v-279.83L200 4.484z" />
      <path d="M58 176.516h284v42H58z" />
    </svg>
  );
}

export default GarageIcon;
