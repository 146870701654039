import React, { useEffect } from 'react';
import { AuthProvider, useAuthContext } from './context/auth.context';
import MenuAppBarComponent from './component/menu-app-bar.component';
import { MenuProvider, useMenuContext } from './context/menu.context';
import { RouterSwitchComponent } from './component/router-switch.component';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { SnackProvider } from './context/snackbar.context';
import { Button, makeStyles, useMediaQuery } from '@material-ui/core';
import AskNotificationPermissionComponent from './component/ask-notification-permission.component';
import { changeSubscriptionStatus } from './service/notification.service';
import { ActivationEnum } from './enum/activation.enum';

import { useClearCache } from 'react-clear-cache';

interface CallbacksInterface {
  success: (transactionCode: string) => void;
  abort: () => void;
}

declare global {
  interface Window {
    PagSeguroLightbox(code: string, object: CallbacksInterface): string;
  }
}

const useStyles = makeStyles({
  root: {
    paddingTop: 50,
  },
});

function CustomMenuComponent(): JSX.Element {
  const classes = useStyles();
  const { authenticatedUser } = useAuthContext();
  const { menuPage } = useMenuContext();
  return (
    <div className={menuPage?.fixHeader ? classes.root : null}>
      {authenticatedUser && <MenuAppBarComponent></MenuAppBarComponent>}
    </div>
  );
}

const isPushNotificationSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window

function CustomAskNotificationComponent(): JSX.Element {
  const {
    token,
    authenticatedUser,
    userNotificationSubscription,
    setAskForNotificationPermission,
  } = useAuthContext();
  const { userId, subscriptionId } = userNotificationSubscription;

  if (
    userId === authenticatedUser?.id &&
    userNotificationSubscription.askForPermission
  ) {
    changeSubscriptionStatus(token, subscriptionId, ActivationEnum.ACTIVE).then(
      () => {
        setAskForNotificationPermission(false);
        return;
      },
    );
  }

  if(isPushNotificationSupported()) {

    return authenticatedUser &&
      Notification.permission === 'default' &&
      userNotificationSubscription.askForPermission ? (
      <AskNotificationPermissionComponent />
    ) : null;
  } else {
    return null;
  }

}

export default function App(): JSX.Element {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const notistackRef = React.createRef<{
    closeSnackbar: (key: SnackbarKey) => void;
  }>();
  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }

    const script = document.createElement('script');
    script.src =
      process.env.REACT_APP_NODE_ENV !== 'production'
        ? 'https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js'
        : 'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js';
    script.async = true;
    document.body.appendChild(script);
    return (): void => {
      document.body.removeChild(script);
    };
  }, []);

  const onClickDismissSnack = (key: SnackbarKey): void => {
    notistackRef.current.closeSnackbar(key);
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <SnackbarProvider
      maxSnack={4}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: isMobile ? 'center' : 'left',
      }}
      ref={notistackRef}
      action={(key: SnackbarKey): JSX.Element => (
        <Button
          onClick={(): void => onClickDismissSnack(key)}
          style={{ color: 'white' }}
        >
          Ok
        </Button>
      )}
    >
      <div className="App">
        <Router>
          <AuthProvider>
            <SnackProvider>
              <MenuProvider>
                <div>
                  <CustomAskNotificationComponent />
                  <CustomMenuComponent></CustomMenuComponent>
                  <RouterSwitchComponent></RouterSwitchComponent>
                </div>
              </MenuProvider>
            </SnackProvider>
          </AuthProvider>
        </Router>
      </div>
    </SnackbarProvider>
  );
}
