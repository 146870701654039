import { useState, useEffect } from 'react';
import { setLocalStorage } from './util.common';
import { useLocation } from 'react-router-dom';
import { WindowSizeInterface } from '../interface/window-size.interface';

export function useLocalStorageState<T>(
  initialValue: T,
  storageKey: string,
  parser: (string: string) => T,
): [T, (value: T) => void] {
  const persistedValue = localStorage.getItem(storageKey);
  const persistedValueParsed =
    persistedValue === null ? null : parser(persistedValue);

  if (!persistedValue) setLocalStorage<T>(storageKey, initialValue);

  const [value, setValue] = useState<T>(persistedValueParsed || initialValue);
  const setStorageValue = (value: T): void => {
    setLocalStorage<T>(storageKey, value);
    setValue(value);
  };

  return [value, setStorageValue];
}

export function useQueryString(attributeName: string): string | null {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  return urlSearchParams.get(attributeName);
}

export function useWindowSize(): WindowSizeInterface {
  const [windowSize, setWindowSize] = useState<WindowSizeInterface>({
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
  });

  useEffect(() => {
    function handleResize(): void {
      setWindowSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export function useUrlQuery(queryName: string): string {
  return new URLSearchParams(window.location.search).get(queryName);
}
