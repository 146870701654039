import React from 'react';
import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { Redirect } from 'react-router-dom';
import { UserType } from '../interface/user.interface';

export function DefaultRedirectPage(): JSX.Element {
  const { authenticatedUser } = useAuthContext();
  const { getMenuPageById } = useMenuContext();
  const defaultMenuPageId: MenuPageIdEnum = authenticatedUser
    ? authenticatedUser?.type === UserType.RESIDENT
      ? MenuPageIdEnum.HOME
      : MenuPageIdEnum.ADMIN
    : MenuPageIdEnum.AUTH;

  const defaultMenuPage = getMenuPageById(defaultMenuPageId);

  return (
    <div>
      {defaultMenuPage && (
        <Redirect to={defaultMenuPage.menuRedirectTo || defaultMenuPage.path} />
      )}
    </div>
  );
}
