import React, { useState } from 'react';
import {
  makeStyles,
  Card,
  CardContent,
  Button,
  Dialog,
} from '@material-ui/core';
import PaginationComponent from './pagination.component';
import ReceivingMethodFormComponent from './receiving-method-form.component';
import { PaymentMethodEnum } from '../enum/paymeny-method.enum';
import { BankAccountBodyInterface } from '../interface/bank-account-register.interface';
import { PicpayAccountBodyInterface } from '../interface/picpay-account-register.interface';
import { useAuthContext } from '../context/auth.context';
import { useSnackbarContext } from '../context/snackbar.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import {
  updateBankAccount,
  updatePicpayAccount,
} from '../service/user.service';
import { useMenuContext } from '../context/menu.context';
import { UserAccountInterface } from '../interface/user-account.interface';
import { useEffect } from 'react';
import { registerWithdrawalsRequest } from '../service/wallet.service';
import { FormData } from '../common/form.type';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  editButton: {
    width: '100%',
    color: '#5abefc',
    marginTop: 20,
  },
  selectButton: {
    width: '100%',
    backgroundColor: '#5abefc',
    color: 'white',
  },
});

interface ReceivingMethodModalProps {
  open: boolean;
  userAccounts: UserAccountInterface[];
  onClose: () => void;
  value?: number;
  userBalance?: number;
  walletId?: number;
}

const ReceivingMethodsModalComponent: React.FC<ReceivingMethodModalProps> = ({
  open,
  onClose,
  userAccounts,
  value,
  userBalance,
  walletId,
}: ReceivingMethodModalProps) => {
  const classes = useStyles();
  const { setHeaderLoading, isHeaderLoading } = useMenuContext();
  const { authenticatedUser, token } = useAuthContext();
  const { displaySnack } = useSnackbarContext();
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [accountIndex, setAccountIndex] = useState<number>(0);
  const { goToPageRouteById } = useMenuContext();
  const [currentAccount, setCurrentAccount] = useState<UserAccountInterface>(
    userAccounts[accountIndex],
  );
  const typeCondition = !!currentAccount?.userAccountPicpay
    ? PaymentMethodEnum.PICPAY
    : PaymentMethodEnum.BANK_TRANSFER;
  const [formType, setFormType] = useState<PaymentMethodEnum>(typeCondition);

  useEffect(() => setCurrentAccount(userAccounts[accountIndex]), [
    accountIndex,
  ]);

  useEffect(() => setFormType(typeCondition), [currentAccount]);

  const createWithdrawRequest = async (): Promise<void> => {
    try {
      if (!value || value <= 0) {
        displaySnack('Valor de saque inválido!', 'error');
        return;
      }
      if (value > userBalance) {
        displaySnack('Você não possui saldo para sacar esta quantia!', 'error');
        return;
      }
      setHeaderLoading(true);
      await registerWithdrawalsRequest(
        walletId,
        token,
        authenticatedUser.id,
        currentAccount.id,
        value,
      );
      displaySnack('Requisição de saque concluída com sucesso!', 'success');
      goToPageRouteById(MenuPageIdEnum.TRANSACTIONS_STATEMENT);
    } catch (errorMessage) {
      displaySnack(errorMessage, 'error', true);
    } finally {
      onClose();
      setHeaderLoading(false);
    }
  };

  const handleUpdateBankAccount = async (
    bankAccountData: BankAccountBodyInterface,
  ): Promise<void> => {
    try {
      setHeaderLoading(true);
      await updateBankAccount(
        authenticatedUser.id,
        token,
        bankAccountData,
        userAccounts[accountIndex].userAccountBankingAccount.id,
      );
      displaySnack('Conta atualizada com sucesso!', 'success', true);
      onClose();
    } catch (errorMessage) {
      displaySnack(errorMessage, 'error', true);
    } finally {
      setHeaderLoading(false);
    }
  };

  const handleUpdatePicpayAccount = async (
    picpayAccountData: PicpayAccountBodyInterface,
  ): Promise<void> => {
    try {
      setHeaderLoading(true);
      await updatePicpayAccount(
        authenticatedUser.id,
        token,
        picpayAccountData,
        userAccounts[accountIndex].userAccountPicpay.id,
      );
      onClose();
      displaySnack('Conta atualizada com sucesso!', 'success', true);
      setHeaderLoading(false);
    } catch (errorMessage) {
      displaySnack(errorMessage, 'error', true);
      setHeaderLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="Métodos de pagamentos já utilizados"
      aria-describedby="Modal para selecionar o método de você deseja receber o seu dinheiro"
      className={classes.root}
    >
      <Card>
        <CardContent>
          <>
            <PaginationComponent
              currentPage={currentPage}
              totalPages={userAccounts.length}
              nextPageFuntion={(): void => {
                if (currentPage === userAccounts.length) return;
                setAccountIndex(accountIndex + 1);
                setCurrentPage(currentPage + 1);
              }}
              previousPageFuntion={(): void => {
                if (currentPage === 1) return;
                setAccountIndex(accountIndex - 1);
                setCurrentPage(currentPage - 1);
              }}
            />

            <ReceivingMethodFormComponent
              disabled={isDisabled}
              onAbort={(): void => {
                onClose();
                setDisabled(true);
              }}
              onConfirmSubmit={(data: FormData): void => {
                if (isHeaderLoading) return;
                if ('username' in data)
                  handleUpdatePicpayAccount(data as PicpayAccountBodyInterface);
                else handleUpdateBankAccount(data as BankAccountBodyInterface);
              }}
              type={formType}
              data={{
                username: currentAccount?.userAccountPicpay?.username,
                agency: currentAccount?.userAccountBankingAccount?.agency,
                account: currentAccount?.userAccountBankingAccount?.account,
                bankCode: currentAccount?.userAccountBankingAccount?.bankCode,
                bankName: currentAccount?.userAccountBankingAccount?.bankName,
                owner: currentAccount?.userAccountBankingAccount?.owner,
                cpf: currentAccount?.userAccountBankingAccount?.cpf,
              }}
            />

            {!isDisabled || (
              <Button
                className={classes.selectButton}
                variant="contained"
                onClick={createWithdrawRequest}
              >
                Selecionar
              </Button>
            )}
            <Button
              className={classes.editButton}
              variant="text"
              onClick={(): void => setDisabled(!isDisabled)}
            >
              {isDisabled ? 'Editar conta' : 'Parar de editar'}
            </Button>
          </>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default ReceivingMethodsModalComponent;
