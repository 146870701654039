import api from './api';
import { BlockInterface } from '../interface/block.interface';
import { CondominiumInterface } from '../interface/condominium.interface';
import { ApartmentInterface } from '../interface/apartment.interface';
import { GateInterface } from '../interface/gate.interface';
import { ParkingSpotInterface } from '../interface/parking-spot.interface';
import { AvailableFor } from '../enum/available-for.enum';
import { sortByName } from '../common/util.common';

const prefix = 'condominiums';

export const getCondominiums = async (
  token: string,
  onlyAvailableToLink?: boolean,
  availableFor?: AvailableFor,
): Promise<CondominiumInterface[]> => {
  try {
    let queryString = '';
    if (!!onlyAvailableToLink)
      queryString = `?onlyAvailableToLink=${onlyAvailableToLink}&availableFor=${availableFor}`;

    const { data } = await api.get<CondominiumInterface[]>(
      `/${prefix}${queryString}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    const retData = data;
    if(retData.length > 0){
      return retData.sort(sortByName);
    }else{
      return retData;
    }
  } catch (error) {
    if (error.response) throw error.response.data.message;
    throw 'Não foi possível buscar os condomínios';
  }
};

export const getApartmentBlocks = async (
  condominiumId: number,
  token: string,
): Promise<BlockInterface[]> => {
  if (condominiumId !== 0) {
    try {
      const { data } = await api.get<BlockInterface[]>(
        `/${prefix}/${condominiumId}/blocks`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return data;
    } catch (error) {
      if (error.response) throw error.response.data.message;
      throw 'Não foi possível buscar os blocos dos condomínios';
    }
  }
};

export const getParkingSpotGates = async (
  condominiumId: number,
  token: string,
  onlyAvailableToLink?: boolean,
): Promise<GateInterface[]> => {
  if (condominiumId !== 0) {
    try {
      let queryString = '';
      if (!!onlyAvailableToLink)
        queryString = `?onlyAvailableToLink=${onlyAvailableToLink}`;

      const { data } = await api.get<GateInterface[]>(
        `/${prefix}/${condominiumId}/gates${queryString}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return data;
    } catch (error) {
      if (error.response) throw error.response.data.message;
      throw 'Não foi possível buscar os portões';
    }
  }
};

export const getApartments = async (
  condominiumId: number,
  block: string,
  token: string,
): Promise<ApartmentInterface[]> => {
  try {
    const { data } = await api.get<ApartmentInterface[]>(
      `/${prefix}/${condominiumId}/apartments?block=${block}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return data;
  } catch (error) {
    if (error.response) throw error.response.data.message;
    throw 'Não foi possível buscar a lista de apartamentos';
  }
};

export const getParkingSpots = async (
  condominiumId: number,
  gate: string,
  token: string,
): Promise<ParkingSpotInterface[]> => {
  try {
    const { data } = await api.get<ParkingSpotInterface[]>(
      `/${prefix}/${condominiumId}/parking-spots?gate=${gate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return data;
  } catch (error) {
    if (error.response) throw error.response.data.message;
    throw 'Não foi possível buscar as vagas';
  }
};
