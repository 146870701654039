import React, { useState, useEffect } from 'react';
import {
  Typography,
  makeStyles,
  createStyles,
  Grid,
  Paper,
  Button,
  InputLabel,
  Select,
  MenuItem,
  withStyles,
  FormControl,
} from '@material-ui/core';
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { useSnackbarContext } from '../context/snackbar.context';

import { createPreReservation } from '../service/reservation.service';
import { getParkingSpotGates } from '../service/condominium.service';
import { GateInterface } from '../interface/gate.interface';
import { PreReservationInterface } from '../interface/pre-reservation.interface';

import ptBR from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useUrlQuery } from '../common/common.hook';
import { sortByGate } from '../common/util.common';

interface BookingAvailabilityModalComponentProps {
  setParkingSpot: (value: PreReservationInterface) => void;
  isReserveNow?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    paper: { padding: '5%', marginTop: '3%' },
    title: {
      color: '#262626',
      fontWeight: 'bold',
      fontSize: 20,
    },
    gateLabel: {
      color: '#b3b3b3',
    },
    select: {
      width: '100%',
    },
    timePickerInput: {
      width: '100%',
    },
  }),
);

const ConfirmButton = withStyles({
  root: {
    backgroundColor: '#3FB7F8',
    color: 'white',
    width: '100%',
    height: '100%',
    '&:hover': {
      backgroundColor: '#0069d9',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

const BookingAvailabilityModalComponent: React.FC<BookingAvailabilityModalComponentProps> = ({
  setParkingSpot,
  isReserveNow,
}: BookingAvailabilityModalComponentProps) => {
  const queryTime = useUrlQuery('time');
  const queryDate = useUrlQuery('date');

  const { token } = useAuthContext(),
    { condominium, isHeaderLoading, setHeaderLoading } = useMenuContext(),
    classes = useStyles(),
    { displaySnack } = useSnackbarContext(),
    [currentDay, setCurrentDay] = useState<string>(),
    [initialDate, setInitialDate] = useState<Date | null>(
      !!queryDate ? new Date(queryDate.replace(/-/g,"/")) : null,
    ),
    [finalDate, setFinalDate] = useState<Date | null>(null),
    [initialTime, setInitialTime] = useState<Date | null>(
      !!queryTime ? new Date(`2020-06-03T${queryTime}:00`) : null,
    ),
    [finalTime, setFinalTime] = useState<Date | null>(null),
    [gates, setGates] = useState<GateInterface[]>(),
    [selectedGate, setSelectedGate] = useState<string>('');

  useEffect(() => {
    (async (): Promise<void> => {
      const currentDay = moment(new Date()).format('yyyy-MM-DD');
      setCurrentDay(currentDay);
      try {
        const gates = await getParkingSpotGates(condominium.id, token, false);
        setGates(gates);
      } catch (errorMessage) {
        displaySnack(errorMessage, 'error');
      }
    })();
  }, [displaySnack, token, condominium]);
  const onInitialDateChange = (initialDate: Date): void => {
    if (moment(initialDate).format('yyyy-MM-DD') < currentDay) {
      displaySnack(
        'A data inicial não pode ser menor que a data atual',
        'error',
      );
    } else {
      setInitialDate(initialDate);
      setFinalDate(null);
      setInitialTime(null);
      setFinalTime(null);
    }
  };
  const onFinalDateChange = (finalDate: Date): void => {
    if (
      moment(initialDate).format('yyyy-MM-DD') >
      moment(finalDate).format('yyyy-MM-DD')
    ) {
      displaySnack(
        'A data final não pode ser menor que a data inicial',
        'error',
      );
    } else {
      setFinalDate(finalDate);
      setFinalTime(null);
    }
  };

  const onInitialTimeChange = (initialTime: Date): void => {
    if (
      moment(initialTime).format('HH:mm') <
        moment(new Date()).format('HH:mm') &&
      moment(initialDate).format('yyyy-MM-DD') === currentDay
    ) {
      displaySnack(
        'A hora inicial não pode ser menor que a hora atual',
        'error',
      );
    } else {
      setInitialTime(initialTime);
      setFinalTime(null);
    }
  };

  const onFinalTimeChange = (finalTime: Date): void => {
    let selectedFinalTime = moment(finalTime).format('HH:mm');
    selectedFinalTime =
      selectedFinalTime[0] + selectedFinalTime[1] === '00'
        ? '24:00'
        : selectedFinalTime;
    if (
      moment(initialDate).format('yyyy-MM-DD') ===
        moment(finalDate).format('yyyy-MM-DD') &&
      moment(initialTime).format('HH:mm') >= selectedFinalTime
    ) {
      displaySnack(
        'A hora final não pode ser menor nem igual a hora inicial',
        'error',
      );
    } else {
      setFinalTime(finalTime);
    }
  };

  const onConfirm = async (): Promise<void> => {
    setHeaderLoading(true);
    if (
      initialDate === null ||
      finalDate === null ||
      initialTime === null ||
      finalTime === null ||
      selectedGate === ''
    ) {
      displaySnack('Todos os dados são necessários!', 'error');
    } else {
      try {
        setParkingSpot(null);

        const availableSpot = await createPreReservation(
          condominium?.id,
          selectedGate,
          moment(initialDate).format('yyyy-MM-DD'),
          moment(initialTime).format('HH:mm'),
          moment(finalDate).format('yyyy-MM-DD'),
          moment(finalTime).format('HH:mm'),
          token,
          isReserveNow,
        );
        setParkingSpot(availableSpot);
      } catch (message) {
        displaySnack(message, 'error', true);
      }
    }
    setHeaderLoading(false);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <Paper className={classes.paper} elevation={3}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography className={classes.title} align="left">
              Disponibilidade para Reserva
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <DatePicker
              className={classes.timePickerInput}
              format="dd/MM/yyyy"
              value={initialDate}
              lang="pt-br"
              label="Dia de início"
              minDate={new Date()}
              onChange={(value): void => onInitialDateChange(value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DatePicker
              className={classes.timePickerInput}
              format="dd/MM/yyyy"
              minDate={new Date()}
              initialFocusedDate={new Date()}
              label="Dia de término"
              lang="pt-br"
              DialogProps={{
                lang: 'pt',
              }}
              InputLabelProps={{
                lang: 'pt',
              }}
              InputProps={{
                lang: 'pt-br',
              }}
              disabled={!initialDate ? true : false}
              value={finalDate}
              onChange={(value): void => onFinalDateChange(value)}
            />
          </Grid>

          <Grid item container direction="row" spacing={1}>
            <Grid item xs={6}>
              <TimePicker
                className={classes.timePickerInput}
                value={initialTime}
                disabled={!initialDate ? true : false}
                format="HH:mm"
                label="Hora inicial"
                lang="pt-BR"
                initialFocusedDate={new Date('03/16/2020 00:00:00')}
                InputProps={{
                  lang: 'pt-br',
                }}
                ampm={false}
                onChange={(value): void => onInitialTimeChange(value)}
                minutesStep={15}
              />
            </Grid>
            <Grid item xs={6}>
              <TimePicker
                className={classes.timePickerInput}
                value={finalTime}
                format="HH:mm"
                label="Hora final"
                ampm={false}
                InputProps={{
                  lang: 'pt-br',
                }}
                lang="pt-br"
                initialFocusedDate={new Date('03/16/2020 00:00:00')}
                disabled={!initialTime || !finalDate ? true : false}
                onChange={(value): void => onFinalTimeChange(value)}
                minutesStep={15}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                variant="standard"
                required
                className={classes.select}
              >
                <InputLabel
                  className={classes.gateLabel}
                  id="gate-select-label"
                >
                  Acesso
                </InputLabel>
                <Select
                  value={selectedGate}
                  onChange={({
                    target,
                  }: React.ChangeEvent<{ value: unknown }>): void =>
                    setSelectedGate(target.value as string)
                  }
                  id="gate-select"
                >
                  {gates?.sort(sortByGate).map((gate, index) => (
                    <MenuItem key={index} value={gate.gate}>
                      {gate.gate}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <ConfirmButton
                onClick={(): Promise<void> => onConfirm()}
                disabled={isHeaderLoading}
              >
                Consultar
              </ConfirmButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </MuiPickersUtilsProvider>
  );
};
export default BookingAvailabilityModalComponent;
