import React, { useLayoutEffect, useState } from 'react';
import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { useSnackbarContext } from '../context/snackbar.context';
import { UserType } from '../interface/user.interface';
import { WalletBalanceResponseInterface } from '../interface/wallet-balance-response.interface';
import { consultWalletBalance } from '../service/wallet.service';
import BalanceDashboardComponent from './balance-dashboard.component';
import TransactionHistoryComponent from './transaction-history.component';

interface TransactionStatementProps {
  walletId: number;
  userId?: number;
}

const TransactionStatementComponent: React.FC<TransactionStatementProps> = ({
  walletId,
  userId,
}: TransactionStatementProps) => {
  const { setHeaderLoading } = useMenuContext();
  const { displaySnack } = useSnackbarContext();
  const { authenticatedUser, token } = useAuthContext();
  const { wallet } = authenticatedUser;
  const [
    { balance, bonusBalance, totalAmount, walletTransactionsPaged },
    setWallet,
  ] = useState<Partial<WalletBalanceResponseInterface>>({
    balance: wallet?.balance || 0,
    bonusBalance: wallet?.bonusBalance || 0,
    totalAmount: wallet?.totalAmount || 0,
    walletTransactionsPaged: {
      count: 0,
      limit: 0,
      offset: 0,
      rows: [],
    },
  });
  const isAdmin: boolean = authenticatedUser.type === UserType.ADMIN;

  useLayoutEffect(() => {
    setHeaderLoading(true);
    (async (): Promise<void> => {
      try {
        if (walletId) {
          const walletResponse = await consultWalletBalance(
            walletId,
            token,
            true,
          );
          setWallet(walletResponse);
        }
      } catch (errorMessage) {
        displaySnack(errorMessage, 'error', true);
      } finally {
        setHeaderLoading(false);
      }
    })();
  }, [walletId]);

  return (
    <div>
      <BalanceDashboardComponent
        balance={balance}
        bonusBalance={bonusBalance}
        totalAmount={totalAmount}
        isAdmin={isAdmin}
        walletId={walletId}
        userId={userId}
      />
      <TransactionHistoryComponent
        walletId={walletId}
        initialTransactionList={walletTransactionsPaged}
      />
    </div>
  );
};

export default TransactionStatementComponent;
