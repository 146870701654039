import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CssBaseline } from '@material-ui/core';

declare global {
  namespace NodeJS {
    interface Global {
      config: any;
    } 
  }
}

require(`./config/env/${process.env.REACT_APP_NODE_ENV}`)

console.log(global.config)

ReactDOM.render(
  <React.Fragment>
    <CssBaseline></CssBaseline>
    <App />
  </React.Fragment>,
  document.getElementById('root'),
);

// serviceWorker.register();

serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        // @ts-ignore: Unreachable code error
        if (event.target.state === "activated") {
          window.location.reload()
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  }
});
