import { WalletBalanceResponseInterface } from '../interface/wallet-balance-response.interface';
import { WalletTransactionsPagedResponseInterface } from '../interface/wallet-transactions-paged-response.interface';
import api from './api';

const prefix = '/wallets';
interface CheckoutPagSeguroInterface {
  code: string;
}

interface AddBonusModalInterface {
  value: number;
  reason: string;
}
export async function consultWalletBalance(
  walletId: number,
  token: string,
  includeWalletTransactions?: boolean,
): Promise<WalletBalanceResponseInterface> {
  try {
    let queryTransactions = '';
    if (includeWalletTransactions)
      queryTransactions = `?includeWalletTransactions=${includeWalletTransactions}`;
    const { data } = await api.get<WalletBalanceResponseInterface>(
      `${prefix}/${walletId}${queryTransactions}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch ({ response }) {
    if (response?.message) throw response?.message;
    throw 'Não foi possível buscar os dados da carteira';
  }
}
export const createCheckoutCode = async (
  value: number,
  token: string,
): Promise<CheckoutPagSeguroInterface> => {
  try {
    const { data } = await api.post<CheckoutPagSeguroInterface>(
      `${prefix}/checkout-code`,
      {
        value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return data;
  } catch (error) {
    if (error.response) throw error?.response?.data?.message;
    throw 'Não foi possível buscar as vagas';
  }
};
export async function getTransactionsByWalletId(
  token: string,
  walletId: number,
  limit = 20,
  offset?: number,
): Promise<WalletTransactionsPagedResponseInterface> {
  try {
    let queryOffset = '';
    if (offset) queryOffset = `&offset=${offset}`;
    const { data } = await api.get<WalletTransactionsPagedResponseInterface>(
      `${prefix}/${walletId}/transactions?limit=${limit}${queryOffset}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch ({ response }) {
    if (response?.message) throw response?.message;
    throw 'Não foi possível buscar os dados da carteira';
  }
}

export async function registerWithdrawalsRequest(
  walletId: number,
  token: string,
  userId: number,
  userAccountId: number,
  value: number,
): Promise<void> {
  try {
    await api.post<WalletTransactionsPagedResponseInterface>(
      `${prefix}/${walletId}/withdrawals`,
      {
        userId,
        userAccountId,
        value,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
  } catch ({ response }) {
    if (response.message) throw response.message;
    throw 'Erro inesperado, tente novamente';
  }
}
export const registerBonuses = async (
  walletId: number,
  userId: number,
  value: number,
  description: string,
  token: string,
): Promise<AddBonusModalInterface> => {
  try {
    const { data } = await api.post<AddBonusModalInterface>(
      `${prefix}/${walletId}/bonuses`,
      {
        userId,
        value,
        description,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return data;
  } catch (error) {
    if (error.response) throw error?.response?.data?.message;
    throw 'Não foi possível adicionar bônus';
  }
};
