import api from './api';
import { NotificationSubscriptionInterface } from '../interface/notification-subscription.interface';
import { ActivationEnum } from '../enum/activation.enum';
import { NotificationPagedResponseInterface } from '../interface/notification-paged-response.interface';

const prefix = '/notifications';
export async function getNotifications(
  token: string,
  limit: number,
  page?: number,
): Promise<NotificationPagedResponseInterface> {
  let queryString = '';
  queryString += `?limit=${limit}`;

  if (page > 1) {
    queryString += `&offset=${limit * (page - 1)}`;
  }

  try {
    const { data } = await api.get<NotificationPagedResponseInterface>(
      `${prefix}${queryString}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch ({ response }) {
    if (response?.data?.message) throw response.data.message;
    throw 'Não foi possível buscar notificações';
  }
}

export async function createSubscription(
  token: string,
  subscription: PushSubscription,
): Promise<number> {
  try {
    const { data } = await api.post<
      Pick<NotificationSubscriptionInterface, 'id'>
    >(
      `${prefix}/subscription`,
      { subscription: subscription.toJSON() },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data.id;
  } catch ({ response }) {
    if (response?.data?.message) throw response.data.message;
    throw 'Não foi possível criar permissão';
  }
}

export async function changeSubscriptionStatus(
  token: string,
  subscriptionId: number,
  status: ActivationEnum,
): Promise<number> {
  try {
    const { data } = await api.patch<
      Pick<NotificationSubscriptionInterface, 'id'>
    >(
      `${prefix}/subscription/${subscriptionId}?status=${status}`,
      {},
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );

    return data.id;
  } catch ({ response }) {
    if (response?.data?.message) throw response.data.message;
    throw 'Não foi possível validar permissão';
  }
}
