/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Typography,
  IconButton,
  Modal,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { useMenuContext } from '../context/menu.context';
import { useAuthContext } from '../context/auth.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import {
  getParkingSpotWithCurrentReservation,
  getAvailabilityByParkingSpotAndDateRange,
} from '../service/parking-spot.service';
import { RentalTimesAvailabilityResponseInterface } from '../interface/rental-times-availability-response.interface';
import { ParkingSpotCurrentReservationInterface } from '../interface/parking-spot-current-reservation.interface';
import { ParkingSpotStatusEnum } from '../enum/parking-spot-status.enum';
import { ContainerComponent } from '../component/container.component';
import TimesAndReservationsItemComponent from '../component/times-and-reservations-item.component';
import PaginationComponent from '../component/pagination.component';
import { MakeAvailabilityNowComponent } from '../component/make-availability-now.component';
import { useSnackbarContext } from '../context/snackbar.context';
import moment from 'moment';
import OpenGarageIcon from '../icons/open-garage.icon';
import EditAvailabilityComponent from '../component/edit-availability.component';
import { RentalTimesGroupInterface } from '../interface/rental-times-group.interface';
import { useUrlQuery } from '../common/common.hook';

interface MatchParams {
  parkingSpotId: string;
}

const useStyles = makeStyles({
  title: {
    fontSize: 17,
    color: '#6abeff',
    fontWeight: 'bold',
    marginTop: '5px',
  },
  upTitle: {
    fontSize: 17,
    color: '#6abeff',
    fontWeight: 'bold',
    marginTop: '20px',
  },
  iconBox: {
    display: 'flex',
    position: 'sticky',
    justifyContent: 'flex-end',
    flex: 1,
    marginRight: '5px',
  },
  iconButton: {
    borderRadius: '100%',
    width: '50px',
    height: '50px',
    backgroundColor: '#6abeff',
    boxShadow: '2px 5px 5px grey',
    alignItems: 'center',
    position: 'fixed',
    cursor: 'pointer',
    bottom: 20,
  },
  icon: {
    position: 'absolute',
    top: '15%',
    width: '30px',
    height: '30px',
  },
  parkingSpotReservationData: {
    fontWeight: 'bold',
    color: '#a6a6a6',
    maxWidth: '100px',
  },
  parkingSpotData: {
    fontWeight: 'bold',
    color: '#a6a6a6',
  },
  circularProgress: {
    position: 'fixed',
    bottom: 300,
    marginLeft: '50%',
    fontSize: 70,
  },
  statusColor: (props: { statusColor: string }) => ({
    borderRadius: '100%',
    width: '12px',
    margin: '5px',
    height: '12px',
    backgroundColor: props.statusColor,
  }),
});

const emptySpot: ParkingSpotCurrentReservationInterface = {
  id: 0,
  status: ParkingSpotStatusEnum.UNAVAILABLE,
  condominiumId: 0,
  gate: '',
  code: '',
  block: '',
  currentReservation: {
    finalDatetime: '',
    initialDatetime: '',
    lessorUserName: '',
    apartment: '',
    telephone: '',
    block: '',
  },
};

function getStatusColor(parkingSpotStatus: ParkingSpotStatusEnum): string {
  switch (parkingSpotStatus) {
    case ParkingSpotStatusEnum.AVAILABLE:
      return '#6ABEFF';
    case ParkingSpotStatusEnum.HAS_RESERVATION:
      return '#1B46FF';
    case ParkingSpotStatusEnum.UNAVAILABLE:
      return '#9E9E9E';
    default:
      return 'red';
  }
}

function getTimeSlotByCurrentTime(currentDate: Date): string {
  const minutesInterval = [45, 30, 15, 0];
  const minutes = currentDate.getMinutes();
  const finalHour = currentDate.getHours();
  let finalMinute: number | string = minutesInterval.find(
    (interval) => minutes > interval,
  );

  if (finalMinute === undefined) finalMinute = '00';

  return `${finalHour < 10 ? `0${finalHour}` : finalHour}:${
    finalMinute === 0 ? '00' : finalMinute
  }`;
}

function formatDateStringToTime(dateString: string): string {
  const date = new Date(dateString);
  const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  const minute =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  const result = hour + ':' + minute;
  return result;
}

function getAvailabilityText(
  status: ParkingSpotStatusEnum,
  finalTime?: string,
): string {
  switch (status) {
    case ParkingSpotStatusEnum.AVAILABLE:
      return 'Vaga disponibilizada';
    case ParkingSpotStatusEnum.UNAVAILABLE:
      return 'Vaga não disponibilizada';
    case ParkingSpotStatusEnum.HAS_RESERVATION:
      return `Vaga ocupada até ${formatDateStringToTime(finalTime)}`;
  }
}

// eslint-disable-next-line complexity
export function ManageAvailabilityPage(
  props: RouteComponentProps<MatchParams>,
): JSX.Element {
  const { token } = useAuthContext();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [selectedDateToEdit, setSelectedDateToEdit] = useState<
    RentalTimesGroupInterface
  >(null);
  const {
    setMenuPageById,
    goToPageRouteById,
    setHeaderLoading,
    isHeaderLoading,
  } = useMenuContext();
  const { displaySnack } = useSnackbarContext();
  const [availabilityData, setAvailabilityData] = useState<
    RentalTimesAvailabilityResponseInterface
  >({ lastAvailabilityDate: '', parkingSpotRentalTimeGrouped: [] });
  const [spotInfo, setSpotInfo] = useState<
    ParkingSpotCurrentReservationInterface
  >(emptySpot);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastDayOfTheCurrentWeek, setLastDayOfTheCurrentWeek] = useState<
    moment.Moment
  >(moment().add(6, 'days'));
  const [currentDay, setCurrentDay] = useState<moment.Moment>(moment());
  const [isComponentLoading, setComponentLoading] = useState<boolean>(false);
  const queryMakeAvailabilityNow = useUrlQuery('makeAvailabilityNow');
  const [
    openMakeAvailabilityNowDialog,
    setOpenMakeAvailabilityNowDialog,
  ] = useState(false);
  const classes = useStyles({
    statusColor: getStatusColor(spotInfo.status),
  });

  useEffect(() => {
    if (queryMakeAvailabilityNow === 'true') {
      setOpenMakeAvailabilityNowDialog(true);
    }
  }, []);

  const loadSpotDayAvailabilityList = async (
    isFirstLoad = false,
  ): Promise<void> => {
    try {
      const availabilities = await getAvailabilityByParkingSpotAndDateRange(
        currentDay.format('yyyy-MM-DD'),
        lastDayOfTheCurrentWeek.format('yyyy-MM-DD'),
        parseInt(props.match.params.parkingSpotId),
        token,
      );
      setAvailabilityData(availabilities);

      if (isFirstLoad) {
        const dateDiff =
          moment(availabilities.lastAvailabilityDate).diff(currentDay, 'days') +
          1;

        setTotalPage(Math.ceil(dateDiff / 7));
      }
    } catch (errorMessage) {
      throw errorMessage;
    }
  };

  const nextPage = async (): Promise<void> => {
    if (currentPage < totalPage && !isHeaderLoading) {
      setHeaderLoading(true);
      setCurrentDay(currentDay.add(7, 'days'));
      setLastDayOfTheCurrentWeek(lastDayOfTheCurrentWeek.add(7, 'days'));
      await loadSpotDayAvailabilityList();
      setCurrentPage(currentPage + 1);
      setHeaderLoading(false);
    }
  };

  const previousPage = async (): Promise<void> => {
    if (currentPage > 1 && !isHeaderLoading) {
      setHeaderLoading(true);
      setCurrentDay(currentDay.subtract(7, 'days'));
      setLastDayOfTheCurrentWeek(lastDayOfTheCurrentWeek.subtract(7, 'days'));
      await loadSpotDayAvailabilityList();
      setCurrentPage(currentPage - 1);
      setHeaderLoading(false);
    }
  };

  useEffect(() => { setMenuPageById(MenuPageIdEnum.MANAGE_AVAILABILITY); },[]);

  useEffect((): void => {
    (async (): Promise<void> => {
      setHeaderLoading(true);
      try {
        await loadSpotDayAvailabilityList(true);
        const spot = await getParkingSpotWithCurrentReservation(
          token,
          getTimeSlotByCurrentTime(new Date()),
          parseInt(props.match.params.parkingSpotId),
        );
        setSpotInfo(spot);
      } catch (errorMessage) {
        displaySnack(errorMessage, 'error');
        setTimeout(() => {
          goToPageRouteById(MenuPageIdEnum.HOME);
        }, 3000);
      } finally {
        setHeaderLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <EditAvailabilityComponent
        parkingSpotId={parseInt(props.match.params.parkingSpotId)}
        open={openEditModal}
        onClose={async (): Promise<void> => {
          setOpenEditModal(false);
          await loadSpotDayAvailabilityList();
        }}
        date={selectedDateToEdit?.date}
        parkingSpotRentalTimes={selectedDateToEdit?.parkingSpotRentalTimes}
      />
      {isComponentLoading ? (
        <Modal open>
          <div>
            <CircularProgress className={classes.circularProgress} />
          </div>
        </Modal>
      ) : null}
      <ContainerComponent>
        <>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Typography variant="body2" className={classes.upTitle}>
                Status
              </Typography>
              <Grid container item direction="row">
                <div className={classes.statusColor} />
                <Typography
                  variant="body2"
                  className={classes.parkingSpotReservationData}
                >
                  {getAvailabilityText(
                    spotInfo.status,
                    spotInfo?.currentReservation?.finalDatetime,
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography
                align="right"
                variant="body2"
                className={classes.upTitle}
              >
                Ocupante
              </Typography>
              <Typography
                variant="body2"
                align="right"
                className={classes.parkingSpotData}
              >
                {spotInfo.status === ParkingSpotStatusEnum.HAS_RESERVATION
                  ? spotInfo.currentReservation.lessorUserName
                  : 'Ocupada por mim'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" direction="row">
            <Grid item xs={12} container direction="column">
              <Grid item xs={6} sm={12}>
                <Typography variant="body2" className={classes.title}>
                  Identificação
                </Typography>
                <Typography variant="body2" className={classes.parkingSpotData}>
                  {spotInfo.code}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container direction="column">
              {spotInfo?.currentReservation?.apartment ? (
                <Grid item xs={12} sm={12}>
                  <Typography
                    align="right"
                    variant="body2"
                    className={classes.title}
                  >
                    Apto - Bloco
                  </Typography>
                  <Typography
                    align="right"
                    variant="body2"
                    className={classes.parkingSpotData}
                  >
                    {spotInfo.currentReservation?.apartment} -{' '}
                    {spotInfo.currentReservation?.block}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <Typography variant="body2" className={classes.title}>
            Horários e reservas
          </Typography>
          {availabilityData.lastAvailabilityDate && totalPage > 1 ? (
            <PaginationComponent
              totalPages={totalPage}
              currentPage={currentPage}
              previousPageFuntion={(): Promise<void> => previousPage()}
              nextPageFuntion={(): Promise<void> => nextPage()}
            />
          ) : null}
          {availabilityData.parkingSpotRentalTimeGrouped.map(
            (rentalGroup, index) => (
              <TimesAndReservationsItemComponent
                key={index}
                rentalTimesInfo={rentalGroup}
                onButtonClick={(date, parkingSpotRentalTimes): void => {
                  setSelectedDateToEdit({ date, parkingSpotRentalTimes });
                  setOpenEditModal(true);
                }}
              />
            ),
          )}
          <MakeAvailabilityNowComponent
            parkingSpotId={parseInt(props.match.params.parkingSpotId)}
            open={openMakeAvailabilityNowDialog}
            onConfirm={async (): Promise<void> => {
              setOpenMakeAvailabilityNowDialog(false);
              await loadSpotDayAvailabilityList();
            }}
            onClose={(): void => setOpenMakeAvailabilityNowDialog(false)}
            setLoading={(value: boolean): void => setComponentLoading(value)}
          />
          <Box className={classes.iconBox}>
            <IconButton
              onClick={(): void => setOpenMakeAvailabilityNowDialog(true)}
              className={classes.iconButton}
            >
              <OpenGarageIcon
                className={classes.icon}
                fill="lightgrey"
                fontSize={125}
              />
            </IconButton>
          </Box>
        </>
      </ContainerComponent>
    </>
  );
}
