import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { useAuthContext } from '../context/auth.context';
import { UserInterface, UserType } from '../interface/user.interface';
import { useMenuContext } from '../context/menu.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';

export const usersMock: UserInterface[] = [
  {
    id: 1,
    name: 'Uesley',
    type: UserType.RESIDENT,
    email: 'Uesley@teste.com',
    telephone: '',
    cpf: '',
    birthday: '',
  },
  {
    id: 2,
    name: 'Carlos',
    type: UserType.RESIDENT,
    email: 'Carlos@teste.com',
    telephone: '',
    cpf: '',
    birthday: '',
  },
  {
    id: 3,
    name: 'Marcos',
    type: UserType.RESIDENT,
    email: 'Marcos@teste.com',
    telephone: '',
    cpf: '',
    birthday: '',
  },
  {
    id: 4,
    name: 'Claudio',
    type: UserType.RESIDENT,
    email: 'Claudio@teste.com',
    telephone: '',
    cpf: '',
    birthday: '',
  },
  {
    id: 5,
    name: 'Admin',
    type: UserType.ADMIN,
    email: 'Admin@teste.com',
    telephone: '',
    cpf: '',
    birthday: '',
  },
];

export const pagSeguroCodMock = '4ED24FBB2828E0B224A30FBD1C925427';
export const cpfMock = '111.111.111-11';

export function LoginMockComponent(): JSX.Element {
  const { setAuthenticatedUser } = useAuthContext();
  const { goToPageRouteById } = useMenuContext();
  const loginAndRedirect = (authenticatedUser: UserInterface): void => {
    setAuthenticatedUser(authenticatedUser);
    goToPageRouteById(MenuPageIdEnum.HOME);
  };

  return (
    <div>
      <p>Mock Ativado!</p>
      <p>Selecione um usuário para efetuar o login</p>
      <List component="nav" aria-label="secondary mailbox folders">
        {usersMock.map((user) => (
          <ListItem
            button
            key={user.id}
            onClick={(): void => loginAndRedirect(user)}
          >
            <ListItemText primary={`${user.id} - ${user.name}`} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
