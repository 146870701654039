import { MenuPageInterface } from '../interface/menu-page.interface';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { HomePage } from '../page/home.page';
import { AuthPage } from '../page/auth.page';
import { UserType } from '../interface/user.interface';
import { DefaultRedirectPage } from '../page/default-redirect.page';
import { ManageAvailabilityPage } from '../page/manage-availability.page';
import { LogoutComponent } from '../component/logout.component';
import { TalkToUsPage } from '../page/talk-to-us.page';
import { AccountVerificationPage } from '../page/account-verification.page';
import { MyDataPage } from '../page/my-data.page';
import { ChangePasswordPage } from '../page/change-password.page';
import { UserRequestApartmentPage } from '../page/user-request-apartment.page';
import AdministrationPage from '../page/administration.page';
import MySpotsPage from '../page/my-spots.page';
import MyCondominiumsPage from '../page/my-comdominiums.page';
import UserRequestParkingSpotPage from '../page/user-request-parking-spot.page';
import { UsersPage } from '../page/users.page';
import { UserDataPage } from '../page/user-data.page';
import TransactionStatementPage from '../page/transaction-statement.page';
import MyReservationsPage from '../page/my-reservations.page';
import CreateNewReservationPage from '../page/create-new-reservation.page';
import { BuyCreditsPage } from '../page/buy-credits.page';
import WithdrawalRequestPage from '../page/withdrawal-request.page';
import { NotificationsPage } from '../page/notifications.page';
import { TermsOfUsePage } from '../page/terms-of-use.page';

export const routesConfig: MenuPageInterface[] = [
  {
    id: MenuPageIdEnum.HOME,
    title: 'Inicio',
    icon: 'home',
    path: '/home',
    showCondominumSelect: true,
    isPrivate: true,
    hideMenuListItem: UserType.RESIDENT ? false : true,
    restrictedToUserTypes: [UserType.RESIDENT],
    component: HomePage,
  },
  {
    id: MenuPageIdEnum.AUTH,
    title: 'Autenticação',
    icon: 'home',
    path: '/auth/:subroute',
    menuRedirectTo: '/auth/login',
    hideMenuListItem: true,
    disableContainer: true,
    component: AuthPage,
  },
  {
    id: MenuPageIdEnum.NOTIFICATIONS,
    title: 'Avisos',
    icon: 'report',
    path: '/notifications',
    showCondominumSelect: false,
    isPrivate: true,
    hideMenuListItem: UserType.RESIDENT ? false : true,
    restrictedToUserTypes: [UserType.RESIDENT],
    component: NotificationsPage,
  },
  {
    id: MenuPageIdEnum.CONDOMINIUMS,
    title: 'Meus condomínios',
    icon: 'apartment',
    path: '/condominiums',
    isPrivate: true,
    fixHeader: true,
    restrictedToUserTypes: [UserType.RESIDENT],
    component: MyCondominiumsPage,
  },
  {
    id: MenuPageIdEnum.USER_REQUEST_APARTMENT,
    title: 'Solicitação de vínculo',
    icon: 'apartment',
    path: '/user-request-apartment',
    isPrivate: true,
    fixHeader: true,
    hideMenuListItem: true,
    restrictedToUserTypes: [UserType.RESIDENT],
    component: UserRequestApartmentPage,
  },
  {
    id: MenuPageIdEnum.MY_RESERVATIONS,
    title: 'Minhas reservas',
    icon: 'emoji_transportation',
    path: '/my-reservations',
    isPrivate: true,
    fixHeader: true,
    showCondominumSelect: true,
    restrictedToUserTypes: [UserType.RESIDENT],
    component: MyReservationsPage,
  },
  {
    id: MenuPageIdEnum.NEW_RESERVATION_PAGE,
    title: 'Criar nova reserva',
    icon: 'emoji_transportation',
    path: '/create-new-reservations',
    isPrivate: true,
    hideMenuListItem: true,
    showCondominumSelect: true,
    fixHeader: true,
    restrictedToUserTypes: [UserType.RESIDENT],
    component: CreateNewReservationPage,
  },
  {
    id: MenuPageIdEnum.MY_SPOTS,
    title: 'Minhas vagas',
    icon: 'directions_car',
    path: '/mySpots',
    isPrivate: true,
    fixHeader: true,
    showCondominumSelect: true,
    restrictedToUserTypes: [UserType.RESIDENT],
    component: MySpotsPage,
  },
  {
    id: MenuPageIdEnum.USER_REQUEST_PARKING_SPOT,
    title: 'Solicitação de vínculo com vaga',
    icon: 'directions_car',
    path: '/user-request-parking-spot',
    isPrivate: true,
    hideMenuListItem: true,
    fixHeader: true,
    restrictedToUserTypes: [UserType.RESIDENT],
    component: UserRequestParkingSpotPage,
  },
  {
    id: MenuPageIdEnum.TRANSACTIONS_STATEMENT,
    title: 'Extrato',
    icon: 'monetization_on',
    path: '/transaction-statement',
    fixHeader: true,
    isPrivate: true,
    restrictedToUserTypes: [UserType.RESIDENT],
    component: TransactionStatementPage,
  },
  {
    id: MenuPageIdEnum.WITHDRAWAL_REQUEST,
    title: 'Solicitação de Saque',
    icon: 'money',
    path: '/withdrawal-request',
    isPrivate: true,
    hideMenuListItem: true,
    fixHeader: true,
    restrictedToUserTypes: [UserType.RESIDENT],
    component: WithdrawalRequestPage,
  },
  {
    id: MenuPageIdEnum.CONTACT,
    title: 'Fale conosco',
    icon: 'chat',
    path: '/contact',
    isPrivate: true,
    restrictedToUserTypes: [UserType.RESIDENT],
    component: TalkToUsPage,
  },
  {
    id: MenuPageIdEnum.MY_DATA,
    title: 'Meus dados',
    icon: 'person',
    path: '/mydata',
    isPrivate: true,
    component: MyDataPage,
  },
  {
    id: MenuPageIdEnum.ADMIN,
    title: 'Administração',
    icon: 'settings',
    path: '/admin',
    isPrivate: true,
    restrictedToUserTypes: [UserType.ADMIN],
    component: AdministrationPage,
  },
  {
    id: MenuPageIdEnum.USERS,
    title: 'Usuários',
    icon: 'person',
    path: '/users',
    isPrivate: true,
    restrictedToUserTypes: [UserType.ADMIN],
    component: UsersPage,
  },
  {
    id: MenuPageIdEnum.USER_DATA,
    title: 'Dados do usuário',
    icon: 'person',
    path: '/user-data/:userId',
    menuRedirectTo: '/user-data/',
    isPrivate: true,
    fixHeader: true,
    hideMenuListItem: true,
    restrictedToUserTypes: [UserType.ADMIN],
    component: UserDataPage,
  },
  {
    id: MenuPageIdEnum.TERMS,
    title: 'Termos de uso',
    icon: 'menu_book',
    path: '/terms',
    showTopDivider: true,
    isSmall: true,
    component: TermsOfUsePage,
  },
  {
    id: MenuPageIdEnum.LOGOUT,
    title: 'Sair',
    icon: 'exit_to_app',
    isPrivate: true,
    path: '/logout',
    isSmall: true,
    component: LogoutComponent,
  },
  {
    id: MenuPageIdEnum.CHANGE_PASSWORD,
    title: 'Alterar senha',
    icon: 'password',
    path: '/change-password',
    hideMenuListItem: true,
    component: ChangePasswordPage,
  },
  {
    id: MenuPageIdEnum.BUY_CREDITS,
    title: 'Comprar creditos',
    icon: 'password',
    path: '/buy-credits',
    hideMenuListItem: true,
    component: BuyCreditsPage,
  },
  {
    id: MenuPageIdEnum.ACCOUNT_VERIFICATION,
    title: 'Verificação de conta',
    icon: '',
    path: '/account-verification',
    hideMenuListItem: true,
    component: AccountVerificationPage,
  },
  {
    id: MenuPageIdEnum.MANAGE_AVAILABILITY,
    title: 'Disponibilidade de vaga',
    icon: '',
    path: '/manage-availability/:parkingSpotId',
    menuRedirectTo: '/manage-availability/',
    fixHeader: true,
    hideMenuListItem: true,
    showCondominumSelect: false,
    restrictedToUserTypes: [UserType.RESIDENT],
    component: ManageAvailabilityPage,
  },
  {
    // Precisa ser a ultima rota declarada
    id: MenuPageIdEnum.DEFAULT,
    title: 'Default',
    icon: 'home',
    path: '/',
    hideMenuListItem: true,
    component: DefaultRedirectPage,
  },
];
