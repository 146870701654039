import React, { useState, useEffect } from 'react';
import { ParkingSpotRentalTimeInterface } from '../interface/parking-spot-rental-time.interface';
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import moment from 'moment';
import BrokenHourDotComponent from './broken-hour-dot.component';
import HourOClockDotComponent from './hour-o-clock-dot.component';
import { IsAvailablePropInterface } from '../interface/is-available-props.interface';

interface TimeListProps {
  hidePreviousHoursIfCurrentDay?: boolean;
  parkingSpotRentalTimes: ParkingSpotRentalTimeInterface[];
  date: string;
  nextPage?: () => void | Promise<void>;
  previousPage?: () => void | Promise<void>;
}
interface StylesInterface {
  isMobile: boolean;
  color: string;
}

interface TimeInterface extends IsAvailablePropInterface {
  hour: string;
  minute: string;
}

const useStyles = makeStyles({
  gateScroll: (props: StylesInterface) => ({
    width: '100%',
    height: 60,
    overflow: 'scroll',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    '&::-webkit-scrollbar': {
      height: props.isMobile ? '0px' : '7px',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.15)',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(90,190,252,0.7)',
      borderRadius: 10,
    },
  }),
  timesContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  nextButton: {
    marginLeft: 5,
    marginRight: 10,
    color: '#6ABEFF',
  },
  previousButton: {
    marginLeft: 10,
    color: '#6ABEFF',
  },
});

function shortTimesInAscOrder(
  a: ParkingSpotRentalTimeInterface,
  b: ParkingSpotRentalTimeInterface,
): number {
  if (a.timeSlotId < b.timeSlotId) return -1;
  if (a.timeSlotId > b.timeSlotId) return 1;

  return 0;
}

function getHourStringSettingLeftZero(hour: number): string {
  return hour < 10 ? '0' + hour : String(hour);
}

function getHourSubstringFromTime(time: string): string {
  return time.substring(0, 2);
}

function getMinutesSubstringFromTime(time: string): string {
  return time.substring(time.length - 2, time.length);
}

const TimeListComponent: React.FC<TimeListProps> = ({
  hidePreviousHoursIfCurrentDay = false,
  parkingSpotRentalTimes,
  date,
  nextPage,
  previousPage,
}: TimeListProps) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const classes = useStyles({ color: '', isMobile });
  const [times, setTimes] = useState<TimeInterface[]>([]);
  const [hours, setHours] = useState<number[]>([]);

  const setHoursOnState = (): void => {
    const currentDate = moment().format('yyyy-MM-DD');
    const hoursArray = [];
    if (hidePreviousHoursIfCurrentDay && date === currentDate)
      for (let index = new Date().getHours(); index < 24; index++) {
        hoursArray.push(index);
      }
    else {
      for (let index = 0; index < 24; index++) {
        hoursArray.push(index);
      }
    }
    setHours(hoursArray);
  };

  const setTimesOnState = (): void => {
    const timesArray: TimeInterface[] = [];
    hours.map((hour) => {
      const timesFounded = parkingSpotRentalTimes.filter(
        (rentalTime) =>
          getHourSubstringFromTime(rentalTime.initialTime) ===
          getHourStringSettingLeftZero(hour),
      );

      timesFounded.sort(shortTimesInAscOrder);

      const minutes = ['00', '15', '30', '45'];
      for (let index = 0; index < minutes.length; index++) {
        const found = timesFounded.find(
          (timeFounded) =>
            getMinutesSubstringFromTime(timeFounded.initialTime) ===
            minutes[index],
        );

        if (!found)
          timesArray.push({
            hour: getHourStringSettingLeftZero(hour),
            minute: minutes[index],
            isAvailable: false,
            isReserved: false,
          });
        else
          timesArray.push({
            hour: getHourStringSettingLeftZero(hour),
            minute: getMinutesSubstringFromTime(found.initialTime),
            isAvailable: true,
            isReserved: found.reservationId ? true : false,
          });
      }
    });

    setTimes(timesArray);
  };

  useEffect((): void => setHoursOnState(), [date]);

  useEffect((): void => setTimesOnState(), [hours, parkingSpotRentalTimes]);

  return (
    <>
      <div className={classes.gateScroll}>
        <div className={classes.timesContainer}>
          {!!previousPage ? (
            <Button
              onClick={async (): Promise<void> => await previousPage()}
              variant="text"
              color="primary"
              className={classes.nextButton}
            >
              Anterior
            </Button>
          ) : null}
          {times.map((time, index) =>
            time.minute !== '00' ? (
              <Grid
                key={index}
                container
                direction="column"
                justify="flex-end"
                alignItems="center"
              >
                <BrokenHourDotComponent
                  isAvailable={time.isAvailable}
                  isReserved={time.isReserved}
                  style={{ marginTop: 22 }}
                />
              </Grid>
            ) : (
              <Grid
                key={index}
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Typography
                  variant="caption"
                  display="block"
                  color="textSecondary"
                >
                  {time.hour}
                </Typography>
                <HourOClockDotComponent
                  isAvailable={time.isAvailable}
                  isReserved={time.isReserved}
                />
              </Grid>
            ),
          )}
          {!!nextPage ? (
            <Button
              onClick={async (): Promise<void> => await nextPage()}
              variant="text"
              color="primary"
              className={classes.previousButton}
            >
              Próximo
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default TimeListComponent;
