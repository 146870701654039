import { makeStyles } from '@material-ui/core';

export default makeStyles({
  title: {
    paddingTop: 15,
    fontSize: 20,
  },
  spotCardComponent: {
    marginTop: '30px',
    marginBottom: '90px',
  },
});
