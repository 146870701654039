import React, { useCallback, useEffect } from 'react';

import { useMenuContext } from '../context/menu.context';

export function LogoutComponent(): JSX.Element {
  const { logoutAndRedirect } = useMenuContext();

  const logoutCallback = useCallback(async () => {
    await logoutAndRedirect();
  }, []);

  useEffect(() => {
    logoutCallback();
  }, []);

  return <div></div>;
}
