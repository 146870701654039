import React, { createContext, useContext, useState } from 'react';
import { useLocalStorageState } from '../common/common.hook';
import { throwContextError } from '../common/util.common';
import { CondominiumInterface } from '../interface/condominium.interface';
import { MenuPageInterface } from '../interface/menu-page.interface';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { useHistory } from 'react-router-dom';
import { routesConfig } from '../config/routes.config';
import { useAuthContext } from './auth.context';
import { updateMyData } from '../service/user.service';
import { LoginInterface } from '../interface/login.interface';
const businessContext = 'Menu';

interface MenuContextInterface {
  openMenu: boolean;
  closeMenu: () => void;
  toogleMenu: () => void;

  isHeaderLoading: boolean;
  setHeaderLoading: (value: boolean) => void;

  menuPages: MenuPageInterface[];
  menuPage: MenuPageInterface;
  setMenuPage: (menuPage: MenuPageInterface) => void;
  setMenuPageById: (id: MenuPageIdEnum) => void;

  condominiums: CondominiumInterface[];
  setCondominiums: (condominiums: CondominiumInterface[]) => void;

  condominium: CondominiumInterface;
  setCondominium: (condominium: CondominiumInterface) => void;

  getMenuPageById: (menuPageId: MenuPageIdEnum) => MenuPageInterface;
  goToPath: (path: string, param?: number) => void;
  goToPageRouteById: (
    menuPageId: MenuPageIdEnum,
    param?: number,
    queries?: unknown[],
  ) => void;

  logoutAndRedirect: () => Promise<void>;
}

const MenuContext = createContext<MenuContextInterface>(null);

export function MenuProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { logout, authenticatedUser, token, setAuthenticatedUser } = useAuthContext();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const toogleMenu = (): void => setOpenMenu(!openMenu);
  const closeMenu = (): void => setOpenMenu(false);

  const [isHeaderLoading, setHeaderLoading] = useState<boolean>(false);

  const [menuPages] = useState<MenuPageInterface[]>(routesConfig);
  const [menuPage, setMenuPage] = useState<MenuPageInterface>();

  const history = useHistory();

  const getMenuPageById = (menuPageId: MenuPageIdEnum): MenuPageInterface =>
    menuPages.find((menuPage) => menuPage.id === menuPageId);

  const goToPath = (
    path: string,
    param?: number,
    queries: unknown[] = [],
  ): void => {
    const urlQuery = new URLSearchParams();
    queries.map((query) => {
      urlQuery.append(Object.keys(query)[0], Object.values(query)[0]);
    });
    closeMenu();
    if (history)
      history.push(
        `${path}${param ? param : ''}${
          queries.length !== 0 ? '?' + urlQuery.toString() : ''
        }`,
      );
  };
  const goToPageRouteById = (
    menuPageId: MenuPageIdEnum,
    param?: number,
    queries: unknown[] = [],
  ): void => {
    const menuPage = getMenuPageById(menuPageId);
    if (menuPage)
      goToPath(menuPage.menuRedirectTo || menuPage.path, param, queries);
  };

  const getMyData = async ()=>{
    const data = await updateMyData(authenticatedUser.id, token);
    if (data) {
      setAuthenticatedUser(data.user);
      if(data.user.condominiums){
        if(data.user.condominiums.length > 0){
          setCondominiums(data.user.condominiums);
          if(!condominium){
            setCondominium(data.user.condominiums[0]);
          }
        }
      }
    }
  }

  const setMenuPageById = (id: MenuPageIdEnum):void => {
    
    const menuPage = getMenuPageById(id);
    if(authenticatedUser && authenticatedUser.type != 'ADMIN' && token && menuPage.id != MenuPageIdEnum.AUTH && menuPage.id != MenuPageIdEnum.LOGOUT){
      getMyData()
    }
    
    if (
      (menuPage.isPrivate && !authenticatedUser) ||
      (authenticatedUser && menuPage.id === MenuPageIdEnum.AUTH)
    )
      return goToPageRouteById(MenuPageIdEnum.HOME);
    if (menuPage) setMenuPage(menuPage);
  };

  const logoutAndRedirect = async (): Promise<void> => {
    setCondominiums([]);
    setCondominium(null);
    await logout();
    goToPageRouteById(MenuPageIdEnum.AUTH);
  };

  const [condominiums, setCondominiums] = useLocalStorageState<
    CondominiumInterface[]
  >([], 'CONDOMINIUMS', JSON.parse);

  const [condominium, setCondominium] = useLocalStorageState<
    CondominiumInterface
  >(null, 'CONDOMINIUM', JSON.parse);

  return (
    <MenuContext.Provider
      value={{
        openMenu,
        closeMenu,
        isHeaderLoading,
        setHeaderLoading,
        toogleMenu,
        condominiums,
        setCondominiums,
        condominium,
        setCondominium,
        menuPage,
        menuPages,
        getMenuPageById,
        setMenuPageById,
        goToPageRouteById,
        goToPath,
        logoutAndRedirect,
        setMenuPage,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}

export function useMenuContext(): MenuContextInterface {
  const context = useContext(MenuContext);
  if (!context) throwContextError(businessContext);
  return context;
}
