import api from './api';
import { ParkingSpotWeekAvailabilityInterface } from '../interface/parking-spot-week-availability.interface';
import { RentalTimesAvailabilityResponseInterface } from '../interface/rental-times-availability-response.interface';
import { ParkingSpotCurrentReservationInterface } from '../interface/parking-spot-current-reservation.interface';
import { ParkingSpotsWithPendingApprobationInterface } from '../interface/parking-spot-with-pending-approbation.interface';
import { GateAvailabilityInterface } from '../interface/gate-availability.interface';
import { ParkingSpotRentalTimeInterface } from '../interface/parking-spot-rental-time.interface';
import { SettingsInterface } from '../interface/settings.interface';
import { SettingsNameEnum } from '../enum/settings-name.enum';
import { AxiosResponse } from 'axios';
import { AvailabilityIntervalInterface } from '../interface/availability-interval.interface';

export const getMyParkingSpotsWithWeekAvailability = async (
  condominiumId: number,
  token: string,
): Promise<ParkingSpotWeekAvailabilityInterface[]> => {
  try {
    const condoId = 'x-condominium-id';
    const { data } = await api.get<ParkingSpotWeekAvailabilityInterface[]>(
      '/week-availability',
      {
        headers: {
          Authorization: `Bearer ${token}`,
          [condoId]: condominiumId,
        },
      },
    );
    return data;
  } catch (error) {
    if (error.response) throw error.response.data.message;
    throw 'Verifique sua conexão com a internet!';
  }
};

export const getParkingSpotsForApprobation = async (
  condominiumId: number,
  token: string,
): Promise<ParkingSpotsWithPendingApprobationInterface[]> => {
  try {
    const condoId = 'x-condominium-id';
    const queryString = '?onlyPendingApproval=true';

    const { data } = await api.get<
      ParkingSpotsWithPendingApprobationInterface[]
    >(`/parking-spots${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        [condoId]: condominiumId,
      },
    });
    return data;
  } catch (error) {
    if (error.response) throw error.response.data.message;
    throw 'Verifique sua conexão com a internet!';
  }
};

export const getGatesAvailabilities = async (
  condominiumId: number,
  token: string,
): Promise<GateAvailabilityInterface[]> => {
  const condominiumHeader = 'x-condominium-id';
  try {
    const { data } = await api.get(`available-gates?currentTime=00:00`, {
      headers: {
        [condominiumHeader]: condominiumId,
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    if (error.response) throw error.response.message;
    throw 'Verifique sua conexão com a internet!';
  }
};

export const getAvailabilityByParkingSpotAndDateRange = async (
  initialDate: string,
  finalDate: string,
  parkingSpotId: number,
  token: string,
): Promise<RentalTimesAvailabilityResponseInterface> => {
  try {
    const spotAndReservation: AxiosResponse<RentalTimesAvailabilityResponseInterface> = await api.get(
      `/parking-spots/${parkingSpotId}/availabilities?initialDate=${initialDate}&finalDate=${finalDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return spotAndReservation.data;
  } catch (error) {
    if (error.response) throw error.response.data.message;
    throw 'Verifique sua conexão com a internet!';
  }
};

export const getAvailabilityByGateAndCondominium = async (
  condominiumId: number,
  gate: string,
  date: string,
  token: string,
): Promise<ParkingSpotRentalTimeInterface[]> => {
  const condominiumHeader = 'x-condominium-id';
  try {
    const {
      data,
    }: AxiosResponse<ParkingSpotRentalTimeInterface[]> = await api.get(
      `available-times/${date}/?gate=${gate}`,
      {
        headers: {
          [condominiumHeader]: condominiumId,
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch (error) {
    if (error.response) throw error.response.data.message;
    throw 'Verifique sua conexão com a internet!';
  }
};

export const getParkingSpotWithCurrentReservation = async (
  token: string,
  currentTime: string,
  parkingSpotId: number,
): Promise<ParkingSpotCurrentReservationInterface> => {
  try {
    const spot: AxiosResponse<ParkingSpotCurrentReservationInterface> = await api.get(
      `parking-spots/${parkingSpotId}?currentTime=${currentTime}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return spot.data;
  } catch (error) {
    if (error.response) throw error.response.data.message;
    throw 'Verifique sua conexão com a internet!';
  }
};

export const createAvailabilityFromTheRange = async (
  parkingSpotId: number,
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  token: string,
  condominiumId: number,
): Promise<string> => {
  try {
    const priceMessage = await api.post<string>(
      `/parking-spots/${parkingSpotId}/availability-range`,
      {
        startDate,
        startTime,
        endDate,
        endTime,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
          ['x-condominium-id']: condominiumId,
        },
      },
    );
    return priceMessage.data;
  } catch ({ response }) {
    if (response?.data?.message) throw response.data.message;
    throw 'Verifique sua conexão com a internet!';
  }
};
export const getMaxDayToReservationsAvailability = async (
  token: string,
): Promise<SettingsInterface[]> => {
  try {
    const { data } = await api.get<SettingsInterface[]>('settings', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.filter((request: SettingsInterface) => {
      return (
        request.name === SettingsNameEnum.MAX_DAYS_TO_RESERVATIONS_AVAILABILITY
      );
    });
  } catch (error) {
    if (error.response) throw error.response.data.message;
    throw 'Verifique sua conexão com a internet!';
  }
};

export const editAvailabilitiesOfDate = async (
  parkingSpotId: number,
  date: string,
  intervals: AvailabilityIntervalInterface[],
  token: string,
): Promise<string> => {
  try {
    const message = await api.put<void>(
      `parking-spots/${parkingSpotId}/availability?date=${date}`,
      {
        intervals,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return String(message.data);
  } catch (error) {
    if (error.response) throw error.response.data.message;
    throw 'Verifique sua conexão com a internet!';
  }
};
