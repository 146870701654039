import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useMediaQuery } from '@material-ui/core';
import CardContainerComponent from '../component/card-container.component';
import ReserveSpotDatesComponent from '../component/reserve-spot-dates.component';
import { ContainerComponent } from '../component/container.component';
import { useSnackbarContext } from '../context/snackbar.context';
import {
  cancelSpotArrival,
  getReservations,
  // registerSpotArrival,
} from '../service/reservation.service';
import { ReservationWithParkingSpotsInterface } from '../interface/reservation-with-parking-spots.interface.dto';
import { ReservationStatusEnum } from '../enum/reservation-status.enum';
import { SpeedButtonComponent } from '../component/speed-button.component';
import {
  SpeedButtonActionInterface,
  SpeedButtonPropsInterface,
} from '../interface/speed-button.interface';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { SpeedDialIcon } from '@material-ui/lab';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { useMenuContext } from '../context/menu.context';
import { useAuthContext } from '../context/auth.context';
import { VagasIcon } from '../icons';

import { Button, makeStyles, Typography, Grid } from '@material-ui/core';
import CondominiumFloorPlanModalComponent from '../component/condominium-floor-plan-modal.component';

const useStyles = makeStyles({
  root: {
    paddingBottom: '10%',
  },
  imageButtonBaseContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageButtonBase: {
    width: '100%',
    maxWidth: '500px',
  },
  text: {
    color: 'grey',
    fontSize: 18,
    textAlign: 'center',
  },
  withoutReservationsLogo: {
    marginTop: '8%',
  },
  buttonCancel: {
    width: '100%',
  },
});

const MyReservationsPage: React.FC = () => {
  const matchMinScreenSize = useMediaQuery('(min-width: 400px)');
  const classes = useStyles({ matchMinScreenSize });
  const [reservations, setReservations] = useState<
    ReservationWithParkingSpotsInterface[]
  >([]);
  const [
    openCondominiumFloorPlanModal,
    setOpenCondominiumFloorPlanModal,
  ] = useState<boolean>(false);
  const { displaySnack } = useSnackbarContext();
  const {
    setMenuPageById,
    setHeaderLoading,
    isHeaderLoading,
    condominium,
  } = useMenuContext();
  const { token } = useAuthContext();
  const [count, setCount] = useState(0);
  const floatingButtonActions: SpeedButtonActionInterface[] = [
      {
        name: 'Reservar',
        icon: <AddCircleIcon />,
        menuPageId: MenuPageIdEnum.NEW_RESERVATION_PAGE,
      },
    ],
    floatingButtonProps: SpeedButtonPropsInterface = {
      icon: <SpeedDialIcon />,
      actions: floatingButtonActions,
    };

  // const ConfirmButton = withStyles({
  //   root: {
  //     backgroundColor: '#3FB7F8',
  //     color: 'white',
  //     width: '100%',
  //     '&:hover': {
  //       backgroundColor: '#0069d9',
  //       boxShadow: 'none',
  //     },
  //     '&:active': {
  //       boxShadow: 'none',
  //       backgroundColor: '#0062cc',
  //     },
  //     '&:focus': {
  //       boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  //     },
  //   },
  // })(Button);

  const getReservationsFromApi = async (
    condominiumId: number,
  ): Promise<void> => {
    setHeaderLoading(true);
    try {
      const reservationsResponse = await getReservations(token, condominiumId);
      reservationsResponse.map((aux) =>
        aux.reservationParkingSpots.sort((a, b) => {
          if (a.startTime > b.startTime) return 1;
          if (a.startTime < b.startTime) return -1;
          return 0;
        }),
      );
      setReservations(reservationsResponse);
    } catch (error) {
      displaySnack(error, 'error', true);
    } finally {
      setHeaderLoading(false);
    }
  };

  // const onArrival = async (reservationParkingSpotId: number): Promise<void> => {
  //   try {
  //     setHeaderLoading(true);
  //     await registerSpotArrival(
  //       token,
  //       reservationParkingSpotId,
  //       ReservationStatusEnum.CHECK_IN,
  //     );
  //     setHeaderLoading(false);
  //     displaySnack('Confirmado!', 'success', true);
  //     setCount(count + 1);
  //   } catch (error) {
  //     displaySnack(error, 'info', true);
  //     setHeaderLoading(false);
  //   }
  // };

  const onCancel = async (reservationId: number): Promise<void> => {
    setHeaderLoading(true);
    try {
      await cancelSpotArrival(token, reservationId);
      displaySnack('Reserva cancelada com sucesso!', 'success', true);
      setCount(count + 1);
    } catch (error) {
      displaySnack(error, 'error', true);
    } finally {
      setHeaderLoading(false);
    }
  };

  useEffect(() => { setMenuPageById(MenuPageIdEnum.MY_RESERVATIONS); },[]);

  useEffect(() => {
    setReservations([]);
    (async (): Promise<void> => {
      if (condominium?.id) await getReservationsFromApi(condominium?.id);
    })();
  }, [condominium, count]);

  useEffect(() => {
    setTimeout(() => {
      setCount(count + 1);
    }, 50000);
    return (): void => clearTimeout(count);
  }, [count]);

  const spotValidationDateTimeAndStatus = (
    status: ReservationStatusEnum,
    startDate: string,
    startTime: string,
    minutesBeforeShow: number,
  ): boolean =>
    status === ReservationStatusEnum.CREATED &&
    moment().diff(moment(startDate), 'day') === 0 &&
    moment(startDate + ' ' + startTime).diff(moment(), 'minutes') <
      minutesBeforeShow;

  return (
    <ContainerComponent>
      <div className={classes.root}>
        {!reservations[0] ? (
          <Grid
            className={classes.withoutReservationsLogo}
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <VagasIcon fill="lightgray" fontSize={125} />
            <Typography className={classes.text}>
              Você não tem reserva agendada
            </Typography>
          </Grid>
        ) : (
          reservations.map((reservation, index) => (
            <CardContainerComponent
              key={reservation?.id}
              sideText={Number(reservation?.totalAmount).toLocaleString(
                'pt-br',
                {
                  currency: 'BRL',
                  style: 'currency',
                },
              )}
              headerTitle={
                reservation?.reservationParkingSpots?.find(
                  (reserveSpot) => !!reserveSpot?.parkingSpot,
                )?.parkingSpot?.gate
              }
              headerSubtitle={
                moment(
                  reservation.startDate + ' ' + reservation.startTime,
                  'yyyy-MM-DD HH:mm',
                ).diff(moment(), 'minutes') <= 30
                  ? ' Aproveite sua reserva!'
                  : ' 30 minutos antes da reserva, será possível ver em qual vaga você deve estacionar.'
              }
            >
              <ReserveSpotDatesComponent
                key={index}
                startDate={reservation?.startDate}
                endDate={reservation?.endDate}
                parkingSpotsBooking={reservation?.reservationParkingSpots?.map(
                  (spotInfo) => {
                    return {
                      startDate: spotInfo?.startDate,
                      startTime: spotInfo?.startTime,
                      endDate: spotInfo?.endDate,
                      endTime: spotInfo?.endTime,
                      attachedText: !!spotInfo?.parkingSpot
                        ? spotInfo?.parkingSpot?.code
                        : '',
                    };
                  },
                )}
              />
              {!spotValidationDateTimeAndStatus(
                reservation?.status,
                reservation?.startDate,
                reservation?.startTime,
                30,
              ) || (
                <CondominiumFloorPlanModalComponent
                  open={openCondominiumFloorPlanModal}
                  setOpen={setOpenCondominiumFloorPlanModal}
                  condominiumParam={null}
                />
              )}
              {spotValidationDateTimeAndStatus(
                reservation.status,
                reservation.startDate,
                reservation.startTime,
                30,
              ) || (
                <Button
                  disabled={isHeaderLoading}
                  variant="text"
                  color="secondary"
                  fullWidth
                  className={classes.buttonCancel}
                  onClick={async (): Promise<void> =>
                    await onCancel(reservation.id)
                  }
                >
                  CANCELAR
                </Button>
              )}
              {/* {!spotValidationDateTimeAndStatus(
                reservation.reservationParkingSpots.find(
                  (aux) => aux.status === ReservationStatusEnum.CREATED,
                )?.status,
                reservation.reservationParkingSpots.find(
                  (aux) => aux.status === ReservationStatusEnum.CREATED,
                )?.startDate,
                reservation.reservationParkingSpots.find(
                  (aux) => aux.status === ReservationStatusEnum.CREATED,
                )?.startTime,
                5,
              ) || (
                <ConfirmButton
                  disabled={
                    isHeaderLoading ||
                    !reservation?.reservationParkingSpots?.find(
                      (reservationParkingSpot) =>
                        reservationParkingSpot?.parkingSpot,
                    )
                  }
                  type="submit"
                  onClick={async (): Promise<void> =>
                    await onArrival(
                      reservation?.reservationParkingSpots?.find(
                        (reservationParkingSpot) => {
                          return (
                            reservationParkingSpot?.status ===
                              ReservationStatusEnum.CREATED &&
                            !!reservationParkingSpot?.parkingSpot
                          );
                        },
                      )?.id,
                    )
                  }
                >
                  CHEGUEI
                </ConfirmButton>
              )} */}
            </CardContainerComponent>
          ))
        )}
        <SpeedButtonComponent {...floatingButtonProps} />
      </div>
    </ContainerComponent>
  );
};

export default MyReservationsPage;
