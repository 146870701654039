import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import {
  TextField,
  makeStyles,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import { useSnackbarContext } from '../context/snackbar.context';
import { forgotMyPassword } from '../service/auth.service';

interface StylesInterface {
  isMobile: boolean;
}

const useStyles = makeStyles({
  textField: (props: StylesInterface) => ({
    marginTop: '5px',
    width: props.isMobile ? '80%' : '70%',
  }),
  description: (props: StylesInterface) => ({
    marginLeft: props.isMobile ? '30px' : '75px',
    color: 'grey',
    fontSize: 16,
    width: '75%',
  }),
  buttonConfirm: (props: StylesInterface) => ({
    color: '#ffff',
    marginTop: 10,
    marginBottom: 10,
    marginRight: props.isMobile ? 10 : 40,
    marginLeft: props.isMobile ? 10 : 50,
    backgroundColor: '#6abeff',
  }),
  buttonCancel: {
    color: 'red',
    height: '20px',
    marginTop: 10,
  },
  dialog: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
});

const schema = yup.object().shape({
  email: yup
    .string()
    .email('O email é inválido')
    .required('O email é obrigatório'),
});

interface ForgotPasswordInputInterface {
  email: string;
}

interface ForgotPasswordModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

interface NotificationModalProps {
  openNotificationModal: boolean;
  onCloseNotificationModal: () => void;
}

export const NotificationModal: React.FC<NotificationModalProps> = ({
  openNotificationModal,
  onCloseNotificationModal,
}: NotificationModalProps) => {
  const isMobile = useMediaQuery('(max-width:570px)');
  const classes = useStyles({ isMobile });
  return (
    <div>
      <Dialog open={openNotificationModal}>
        <DialogTitle>{'Email enviado com sucesso'}</DialogTitle>
        <Typography className={classes.description}>
          Nós enviamos sua nova senha para o email informado
        </Typography>
        <Button onClick={(): void => onCloseNotificationModal()}>OK</Button>
      </Dialog>
    </div>
  );
};

export const ForgotMyPasswordComponent: React.FC<ForgotPasswordModalProps> = ({
  open,
  onClose,
}: ForgotPasswordModalProps) => {
  const isMobile = useMediaQuery('(max-width:570px)'),
    classes = useStyles({ isMobile }),
    [openNotificationModal, setOpenNotificationModal] = useState<boolean>(
      false,
    ),
    { displaySnack } = useSnackbarContext(),
    { register, handleSubmit, errors } = useForm<ForgotPasswordInputInterface>({
      resolver: yupResolver(schema),
      mode: 'all',
      shouldFocusError: true,
    });
  const onSubmit = async (
    input: ForgotPasswordInputInterface,
  ): Promise<void> => {
    try {
      await forgotMyPassword(input.email);
      onClose();
      setOpenNotificationModal(true);
    } catch (message) {
      onClose();
      displaySnack(`${message}`, 'error', true);
    }
  };

  return (
    <div>
      <NotificationModal
        openNotificationModal={openNotificationModal}
        onCloseNotificationModal={(): void => setOpenNotificationModal(false)}
      />
      <Dialog className={classes.dialog} open={open}>
        <DialogTitle>{'Esqueceu sua senha?'}</DialogTitle>
        <Typography className={classes.description}>
          Nos informe seu email de cadastro e entraremos em contato com você!
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            className={classes.textField}
            label="Email"
            fullWidth
            name="email"
            error={!!errors.email}
            helperText={errors?.email?.message}
            inputRef={register}
          />

          <Grid item container justify="flex-end">
            <Button onClick={(): void => onClose()}>Cancelar</Button>
            <Button type="submit" className={classes.buttonConfirm}>
              Enviar
            </Button>
          </Grid>
        </form>
      </Dialog>
    </div>
  );
};
