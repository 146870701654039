import {
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import BalanceDisplayComponent from '../component/balance-display.component';
import ReceivingMethodFormComponent from '../component/receiving-method-form.component';
import ReceivingMethodsModalComponent from '../component/receiving-method-modal.component';
import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { useSnackbarContext } from '../context/snackbar.context';
import { WalletBalanceResponseInterface } from '../interface/wallet-balance-response.interface';
import { consultWalletBalance } from '../service/wallet.service';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { PicpayAccountBodyInterface } from '../interface/picpay-account-register.interface';
import { BankAccountBodyInterface } from '../interface/bank-account-register.interface';
import {
  getUserAccounts,
  registerBankAccount,
  registerPicpayAccount,
} from '../service/user.service';
import { UserAccountInterface } from '../interface/user-account.interface';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { ContainerComponent } from '../component/container.component';

const useStyles = makeStyles({
  containedButton: {
    backgroundColor: '#5abefc',
    color: 'white',
  },
  textButton: {
    color: '#5abefc',
    marginBottom: 10,
  },
  buttonsWrapper: { padding: 10, height: '40vh' },
});

interface ButtonsOptionsProps {
  setOpenModal: (value: boolean) => void;
  setShowForm: (value: boolean) => void;
  setValue: (value: number) => void;
}
interface WithdrawalInterface {
  value: number;
}

const withdrawalSchema = yup.object().shape({
  value: yup
    .string()
    .min(1, 'Valor inválido')
    .required('Por favor informe o valor'),
});

const ButtonsOptionsComponent: React.FC<ButtonsOptionsProps> = (
  props: ButtonsOptionsProps,
) => {
  const classes = useStyles();
  const { register, errors } = useForm<WithdrawalInterface>({
    resolver: yupResolver(withdrawalSchema),
    mode: 'all',
    shouldFocusError: true,
  });
  return (
    <>
      <Grid item container justify="center" direction="column">
        <TextField
          label="Quanto você deseja sacar?"
          variant="outlined"
          type="number"
          name="value"
          inputRef={register}
          error={!!errors.value}
          helperText={errors?.value?.message}
          onChange={({ target }: ChangeEvent<HTMLInputElement>): void =>
            props.setValue(Number(target.value))
          }
        />
      </Grid>
      <Grid item container justify="center" direction="column">
        <Button
          variant="text"
          className={classes.textButton}
          onClick={(): void => props.setOpenModal(true)}
        >
          Selecionar método de recebimento
        </Button>
        <Button
          className={classes.containedButton}
          variant="contained"
          onClick={(): void => props.setShowForm(true)}
        >
          Novo método de recebimento
        </Button>
      </Grid>
    </>
  );
};

const WithdrawalRequestPage: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState<boolean>(true);
  const {
    isHeaderLoading,
    setHeaderLoading,
    setMenuPageById,
  } = useMenuContext();
  const { authenticatedUser, token } = useAuthContext();
  const { wallet } = authenticatedUser;
  const [userAccounts, setUserAccounts] = useState<UserAccountInterface[]>([]);
  const [userWallet, setUserWallet] = useState<
    Partial<WalletBalanceResponseInterface>
  >({
    balance: wallet.balance || 0,
    bonusBalance: wallet.bonusBalance || 0,
    totalAmount: wallet.totalAmount || 0,
  });
  const { displaySnack } = useSnackbarContext();
  const [value, setValue] = useState<number>(0);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width:800px)');

  useEffect(() => { setMenuPageById(MenuPageIdEnum.WITHDRAWAL_REQUEST); },[]);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        setHeaderLoading(true);
        const walletResponse = await consultWalletBalance(
          wallet.id,
          token,
          false,
        );
        if (walletResponse !== userWallet) setUserWallet(walletResponse);
      } catch (errorMessage) {
        displaySnack(errorMessage, 'error', true);
      } finally {
        setHeaderLoading(false);
      }
    })();
  }, [token, openModal]);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        setLoading(true);
        const accountsResponse = await getUserAccounts(
          authenticatedUser.id,
          token,
        );
        if (accountsResponse.length === 0) setShowForm(true);
        setUserAccounts(accountsResponse);
      } catch (errorMessage) {
        displaySnack(errorMessage, 'error', true);
      } finally {
        setLoading(false);
      }
    })();
  }, [openModal]);

  const registerPicpay = async (
    picpayAccountData: PicpayAccountBodyInterface,
  ): Promise<void> => {
    try {
      setHeaderLoading(true);
      await registerPicpayAccount(
        authenticatedUser.id,
        token,
        picpayAccountData,
      );
      displaySnack('Conta criada com sucesso!', 'success', true);
      setShowForm(false);
    } catch (errorMessage) {
      displaySnack(errorMessage, 'error', true);
      setShowForm(false);
    } finally {
      setHeaderLoading(false);
    }
  };

  const registerBank = async (
    bankAccountData: BankAccountBodyInterface,
  ): Promise<void> => {
    try {
      setHeaderLoading(true);
      await registerBankAccount(authenticatedUser.id, token, bankAccountData);
      displaySnack('Conta criada com sucesso!', 'success', true);
      setShowForm(false);
    } catch (errorMessage) {
      displaySnack(errorMessage, 'error', true);
    } finally {
      setHeaderLoading(false);
    }
  };

  return (
    <>
      {isLoading || (
        <ReceivingMethodsModalComponent
          userAccounts={userAccounts}
          open={openModal}
          onClose={(): void => setOpenModal(false)}
          value={value}
          userBalance={userWallet.balance}
          walletId={userWallet.id}
        />
      )}

      {isMobile ? (
        <BalanceDisplayComponent balance={userWallet.balance} />
      ) : (
        <ContainerComponent>
          <BalanceDisplayComponent balance={userWallet.balance} />
        </ContainerComponent>
      )}
      <Container maxWidth="xs">
        <Grid
          container
          justify="space-between"
          direction="column"
          className={classes.buttonsWrapper}
        >
          {showForm ? (
            <ReceivingMethodFormComponent
              onAbort={(): void => setShowForm(false)}
              onConfirmSubmit={(data): void => {
                if (isHeaderLoading) return;
                if ('username' in data)
                  registerPicpay(data as PicpayAccountBodyInterface);
                else registerBank(data as BankAccountBodyInterface);
              }}
            />
          ) : (
            <ButtonsOptionsComponent
              {...{ setOpenModal, setShowForm, setValue }}
            />
          )}
        </Grid>
      </Container>
    </>
  );
};

export default WithdrawalRequestPage;
