import { makeStyles } from '@material-ui/core';
import React from 'react';

import { getStatusColorByAvailabilityAndReservation } from '../common/util.common';
import { IsAvailablePropInterface } from '../interface/is-available-props.interface';

const useStyles = makeStyles({
  hourOClock: (props: { color: string }) => ({
    borderRadius: '100%',
    width: '10px',
    height: '10px',
    border: `2px solid ${props.color}`,
  }),
});

const HourOClockDotComponent: React.FC<IsAvailablePropInterface> = ({
  isAvailable,
  isReserved,
  style,
}: IsAvailablePropInterface) => {
  const color = getStatusColorByAvailabilityAndReservation(
    isAvailable || false,
    isReserved || false,
  );
  const classes = useStyles({
    color,
  });
  return <div className={classes.hourOClock} style={style} />;
};

export default HourOClockDotComponent;
