import * as React from 'react';

function VagasIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 384 384" {...props}>
      <path d="M292.84 140.645C289.867 132.09 281.747 126 272.25 126h-159.5c-9.497 0-17.617 6.09-20.59 14.645L62 227.5v116c0 7.975 6.525 14.5 14.5 14.5H91c8.047 0 14.499-6.525 14.499-14.5V329h174v14.499c0 7.975 6.525 14.5 14.5 14.5h14.499c8.048 0 14.5-6.525 14.5-14.5V227.5l-30.158-86.855zM112.75 285.5c-12.035 0-21.75-9.715-21.75-21.75S100.715 242 112.75 242s21.75 9.715 21.75 21.75-9.715 21.75-21.75 21.75zm159.499 0c-12.035 0-21.75-9.715-21.75-21.75s9.715-21.75 21.75-21.75 21.75 9.715 21.75 21.75-9.715 21.75-21.75 21.75zM91 213l21.75-65.25h159.5L294 213H91zM134.151 86.914l16.576 16.575c22.875-22.874 60.005-22.874 82.879 0l16.576-16.575c-32.032-32.033-83.998-32.033-116.031 0z" />
      <path d="M101 53.762l16.576 16.576c41.191-41.191 107.991-41.191 149.182 0l16.576-16.576c-50.349-50.35-131.985-50.35-182.334 0z" />
    </svg>
  );
}

export default VagasIcon;
