import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { formatMonthByMonthId } from '../common/util.common';
import HourOClockDotComponent from './hour-o-clock-dot.component';
import BrokenHourDotComponent from './broken-hour-dot.component';
import moment from 'moment';

enum DotPositionEnum {
  START = 'START',
  MIDDLE = 'MIDDLE',
  END = 'END',
}

const useStyles = makeStyles({
  root: {
    marginTop: 20,
    marginBottom: 20,
  },
  dateText: {
    lineHeight: 0.9,
    fontWeight: 'bold',
    color: '#3FB7F8',
  },
  monthText: {
    lineHeight: 0.9,
    fontWeight: 'bold',
    color: '#3FB7F8',
  },
  dotLineContainer: {
    paddingTop: 10,
  },
});

interface ParkingSpotBookingInterface {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  attachedText?: string;
}

interface ReserveSpotDatesProps {
  startDate: string;
  endDate: string;
  parkingSpotsBooking: ParkingSpotBookingInterface[];
}

interface ReserveItemComponentProps {
  text?: string;
  date: string;
  time: string;
  position: string;
}

const generateKey = (index: number): number => {
  return new Date().getTime() + index + Math.random() * 1000;
};
const dotHeight = 8;
const BrokenHourDotStyle = {
  height: dotHeight,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const ReserveDotsItemComponent: React.FC = () => (
  <Grid container direction="row">
    <Grid item xs={3} />
    <Grid item xs={1}>
      {[1, 2, 3, 4].map((item) => (
        <BrokenHourDotComponent
          key={item}
          isAvailable={false}
          isReserved={false}
          style={BrokenHourDotStyle}
        />
      ))}
    </Grid>
    <Grid item xs={8} />
  </Grid>
);

const ReserveItemComponent: React.FC<ReserveItemComponentProps> = ({
  date,
  position,
  time,
  text,
}: ReserveItemComponentProps) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Grid item xs={3}>
        <Typography variant="h6" align="center" className={classes.dateText}>
          {moment(date).format('DD')}
        </Typography>
        <Typography align="center" className={classes.monthText}>
          {formatMonthByMonthId(new Date(date + 'T00:00:00').getMonth())}
        </Typography>
      </Grid>
      <Grid item xs={1} container direction="column" alignItems="center">
        <>
          {position === DotPositionEnum.START ||
            [1, 2].map((item) => (
              <BrokenHourDotComponent
                key={item}
                isAvailable={false}
                isReserved={false}
                style={BrokenHourDotStyle}
              />
            ))}
          <HourOClockDotComponent
            isAvailable={false}
            isReserved={false}
            style={
              position === DotPositionEnum.START
                ? { marginTop: 2 * dotHeight }
                : position === DotPositionEnum.END
                ? { marginBottom: 2 * dotHeight }
                : {}
            }
          />
          {position === DotPositionEnum.END ||
            [1, 2].map((item) => (
              <BrokenHourDotComponent
                key={item}
                isAvailable={false}
                isReserved={false}
                style={BrokenHourDotStyle}
              />
            ))}
        </>
      </Grid>
      <Grid item xs={8} container>
        <Grid item xs={6} container alignItems="center">
          <Typography
            align="center"
            color="textSecondary"
            noWrap
            variant="subtitle2"
          >
            {time}
          </Typography>
        </Grid>
        <Grid item xs={6} container alignItems="center">
          <Typography align="center" color="textSecondary" variant="subtitle2">
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ReserveSpotDatesComponent: React.FC<ReserveSpotDatesProps> = (
  props: ReserveSpotDatesProps,
) => {
  const [stateSpots, setStateSpots] = useState<ParkingSpotBookingInterface[]>(
    [],
  );
  const classes = useStyles();

  useEffect(() => {
    setStateSpots(props.parkingSpotsBooking);
  }, []);

  return (
    <div>
      <Grid container className={classes.root}>
        {stateSpots?.map((spot, index) => (
          <Fragment key={generateKey(index)}>
            <ReserveItemComponent
              text={spot.attachedText}
              date={spot.startDate}
              time={spot.startTime}
              position={
                index === 0 ? DotPositionEnum.START : DotPositionEnum.MIDDLE
              }
            />
            <ReserveDotsItemComponent />
            <ReserveItemComponent
              text={
                index === stateSpots.length - 1
                  ? 'Fim da reserva'
                  : 'Troca de vaga'
              }
              date={spot.endDate}
              time={spot.endTime}
              position={
                index === stateSpots.length - 1
                  ? DotPositionEnum.END
                  : DotPositionEnum.MIDDLE
              }
            />
            {index === stateSpots.length - 1 || <ReserveDotsItemComponent />}
          </Fragment>
        ))}
      </Grid>
      <hr style={{ opacity: 0.2 }} />
    </div>
  );
};

export default ReserveSpotDatesComponent;
