import React, { useState } from 'react';
import {
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { subscribeUserToPushNotifications } from '../common/util.common';
import { createSubscription } from '../service/notification.service';
import { useAuthContext } from '../context/auth.context';
import { UserNotificationSubscriptionInterface } from '../interface/user-notification-subscription.interface';

const useStyles = makeStyles({
  background: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#589DFF',
    height: 70,
    width: '100%',
  },
  onAskingContainer: (props: { justifyContent?: string }) => ({
    color: '#FFF',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 10,
    justifyContent: props.justifyContent,
    alignItems: 'center',
    height: '100%',
    width: '100%',
  }),
  onCancelingContainer: {
    color: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  responsiveText: (props: { maxWidth?: number }) => ({
    ...props,
  }),
  underlineText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  icon: {
    color: '#FFF',
    position: 'fixed',
    right: 0,
    marginRight: 10,
    cursor: 'pointer',
  },
});

interface AskOrCancelingProps {
  classes: Record<
    | 'background'
    | 'icon'
    | 'onAskingContainer'
    | 'onCancelingContainer'
    | 'responsiveText'
    | 'underlineText',
    string
  >;
  askAndCreateSubscription: () => Promise<void>;
  setIsCanceling?: React.Dispatch<React.SetStateAction<boolean>>;
  setUserNotificationSubscription?: (
    userNotifcationSubscription: UserNotificationSubscriptionInterface,
  ) => void;
}

const IsCancelingPermissionComponent: React.FC<AskOrCancelingProps> = ({
  classes,
  askAndCreateSubscription,
  setUserNotificationSubscription,
}: AskOrCancelingProps) => (
  <div className={classes.onCancelingContainer}>
    <Typography align="center" className={classes.responsiveText}>
      É altamente recomendável ativar as notificalções
    </Typography>
    <div>
      <span
        className={classes.underlineText}
        onClick={askAndCreateSubscription}
      >
        Ativar notificações
      </span>
      &nbsp;&nbsp;
      <span
        className={classes.underlineText}
        onClick={(): void =>
          setUserNotificationSubscription({
            userId: null,
            subscriptionId: null,
            askForPermission: false,
          })
        }
      >
        Pergunte-me depois
      </span>
    </div>
  </div>
);

const IsAskingPermissionComponent: React.FC<AskOrCancelingProps> = ({
  classes,
  askAndCreateSubscription,
  setIsCanceling,
}: AskOrCancelingProps) => (
  <div className={classes.onAskingContainer}>
    <Typography className={classes.responsiveText}>
      Precisamos de sua permissão para {/*  */}
      <span
        className={classes.underlineText}
        onClick={askAndCreateSubscription}
      >
        exibir notificações úteis para você!
      </span>
    </Typography>
    <IconButton
      className={classes.icon}
      onClick={(): void => setIsCanceling(true)}
    >
      <CloseOutlinedIcon />
    </IconButton>
  </div>
);

const AskNotificationPermissionComponent: React.FC = () => {
  const [isCanceling, setIsCanceling] = useState<boolean>(false);
  const {
    token,
    authenticatedUser,
    setUserNotificationSubscription,
  } = useAuthContext();
  const isMobile = useMediaQuery('(max-width:570px)');
  const isSmallScreen = useMediaQuery('(max-width:370px)');
  const classes = useStyles(
    isMobile
      ? {
          maxWidth: 270,
          justifyContent: isSmallScreen ? 'start' : 'center',
        }
      : {},
  );

  const askAndCreateSubscription = async (): Promise<void> => {
    const subscription = await subscribeUserToPushNotifications();
    const subscriptionId = await createSubscription(token, subscription);
    setUserNotificationSubscription({
      askForPermission: false,
      userId: authenticatedUser.id,
      subscriptionId,
    });
  };

  return (
    <div className={classes.background}>
      {isCanceling ? (
        <IsCancelingPermissionComponent
          setUserNotificationSubscription={setUserNotificationSubscription}
          classes={classes}
          askAndCreateSubscription={askAndCreateSubscription}
        />
      ) : (
        <IsAskingPermissionComponent
          classes={classes}
          setIsCanceling={setIsCanceling}
          askAndCreateSubscription={askAndCreateSubscription}
        />
      )}
    </div>
  );
};

export default AskNotificationPermissionComponent;
