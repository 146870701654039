import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  createStyles,
  Container,
  Typography,
  Grid,
} from '@material-ui/core';
import RequestItemComponent from '../component/request-item.component';
import PaginationComponent from '../component/pagination.component';
import { UserRequestInterface } from '../interface/user-request.interface';
import { getUserRequests } from '../service/user-request.service';
import { useSnackbarContext } from '../context/snackbar.context';
import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { VagasIcon } from '../icons';

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      marginTop: '5%',
      marginBottom: '5%',
    },
    card: {
      marginBottom: '5%',
    },
    cardHeader: {
      background:
        'linear-gradient(90deg, rgba(27,70,255,1) 60%, rgba(90,190,252,1) 100%);',
      color: '#FFF',
    },

    userDataTitle: {
      fontWeight: 'bold',
    },
  }),
);
const AdministrationPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [userRequests, setUserRequests] = useState<UserRequestInterface[]>([]);
  const { token } = useAuthContext();
  const { displaySnack } = useSnackbarContext();
  const classes = useStyles();

  const { setMenuPageById, setHeaderLoading } = useMenuContext();
  useEffect(() => {
    setMenuPageById(MenuPageIdEnum.ADMIN);
  },[]);

  useEffect((): void => {
    async function getUserRequestList(): Promise<void> {
      try {
        setHeaderLoading(true);
        const res = await getUserRequests(currentPage, token);
        setUserRequests(res.userRequestList);
        setTotalRecords(res.totalRecords);
        setHeaderLoading(false);
      } catch (errorMessage) {
        displaySnack(errorMessage, 'error', true);
      }
    }
    getUserRequestList();
    calculateTotalPages();
  }, [token, currentPage]);

  const calculateTotalPages = (): void => {
    if (totalRecords / 10 >= 1) setTotalPages(totalPages + 1);
  };
  const reloadPage = async (): Promise<void> => {
    setHeaderLoading(true);
    try {
      const res = await getUserRequests(currentPage, token);
      setUserRequests(res.userRequestList);
      setTotalRecords(res.totalRecords);
    } catch (errorMessage) {
      displaySnack(errorMessage, 'error', true);
    }
    setHeaderLoading(false);
  };

  const nextPage = (): void => {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
      setHeaderLoading(true);
    }
  };

  const previousPage = (): void => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setHeaderLoading(false);
    }
  };

  return (
    <>
      <Container maxWidth="md">
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          nextPageFuntion={nextPage}
          previousPageFuntion={previousPage}
        />
        <Typography
          align="center"
          paragraph
          variant="subtitle1"
          color="textSecondary"
        >
          Registros encontrados: {userRequests.length === 0 ? 0 : totalRecords}
        </Typography>
        <>
          {userRequests.map((user) => (
            <React.Fragment key={user.id}>
              <Card className={classes.card}>
                <CardHeader
                  className={classes.cardHeader}
                  title={`Residente ${user.name}`}
                />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography className={classes.userDataTitle}>
                        Email:
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography>{user.email}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography className={classes.userDataTitle}>
                        Telefone:
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography>{user.telephone}</Typography>
                    </Grid>
                  </Grid>
                  {!!user.cpf && (
                    <Grid container spacing={1}>
                      <Grid item xs>
                        <Typography className={classes.userDataTitle}>
                          CPF:
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography>{user.cpf}</Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography className={classes.userDataTitle}>
                        Nascimento:
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography>
                        {new Date(user.birthday).toLocaleDateString('pt')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography className={classes.userDataTitle}>
                        Condomínios:
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography>
                        {user.condominiums.map(
                          (condo, index: number) =>
                            `${condo.name}${
                              index === user.condominiums?.length - 1
                                ? '.'
                                : ', '
                            }`,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography className={classes.userDataTitle}>
                        Número de requisições:
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography>{user.userRequests.length}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                {user.userRequests.map((request, index) => (
                  <RequestItemComponent
                    key={index}
                    request={request}
                    userId={user.id}
                    reloadPage={reloadPage}
                  />
                ))}
              </Card>
            </React.Fragment>
          ))}
        </>

        {!userRequests.length ? (
          <>
            <br />
            <Grid container justify="center">
              <VagasIcon fill="lightgray" fontSize={125} />
            </Grid>
          </>
        ) : (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            nextPageFuntion={nextPage}
            previousPageFuntion={previousPage}
          />
        )}
      </Container>
    </>
  );
};

export default AdministrationPage;
