export function setLocalStorage<T>(key: string, value: T): void {
  let stringValue = '';
  switch (typeof value) {
    case 'object':
      stringValue = JSON.stringify(value);
      break;
    case 'boolean':
    case 'number':
    case 'string':
      stringValue = `${value}`;
  }
  localStorage.setItem(key, stringValue);
}

export function throwContextError(businessContext: string): void {
  throw new Error(
    `use${businessContext}Context deve ser utilizando dentro do ${businessContext}Provider`,
  );
}

export const detectDayOfWeekShortString = (day: number): string => {
  switch (day) {
    case 1:
      return 'S';
    case 2:
      return 'T';
    case 3:
      return 'Q';
    case 4:
      return 'Q';
    case 5:
      return 'S';
    case 6:
      return 'S';
    case 0:
      return 'D';
    default:
      return '?';
  }
};

export const detectDayOfWeekLongString = (day: number): string => {
  switch (day) {
    case 1:
      return 'Seg';
    case 2:
      return 'Ter';
    case 3:
      return 'Qua';
    case 4:
      return 'Qui';
    case 5:
      return 'Sex';
    case 6:
      return 'Sab';
    case 0:
      return 'Dom';
    default:
      return String(day);
  }
};

export const detectDayOfWeekFullString = (
  day: number,
  includeSecondWord?: boolean,
): string => {
  const includeExpression = includeSecondWord ? '-feira' : '';
  switch (day) {
    case 1:
      return `Segunda${includeExpression}`;
    case 2:
      return `Terça${includeExpression}`;
    case 3:
      return `Quarta${includeExpression}`;
    case 4:
      return `Quinta${includeExpression}`;
    case 5:
      return `Sexta${includeExpression}`;
    case 6:
      return `Sábado`;
    case 0:
      return `Domingo`;
    default:
      return String(day);
  }
};

export const formatMonthByMonthId = (monthId: number): string => {
  const mounthsArray = [
    'JAN',
    'FEV',
    'MAR',
    'ABR',
    'MAI',
    'JUN',
    'JUL',
    'AGO',
    'SET',
    'OUT',
    'NOV',
    'DEZ',
  ];
  return mounthsArray[monthId];
};

export function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const isPushNotificationSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window

export async function subscribeUserToPushNotifications(): Promise<
  PushSubscription
> {
  if (!('PushManager' in window)) return;

  let permissionResult:any = null

  if(isPushNotificationSupported()){
    permissionResult = await Notification.requestPermission();
  }
  

  if (permissionResult !== 'granted') return;

  const registration = await navigator.serviceWorker.ready;

  const { REACT_APP_PUBLIC_VAPID_KEY } = process.env;

  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(REACT_APP_PUBLIC_VAPID_KEY),
  });
  return subscription;
}

export function getStatusColorByAvailabilityAndReservation(
  isAvailable: boolean,
  isReserved: boolean,
): string {
  switch (isAvailable) {
    case false:
      if (isReserved) return '#1B46FF';
      else return '#9E9E9E';
    case true:
      if (isReserved) return '#1B46FF';
      else return '#6ABEFF';
  }
}

// eslint-disable-next-line complexity
function cpfNumberEqual(cpf: string): boolean {
  if (
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  )
    return true;
}

export function validateCPF(cpf: string): boolean {
  let sum;
  let rest;
  sum = 0;

  const cleanCPF = String(cpf).replace(/\.|-|\s/g, '');

  const isEqual = cpfNumberEqual(cleanCPF);
  if (!!isEqual) return false;

  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(cleanCPF.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(cleanCPF.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(cleanCPF.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;

  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(cleanCPF.substring(10, 11))) return false;
  return true;
}

export const firstStartTime = '00:00';
export const firstEndTime = '00:15';
export const lastStartTime = '23:45';
export const lastEndTime = '00:00';

export function getTheNextStartTimeAt15MinuteInterval(time: string): string {
  if (!time || time.length !== 5) return;
  if (firstStartTime === time) return firstStartTime;
  const minutesInterval = ['00', '15', '30', '45'];
  let hour = time.substr(0, 2);
  let minute = time.substr(3);
  if (!hour || !minute) return;
  if (hour === '23' && minute > '45') return;

  if (minutesInterval.includes(minute)) return time;

  if (minute > '45') {
    hour = String(Number(hour) + 1);
    minute = '00';
    return `${hour}:${minute}`;
  }

  minute = minutesInterval.find((interval) => interval > minute);

  return `${hour}:${minute}`;
}

export function sortByName ( a:any, b:any ) {
  if ( a.name < b.name ){
    return -1;
  }
  if ( a.name > b.name ){
    return 1;
  }
  return 0;
}

export function sortByCode ( a:any, b:any ) {
  if ( a.code < b.code ){
    return -1;
  }
  if ( a.code > b.code ){
    return 1;
  }
  return 0;
}

export function sortByGate ( a:any, b:any ) {
  if ( a.gate < b.gate ){
    return -1;
  }
  if ( a.gate > b.gate ){
    return 1;
  }
  return 0;
}

export function sortByBlock ( a:any, b:any ) {
  if ( a.block < b.block ){
    return -1;
  }
  if ( a.block > b.block ){
    return 1;
  }
  return 0;
}

export function sortByApartment ( a:any, b:any ) {
  if ( a.apartment < b.apartment ){
    return -1;
  }
  if ( a.apartment > b.apartment ){
    return 1;
  }
  return 0;
}
