import React, { useState } from 'react';
import {
  Button,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { AddBonusModalComponent } from './add-bonus-modal.component';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { useMenuContext } from '../context/menu.context';
import { AddCreditModalComponent } from '../component/add-credit-modal.component';

interface BalanceDashboardProps {
  balance: number;
  bonusBalance: number;
  totalAmount: number;
  isAdmin: boolean;
  walletId: number;
  userId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    root: {
      color: 'white',
    },
    dashboardBackground: {
      padding: 35,
      backgroundColor: '#5abefc',
      WebkitBoxShadow: '2px 2px 10px rgba(0,0,0,0.4)',
      MozBoxShadow: '2px 2px 10px rgba(0,0,0,0.4)',
    },
    buttonsBackground: {
      WebkitBoxShadow: '2px 2px 10px rgba(0,0,0,0.4)',
      MozBoxShadow: '2px 2px 10px rgba(0,0,0,0.4)',
    },
    link: {
      textDecoration: 'none',
    },
    button: (props: { isAdmin: boolean }) => ({
      color: '#5abefc',
      width: props.isAdmin ? '100%' : '50%',
      height: 70,
    }),
  }),
);

const ResidentButtonsComponent: React.FC = () => {
  const classes = useStyles({ isAdmin: false });
  const [openAddCreditModal, setOpenAddCreditModal] = useState(false);
  const { goToPageRouteById } = useMenuContext();
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.buttonsBackground}
    >
      <Button
        className={classes.button}
        onClick={(): void =>
          goToPageRouteById(MenuPageIdEnum.WITHDRAWAL_REQUEST)
        }
      >
        Realizar Saque
      </Button>
      <Button
        onClick={(): void => setOpenAddCreditModal(true)}
        className={classes.button}
      >
        Adicionar Créditos
      </Button>
      <AddCreditModalComponent
        open={openAddCreditModal}
        onConfirm={(): void => setOpenAddCreditModal(false)}
        onClose={(): void => setOpenAddCreditModal(false)}
      />
    </Grid>
  );
};

const AdminButtonsComponent: React.FC = () => {
  const classes = useStyles({ isAdmin: true });
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.buttonsBackground}
    >
      <Button className={classes.button}>Adicionar Bônus</Button>
    </Grid>
  );
};

const BalanceDashboardComponent: React.FC<BalanceDashboardProps> = ({
  balance,
  bonusBalance,
  isAdmin,
  walletId,
  userId,
}: BalanceDashboardProps) => {
  const classes = useStyles({ isAdmin });
  const [openAddBonusModal, setOpenAddBonusModal] = useState(false);
  const { isHeaderLoading } = useMenuContext();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.dashboardBackground}
      >
        <Typography
          style={isHeaderLoading ? { filter: 'blur(5px)' } : null}
          variant="h4"
          align="center"
        >
          {Number(balance)?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
        {bonusBalance > 0 ? (
          <Typography
            style={isHeaderLoading ? { filter: 'blur(5px)' } : null}
            variant="caption"
            align="center"
          >
            {!isAdmin ? 'Você tem ' : 'Este usuário possui '}
            {Number(bonusBalance).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}{' '}
            promocionais para reservar vagas
          </Typography>
        ) : null}
      </Grid>
      {isAdmin ? (
        <Link
          className={classes.link}
          href="#"
          onClick={(): void => setOpenAddBonusModal(true)}
        >
          <AdminButtonsComponent />
        </Link>
      ) : (
        <ResidentButtonsComponent />
      )}
      <AddBonusModalComponent
        walletId={walletId}
        userId={userId}
        open={openAddBonusModal}
        onConfirm={(): void => setOpenAddBonusModal(false)}
        onClose={(): void => setOpenAddBonusModal(false)}
      />
    </div>
  );
};

export default BalanceDashboardComponent;
