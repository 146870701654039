import React from 'react';
import {
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { VagasIcon } from '../icons';
import { useMenuContext } from '../context/menu.context';

const useStyles = makeStyles({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  modalToolbar: {
    alignSelf: 'flex-end',
    marginTop: '1%',
  },
  modalToolbarIconColor: {
    color: '#fff',
  },
  modalFloorPlanContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalFloorPlan: {
    width: '100%',
    maxWidth: '900px',
  },
  floorPlanText: {
    color: 'grey',
    fontSize: 15,
    textAlign: 'center',
  },
  floorPlanImageContainer: {
    padding: '2%',
  },
});

interface CondominiumFloorPlanModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  condominiumParam: any;
}

const CondominiumFloorPlanModalComponent: React.FC<CondominiumFloorPlanModalProps> = ({
  open,
  setOpen,
  condominiumParam,
}: CondominiumFloorPlanModalProps) => {
  const classes = useStyles();

  let { condominium } = useMenuContext();

  if(condominiumParam){
     condominium = condominiumParam;
  } 
  
  return open ? (
    <Modal
      open={open}
      onClose={(): void => setOpen(false)}
      className={classes.modalContainer}
    >
      <>
        <Toolbar className={classes.modalToolbar}>
          <IconButton
            className={classes.modalToolbarIconColor}
            onClick={(): void => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Toolbar>
        {condominium?.floorPlanUrl ? (
          <div className={classes.modalFloorPlanContainer}>
            <img
              className={classes.modalFloorPlan}
              src={condominium.floorPlanUrl}
              alt="condominium-floor-plan"
            />
          </div>
        ) : (
          <Grid container alignItems="center" justify="center">
            <VagasIcon fill="lightgray" fontSize={125} />
            <Typography className={classes.floorPlanText}>
              Não foi possível carregar imagem
            </Typography>
          </Grid>
        )}
      </>
    </Modal>
  ) : (
    <div className={classes.floorPlanImageContainer}>
      <img
        className={classes.modalFloorPlan}
        src={condominium.floorPlanUrl}
        alt="condominium-floor-plan"
        onClick={(): void => setOpen(true)}
      />
    </div>
  );
};

export default CondominiumFloorPlanModalComponent;
