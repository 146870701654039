import React, { useCallback, useEffect, useState } from 'react';
import {
  makeStyles,
  createStyles,
  Container,
  Button,
  MenuItem,
  FormControl,
  Select,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import PaginationComponent from '../component/pagination.component';
import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { useForm, Controller } from 'react-hook-form';
import { getCondominiums } from '../service/condominium.service';
import { CondominiumInterface } from '../interface/condominium.interface';
import { FilterTypeEnum } from '../enum/filter-type.enum';
import { UsersFilterOptionInterface } from '../interface/users-filter-option.interface';
import { getUsersPaged } from '../service/user.service';
import { useSnackbarContext } from '../context/snackbar.context';
import UserPreviewComponent from '../component/user-preview.component';

interface SubmitFormValues {
  condominiumId: number;
  inputFilter: string;
  filterOptionId: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      position: 'relative',
      padding: '10px 4px 10px 4px',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '100%',
    },
    buttonDiv: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '5%',
      justifyContent: 'center',
    },
    buttonSubmit: {
      color: '#6abeff',
      marginTop: '2em',
      justifyItems: 'center',
    },
    textField: {
      marginTop: '1em',
    },
    selectField: {
      marginTop: '2em',
    },
  }),
);

const schema = yup.object().shape({
  inputFilter: yup.string(),
});

export const UsersPage = (): JSX.Element => {
  const [condominiumDefault] = useState<CondominiumInterface>({
      id: 0,
      name: 'Todos os condomínios',
    }),
    [condominiums, setCondominiums] = useState<CondominiumInterface[]>([
      condominiumDefault,
    ]),
    [filterOptions] = useState<UsersFilterOptionInterface[]>([
      { id: 0, name: 'Email', type: FilterTypeEnum.EMAIL },
      { id: 1, name: 'Nome', type: FilterTypeEnum.NAME },
    ]),
    [filterOptionSelected, setFilterOptionSelected] = useState<
      UsersFilterOptionInterface
    >(filterOptions[0]),
    [currentPage, setCurrentPage] = useState<number>(1),
    [totalPages, setTotalPages] = useState<number>(1),
    classes = useStyles(),
    { token, setLastUsersSearched, lastUsersSearched } = useAuthContext(),
    { displaySnack } = useSnackbarContext(),
    { setMenuPageById, setHeaderLoading } = useMenuContext(),
    useFormMethods = useForm({
      resolver: yupResolver(schema),
      mode: 'all',
      shouldFocusError: true,
    }),
    {
      watch,
      handleSubmit,
      control,
      errors,
      getValues,
      register,
    } = useFormMethods,
    filterOptionChanged = watch('filterOptionId'),
    condominiumChanged = watch('condominiumId', condominiumDefault?.id);

  useEffect(() => {
    setMenuPageById(MenuPageIdEnum.USERS);
    getCondominiumsForSelect();
  }, []);

  useEffect(() => {
    getUsers(currentPage);
  }, [condominiumChanged, currentPage]);

  useEffect(() => {
    if (filterOptionChanged !== filterOptionSelected?.id) {
      const filter = filterOptions.find(
        (filter) => filter?.id === filterOptionChanged,
      );
      setFilterOptionSelected(filter);
    }
  }, [filterOptionChanged]);

  const getCondominiumsForSelect = useCallback(async (): Promise<void> => {
    const condominiumsOptions = await getCondominiums(token);
    setCondominiums([...condominiums, ...condominiumsOptions]);
  }, [token]);

  const getUsers = useCallback(async (changedCurrentPage?: number): Promise<
    void
  > => {
    setHeaderLoading(true);
    try {
      const { count, rows, limit } = await getUsersPaged(
        token,
        changedCurrentPage ? changedCurrentPage : currentPage,
        getValues('filterOptionId'),
        getValues('inputFilter'),
        getValues('condominiumId'),
      );
      setLastUsersSearched(rows);
      if (count) {
        const totalPages = Math.ceil(count / limit);
        setTotalPages(totalPages);
      }
    } catch (error) {
      displaySnack(`${error}`, 'error');
    } finally {
      setHeaderLoading(false);
    }
  }, []);

  const nextPage = (): void => {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const backPage = (): void => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const submit = async (): Promise<void> => {
    getUsers();
  };

  return (
    <>
      <Container maxWidth="md">
        <form className={classes.form} onSubmit={handleSubmit(submit)}>
          <FormControl fullWidth>
            <Controller
              as={
                <Select
                  className={classes.selectField}
                  defaultValue={filterOptionSelected?.id}
                  labelId="filter-select-label"
                  id="filter-select"
                >
                  {filterOptions.map((filterOption) => (
                    <MenuItem key={filterOption.id} value={filterOption.id}>
                      {filterOption.name}
                    </MenuItem>
                  ))}
                </Select>
              }
              name={'filterOptionId'}
              control={control}
              defaultValue={filterOptionSelected?.id}
            />
            <FormHelperText>{errors?.['filterOption']?.message}</FormHelperText>
          </FormControl>

          {filterOptionSelected?.type === FilterTypeEnum.EMAIL && (
            <TextField
              className={classes.textField}
              label={'Email'}
              fullWidth
              name={'inputFilter'}
              error={!!errors.inputFilter}
              helperText={errors?.inputFilter?.message}
              inputRef={register}
            />
          )}

          {filterOptionSelected?.type === FilterTypeEnum.NAME && (
            <TextField
              className={classes.textField}
              label={'Nome'}
              fullWidth
              name={'inputFilter'}
              error={!!errors.inputFilter}
              helperText={errors?.inputFilter?.message}
              inputRef={register}
            />
          )}

          <FormControl fullWidth>
            <Controller
              as={
                <Select
                  className={classes.selectField}
                  defaultValue={condominiumDefault?.id}
                  labelId="condominium-select-label"
                  id="condominium-select"
                >
                  {condominiums.map((condominium) => (
                    <MenuItem key={condominium.id} value={condominium.id}>
                      {condominium.name}
                    </MenuItem>
                  ))}
                </Select>
              }
              name={'condominiumId'}
              control={control}
              defaultValue={condominiumDefault?.id}
            />
            <FormHelperText>{errors?.['condominium']?.message}</FormHelperText>
          </FormControl>

          <Button type="submit" className={classes.buttonSubmit}>
            Pesquisar
          </Button>
        </form>

        <PaginationComponent
          nextPageFuntion={nextPage}
          previousPageFuntion={backPage}
          totalPages={totalPages}
          currentPage={currentPage}
        />
        {lastUsersSearched.map((userSearched) => (
          <UserPreviewComponent key={userSearched?.id} user={userSearched} />
        ))}
      </Container>
    </>
  );
};
