import React, { useEffect, Fragment, useState } from 'react';
import { useMenuContext } from '../context/menu.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { useAuthContext } from '../context/auth.context';
import { ContainerComponent } from '../component/container.component';
import { SpeedButtonComponent } from '../component/speed-button.component';
import {
  SpeedButtonActionInterface,
  SpeedButtonPropsInterface,
} from '../interface/speed-button.interface';
import { 
  getUserApartmentRequests,
  getUserApartmentRequestsList,
} from '../service/user.service';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { useSnackbarContext } from '../context/snackbar.context';

import { Typography, createStyles, makeStyles, Grid, Card, CardContent, CardHeader, useMediaQuery } from '@material-ui/core';
import { VagasIcon } from '../icons';

import { sortByName } from '../common/util.common';

interface CondominiumProp {
  name: string;
  address: string;
  classes: Record<
    'condominiumName' | 'condominiumAddress' | 'apartmentList',
    string
  >;
}

const CondominiumComponent: React.FC<CondominiumProp> = (condominiumProp) => {
  return (
    <Fragment>
      <Typography
        className={condominiumProp.classes.condominiumName}
        variant="h6"
        component="h1"
      >
        Condomínio {condominiumProp.name}
      </Typography>
      <Typography
        className={condominiumProp.classes.condominiumAddress}
        variant="body2"
        color="textSecondary"
      >
        {condominiumProp.address}
      </Typography>
    </Fragment>
  );
};

interface ApartmentProp {
  apartment: string;
}

const ApartmentComponent: React.FC<ApartmentProp> = (apartmentProp) => {
  return (
    <li>
      <Typography variant="body2">
        Apartamento {apartmentProp.apartment}
      </Typography>
    </li>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    condominiumName: {
      marginTop: '5%',
    },
    condominiumAddress: {
      marginBottom: '2%',
    },
    apartmentList: {
      margin: 0,
    },
    logo: {
      display: 'block',
      marginTop: '10%',
      marginBottom: '15px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    description: {
      color: 'grey',
      fontSize: 15,
      textAlign: 'center',
    },
    card: {
      marginTop: '4%',
      marginBottom: '4%',
    },
    cardHeader: {
      background:
        'linear-gradient(90deg, rgba(27,70,255,1) 60%, rgba(90,190,252,1) 100%);',
      color: '#FFF',
    },
    analyzeText: {
      width: '100%',
      padding: 5,
      borderRadius: 4,
      border: '1px solid #1b46ff',
      color: '#1b46ff',
    },
  }),
);

const MyCondominiumsPage: React.FC = () => {
  const classes = useStyles();
  const { setMenuPageById } = useMenuContext();
  const { token,authenticatedUser } = useAuthContext();
  const { apartments, condominiums } = authenticatedUser;
  const [ apartmentForApprobation, setApartmentForApprobation] = useState([]);
  const floatingButtonActions: SpeedButtonActionInterface[] = [
      {
        name: 'Adicionar condomínio',
        icon: <AddCircleIcon />,
        menuPageId: MenuPageIdEnum.USER_REQUEST_APARTMENT,
      },
    ],
    floatingButtonProps: SpeedButtonPropsInterface = {
      icon: <SpeedDialIcon />,
      actions: floatingButtonActions,
    };
  const [userHasAnApartmentRequest, setUserHasAnApartmentRequest] = useState<boolean>(true);
  const { displaySnack } = useSnackbarContext();
  const {
    condominium,
    setHeaderLoading,
  } = useMenuContext();
  const minAnalyzeWidth = useMediaQuery('(min-width:450px)');

  async function getForApprobationSpots(): Promise<void> {
    try {
      const userHasAnApartmentRequest = await getUserApartmentRequests(
        authenticatedUser.id,
        token,
      );
      setUserHasAnApartmentRequest(userHasAnApartmentRequest);

      const apartmentForApprobation = await getUserApartmentRequestsList(
        authenticatedUser.id,
        token,
      );

      setApartmentForApprobation(apartmentForApprobation);
      setHeaderLoading(false);
    } catch (errorMessage) {
      displaySnack(errorMessage, 'error', true);
      setHeaderLoading(false);
    }
  }

  useEffect(() => { setMenuPageById(MenuPageIdEnum.CONDOMINIUMS); },[]);
  useEffect((): void => {
    condominiums.sort(function (a, b) {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;

      return 0;
    });
    
    getForApprobationSpots();
  },[]);
  
  return (
    <ContainerComponent>
      <>
        {!condominium && !condominiums.length && !userHasAnApartmentRequest ? (
        <>
          <br />
          <div>
            <VagasIcon
              className={classes.logo}
              fill="lightgray"
              fontSize={125}
            />
            <Typography className={classes.description}>
              Você ainda não está vinculado a nenhum condomínio
            </Typography>
          </div>
        </>
        ) : (
        <>
          {condominiums.sort(sortByName).map((condo) => (
            <Fragment key={condo.id}>
              <CondominiumComponent
                classes={classes}
                name={condo.name}
                address={condo.address}
              />
              {apartments.length
                ? apartments.map((apartment) =>
                    apartment.condominiumId === condo.id ? (
                      <ul
                        className={classes.apartmentList}
                        key={apartment.apartmentId}
                      >
                        <ApartmentComponent apartment={apartment.apartment} />
                      </ul>
                    ) : null,
                  )
                : null}
            </Fragment>
          ))}
          
          {apartmentForApprobation.map((approbationApartment) => (
            <Card key={approbationApartment.id} className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                title={approbationApartment.condominium.name}
              />
              <CardContent>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={minAnalyzeWidth ? 0 : 2}
                >
                  <Grid item xs={minAnalyzeWidth ? 8 : 12}>
                    <Typography color="textSecondary" variant="body1">
                      Apartamento: {approbationApartment.apartment.apartment}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      Andar: {approbationApartment.apartment.floor}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      Bloco: {approbationApartment.apartment.block}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={minAnalyzeWidth ? 4 : 12}
                    alignItems="center"
                  >
                    <Typography
                      align="center"
                      className={classes.analyzeText}
                      color="primary"
                      variant="button"
                    >
                      Em análise
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        </>
        )}
        <SpeedButtonComponent {...floatingButtonProps} />
      </>
    </ContainerComponent>
  );
};

export default MyCondominiumsPage;
