import React, { useEffect } from 'react';
import { NotificationComponent } from '../component/notification.component';
import { ContainerComponent } from '../component/container.component';
import { useMenuContext } from '../context/menu.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';

export function NotificationsPage(): JSX.Element {
  const { setMenuPageById } = useMenuContext();

  useEffect(() => { setMenuPageById(MenuPageIdEnum.NOTIFICATIONS); },[]);

  return (
    <ContainerComponent>
      <NotificationComponent itsAtHomePage={false} />
    </ContainerComponent>
  );
}
