import React, { useState, useEffect } from 'react';
import {
  useMediaQuery,
  Typography,
  Card,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  Button,
  CardHeader,
  IconButton,
  CardContent,
  Grid,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import { ContainerComponent } from '../component/container.component';
import {
  detectDayOfWeekLongString,
  detectDayOfWeekShortString,
} from '../common/util.common';
import AvailabilityPoint from '../component/availability-point.component';
import { ParkingSpotWeekAvailabilityInterface } from '../interface/parking-spot-week-availability.interface';
import { ParkingSpotsWithPendingApprobationInterface } from '../interface/parking-spot-with-pending-approbation.interface';
import {
  getMyParkingSpotsWithWeekAvailability,
  getParkingSpotsForApprobation,
} from '../service/parking-spot.service';
import { getUserApartmentRequests } from '../service/user.service';
import { useAuthContext } from '../context/auth.context';
import { useSnackbarContext } from '../context/snackbar.context';
import { useMenuContext } from '../context/menu.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { SpeedButtonComponent } from '../component/speed-button.component';
import {
  SpeedButtonActionInterface,
  SpeedButtonPropsInterface,
} from '../interface/speed-button.interface';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { SpeedDialIcon } from '@material-ui/lab';
import { GarageIcon } from '../icons';

type DayOfWeekStringProp = {
  day: number;
  matches: boolean;
};
const DayOfWeekString: React.FC<DayOfWeekStringProp> = ({ matches, day }) => {
  if (matches)
    return (
      <Typography color="textSecondary" variant="body2" align="center">
        {detectDayOfWeekLongString(day)}
      </Typography>
    );
  else
    return (
      <Typography color="textSecondary" variant="body2" align="center">
        {detectDayOfWeekShortString(day)}
      </Typography>
    );
};

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      marginTop: '4%',
      marginBottom: '4%',
    },
    garage: {
      display: 'block',
      marginTop: '15%',
      marginBottom: '15px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    description: {
      color: 'grey',
      fontSize: 18,
      textAlign: 'center',
    },
    cardHeader: {
      background:
        'linear-gradient(90deg, rgba(27,70,255,1) 60%, rgba(90,190,252,1) 100%);',
      color: '#FFF',
    },
    cardHeaderIcon: {
      color: '#FFF',
    },
    weekContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: 10,
    },
    dayContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    analyzeText: {
      width: '100%',
      padding: 5,
      borderRadius: 4,
      border: '1px solid #1b46ff',
      color: '#1b46ff',
    },
    buttonAdd: {
      padding: '9%',
      color: '#6abeff',
    },
    icon: {
      fontSize: 50,
    },
    dialogButton: {
      margin: '20px',
    },
  }),
);

const MySpotsPage: React.FC = () => {
  const [parkingSpots, setParkingSpots] = useState<
    ParkingSpotWeekAvailabilityInterface[]
  >([]);
  const [spotsForApprobation, setSpotsForApprobation] = useState<
    ParkingSpotsWithPendingApprobationInterface[]
  >([]);
  const { token, authenticatedUser } = useAuthContext();
  const { displaySnack } = useSnackbarContext();
  const {
    setMenuPageById,
    goToPageRouteById,
    condominium,
    setHeaderLoading,
  } = useMenuContext();

  const [userHasAnApartmentRequest, setUserHasAnApartmentRequest] = useState<boolean>(true);
  const minWeekWidth = useMediaQuery('(min-width:400px)');
  const minAnalyzeWidth = useMediaQuery('(min-width:450px)');
  const classes = useStyles();

  const floatingButtonActions: SpeedButtonActionInterface[] = [
      {
        name: 'Adicionar vaga',
        icon: <AddCircleIcon />,
        menuPageId: MenuPageIdEnum.USER_REQUEST_PARKING_SPOT,
      },
    ],
    floatingButtonProps: SpeedButtonPropsInterface = {
      icon: <SpeedDialIcon />,
      actions: floatingButtonActions,
    };

  useEffect(() => { setMenuPageById(MenuPageIdEnum.MY_SPOTS); },[]);

  useEffect((): void => {
    setHeaderLoading(true);
    async function getApprovedParkingSpots(): Promise<void> {
      try {
        if (condominium) {
          const approvedSpots = await getMyParkingSpotsWithWeekAvailability(
            condominium.id,
            token,
          );
          setParkingSpots(approvedSpots);
          setHeaderLoading(false);
        } else {
          setParkingSpots([]);
          setHeaderLoading(false);
        }
      } catch (errorMessage) {
        displaySnack(errorMessage, 'error', true);
        setHeaderLoading(false);
      }
    }
    async function getForApprobationSpots(): Promise<void> {
      try {
        const userHasAnApartmentRequest = await getUserApartmentRequests(
          authenticatedUser.id,
          token,
        );
        setUserHasAnApartmentRequest(userHasAnApartmentRequest);
        if (condominium) {
          const approbationSpots = await getParkingSpotsForApprobation(
            condominium.id,
            token,
          );
          setSpotsForApprobation(approbationSpots);
          setHeaderLoading(false);
        } else {
          const approbationSpots = await getParkingSpotsForApprobation(
            0,
            token,
          );
          setSpotsForApprobation(approbationSpots);
          setHeaderLoading(false);
        }
      } catch (errorMessage) {
        displaySnack(errorMessage, 'error', true);
        setHeaderLoading(false);
      }
    }
    getApprovedParkingSpots();
    getForApprobationSpots();
  }, [displaySnack, token, condominium]);

  const createDateObjet = (date: string): Date => {
    const splitDate = date.split('-');
    const year = parseInt(splitDate[0]);
    const month = parseInt(splitDate[1]);
    const day = parseInt(splitDate[2]);
    const finalDate = new Date(year, month - 1, day);
    return finalDate;
  };
  const dialogBody = (
    <>
      <DialogTitle>{'Olá!'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Infelizmente você ainda não possui um vínculo com um condomínio, você
          deve solicitar o seu vínculo para poder acessar essa página.
        </DialogContentText>
      </DialogContent>
      <Button
        onClick={(): void =>
          goToPageRouteById(MenuPageIdEnum.USER_REQUEST_APARTMENT)
        }
        color="primary"
        autoFocus
        className={classes.dialogButton}
      >
        Ok
      </Button>
    </>
  );

  return (
    <>
      {!condominium && !userHasAnApartmentRequest ? (
        <Dialog open={true}>{dialogBody}</Dialog>
      ) : null}
      <ContainerComponent>
        {parkingSpots.length !== 0 || spotsForApprobation.length !== 0 ? (
          <>
            {parkingSpots.map((spot) => (
              <Card key={spot.id} className={classes.card} elevation={5}>
                <CardHeader
                  className={classes.cardHeader}
                  action={
                    <IconButton
                      onClick={(): void =>
                        goToPageRouteById(
                          MenuPageIdEnum.MANAGE_AVAILABILITY,
                          spot.id,
                        )
                      }
                    >
                      <ArrowForwardIcon className={classes.cardHeaderIcon} />
                    </IconButton>
                  }
                  title={spot.code}
                />
                <CardContent>
                  <Grid container justify="center" alignItems="center">
                    <Grid item xs={12}>
                      <Typography color="textSecondary" variant="body1">
                        {spot.gate}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {
                          authenticatedUser.condominiums.find(
                            (condo) => condo.id === spot.condominiumId,
                          ).address
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.weekContainer}>
                    {spot.weekdays.map((day, index) => (
                      <div key={index} className={classes.dayContainer}>
                        <DayOfWeekString
                          matches={minWeekWidth}
                          day={createDateObjet(String(day.date)).getDay()}
                        />
                        <AvailabilityPoint status={day.status} />
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            ))}
            {spotsForApprobation.map((approbationSpot) => (
              <Card key={approbationSpot.id} className={classes.card}>
                <CardHeader
                  className={classes.cardHeader}
                  title={`${approbationSpot.code} - ${approbationSpot.condominium.name}`}
                />
                <CardContent>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={minAnalyzeWidth ? 0 : 2}
                  >
                    <Grid item xs={minAnalyzeWidth ? 8 : 12}>
                      <Typography color="textSecondary" variant="body1">
                        {approbationSpot.gate}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {approbationSpot.condominium.address}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={minAnalyzeWidth ? 4 : 12}
                      alignItems="center"
                    >
                      <Typography
                        align="center"
                        className={classes.analyzeText}
                        color="primary"
                        variant="button"
                      >
                        Em análise
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}

            <SpeedButtonComponent {...floatingButtonProps} />
          </>
        ) : (
          <div>
            <GarageIcon
              fill="lightgrey"
              fontSize={125}
              className={classes.garage}
            />
            <Typography className={classes.description}>
              Você não está vinculado a nenhuma vaga
            </Typography>
            <SpeedButtonComponent {...floatingButtonProps} />
          </div>
        )}
      </ContainerComponent>
    </>
  );
};
export default MySpotsPage;
