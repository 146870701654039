import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Button, Box, makeStyles, useMediaQuery } from '@material-ui/core';

import {
  getParkingSpots,
  getParkingSpotGates,
  getCondominiums,
} from '../service/condominium.service';
import {
  createUserRequestParkingSpot,
  deleteUserRequestFile,
  uploadUserRequestFile,
  confirmUserRequestParkingSpot,
} from '../service/user-request.service';

import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { useSnackbarContext } from '../context/snackbar.context';

import { cpfRegExp } from '../common/regex.common';

import { cpfMock } from '../mock/auth.mock';

import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { RequestTypeEnum } from '../enum/request-type.enum';
import { AvailableFor } from '../enum/available-for.enum';

import { ContainerComponent } from '../component/container.component';
import { DynamicSelectComponent } from '../component/dynamic-select.component';
import { FormOwnerUserDataComponent } from '../component/form-owner-user-data.component';
import UploadFileComponent from '../component/upload-file.component';
import { ConfirmDialogComponent } from '../component/confirm-dialog.component';

import { ParkingSpotInterface } from '../interface/parking-spot.interface';
import { UserRequestFileInterface } from '../interface/user-request-file.interface';
import { CondominiumInterface } from '../interface/condominium.interface';
import { GateInterface } from '../interface/gate.interface';

import { sortByGate, sortByCode, sortByName, validateCPF } from '../common/util.common';

import style from '../style/user-request.style';
import CondominiumFloorPlanModalComponent from '../component/condominium-floor-plan-modal.component';

const useStyles = makeStyles({
  buttonDiv: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
    justifyContent: 'center',
  },
  buttonFindSpot: {
    color: '#6abeff',
  },
});

const schema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  cpf: yup
    .string()
    .required('Campo obrigatório')
    .matches(cpfRegExp, {
      message: 'O CPF deve estar no formato 000.000.000-00',
    })
    .min(14, 'Mínimo de 11 caracteres')
    .max(14)
    .test('test-cpf', 'CPF inválido', (cpfValue) => {
      return validateCPF(cpfValue);
    }),
  condominiumId: yup.string().required('O campo é obrigatório'),
  gateIndex: yup.string().required('O campo é obrigatório'),
  parkingSpotId: yup.string().required('O campo é obrigatório'),
});

interface UserDataInterface {
  name: string;
  cpf: string;
  isTheLegalGuardian: boolean;
  condominiumId: string;
  gateIndex: string;
  parkingSpotId: string;
}

const UserRequestParkingSpotPage: React.FC = () => {
  const classes = style();
  const matchMinScreenSize = useMediaQuery('(min-width: 400px)');
  const classesAux = useStyles({ matchMinScreenSize });
  const { authenticatedUser, token } = useAuthContext();
  const {
    setMenuPageById,
    goToPageRouteById,
    setHeaderLoading,
    isHeaderLoading,
  } = useMenuContext();
  const { displaySnack } = useSnackbarContext();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserDataInterface>();
  const [condominium, setCondominium] = useState<CondominiumInterface>();
  const [gate, setGate] = useState<GateInterface>();
  const [parkingSpot, setParkingSpot] = useState<ParkingSpotInterface>();
  const [userRequestId, setUserRequestId] = useState<number>();
  const [openFindSpot, setOpenFindSpot] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<
    UserRequestFileInterface[]
  >([]);
  const [
    openRequestConfirmationDialog,
    setOpenRequestConfirmationDialog,
  ] = React.useState(false);
  const [
    openCondominiumFloorPlanModal,
    setOpenCondominiumFloorPlanModal,
  ] = React.useState(false);
  const [
    userRequestCreationIsInProcess,
    setUserRequestCreationIsInProcess,
  ] = useState<boolean>(false);
  const useFormMethods = useForm<UserDataInterface>({
    resolver: yupResolver(schema),
    mode: 'all',
    shouldFocusError: true,
  });
  const { watch, handleSubmit, setValue, clearErrors } = useFormMethods;
  const isTheLegalGuardian = watch('isTheLegalGuardian');

  useEffect(() => { setMenuPageById(MenuPageIdEnum.USER_REQUEST_PARKING_SPOT); },[]);

  const clearParkingSpot = useCallback((): void => {
    setParkingSpot(null);
    setValue('parkingSpotId', '');
    clearErrors('parkingSpotId');
  }, [clearErrors, setValue]);

  const clearGate = useCallback((): void => {
    setGate(null);
    setValue('gateIndex', '');
    clearErrors('gateIndex');
    clearParkingSpot();
  }, [clearParkingSpot, clearErrors, setValue]);

  const onLoadingSelectError = useCallback(
    (errorMessage: string): void => {
      setHeaderLoading(false);
      // goToPageRouteById(MenuPageIdEnum.CONDOMINIUMS);
      displaySnack(errorMessage, 'error');
    },
    [displaySnack],
  );

  const getCondominiumsForSelect = useCallback(async (): Promise<
    CondominiumInterface[]
  > => {
    setHeaderLoading(true);
    const conds = await getCondominiums(token, true, AvailableFor.PARKING_SPOT);
    return conds.sort(sortByName);
  }, [token]);
  const onChangingCondominium = useCallback(
    (condominium: CondominiumInterface): void => {
      if (!condominium) setHeaderLoading(false);
      setCondominium(condominium);
      clearGate();
    },
    [clearGate],
  );

  const getGatesForSelect = useCallback(async (): Promise<GateInterface[]> => {
    setHeaderLoading(true);
    const gates = await getParkingSpotGates(condominium.id, token, true);
    if (!gates || !gates.length)
      throw Error(
        'Não existem vagas disponíveis para vínculo nesse condomínio',
      );
    gates.forEach((gate, index) => (gate.index = index + 1));

    return await gates.sort(sortByGate);

  }, [condominium, token]);

  const onChangingGate = useCallback((gate: GateInterface): void => {
    if (!gate) setHeaderLoading(false);
    setGate(gate);
    setParkingSpot(null);
    setHeaderLoading(false);
  }, []);

  const getParkingSpotForSelect = useCallback(async (): Promise<
    ParkingSpotInterface[]
  > => {
    setHeaderLoading(true);
    const parkingSpots = await getParkingSpots(
      condominium.id,
      gate.gate,
      token,
    );
    if (!parkingSpots || !parkingSpots?.length)
      throw Error('Não existem vagas disponíveis para vínculo nesse acesso');

    return await parkingSpots.sort(sortByCode);
  }, [gate, condominium, token]);

  const onChangingParkingSpot = useCallback(
    (parkingSpot: ParkingSpotInterface): void => {
      if (!parkingSpot) setHeaderLoading(false);
      setParkingSpot(parkingSpot);
      setHeaderLoading(false);
    },
    [],
  );

  const uploadFile = useCallback(
    async (file: File): Promise<UserRequestFileInterface> => {
      try {
        const data = new FormData();
        data.append('file', file);
        data.append('userRequestId', String(userRequestId));
        return await uploadUserRequestFile(data, token);
      } catch (errorMessage) {
        throw errorMessage;
      }
    },
    [displaySnack, token, userRequestId],
  );

  const uploadFiles = useCallback(
    async (files: File[]): Promise<void> => {
      try {
        setHeaderLoading(true);
        const newFilesUploaded: UserRequestFileInterface[] = [];
        for (const file of files) {
          const uploadResponseFile = await uploadFile(file);
          newFilesUploaded.push(uploadResponseFile);
        }
        setUploadedFiles((oldFiles) => [...oldFiles, ...newFilesUploaded]);
        displaySnack('Arquivos adicionados com sucesso', 'success');
      } catch (errorMessage) {
        displaySnack(errorMessage, 'error');
      } finally {
        setHeaderLoading(false);
      }
    },
    [uploadFile, uploadedFiles],
  );

  async function onFileDelete(data: File): Promise<void> {
    if (!uploadedFiles.length || isHeaderLoading) return;

    const { id }: UserRequestFileInterface = uploadedFiles.find(
      (file: UserRequestFileInterface) =>
        file?.name === data?.name && file?.type === data?.type,
    );

    try {
      setHeaderLoading(true);
      await deleteUserRequestFile(id, token);
      setUploadedFiles((oldFiles) =>
        oldFiles.filter((file: UserRequestFileInterface) => file.id !== id),
      );
      displaySnack('Arquivo removido', 'success', false);
    } catch (error) {
      displaySnack(error, 'error');
    } finally {
      setHeaderLoading(false);
    }
  }

  useEffect(() => {
    if (!parkingSpot || userRequestCreationIsInProcess) return;
    setUserRequestCreationIsInProcess(true);
    (async (): Promise<void> => {
      try {
        setHeaderLoading(true);
        const { id } = await createUserRequestParkingSpot(
          RequestTypeEnum.PARKING_SPOT,
          new Date(),
          authenticatedUser.id,
          condominium.id,
          parkingSpot.id,
          false,
          authenticatedUser.cpf || cpfMock,
          authenticatedUser.name,
          0,
          token,
        );
        setUserRequestId(id);
      } catch (error) {
        displaySnack(error, 'error');
      } finally {
        setHeaderLoading(false);
      }
    })();
  }, [
    parkingSpot,
    authenticatedUser,
    condominium,
    token,
    userRequestCreationIsInProcess,
  ]);

  const confirmCondominiumRegistration = async (): Promise<void> => {
    setIsSending(true);
    const sendFilesToEmail = uploadedFiles.map(
      (file: UserRequestFileInterface) => ({
        name: file?.name,
        path: file?.path,
      }),
    );

    try {
      await confirmUserRequestParkingSpot(
        userRequestId,
        authenticatedUser.id,
        condominium.id,
        parkingSpot.id,
        isTheLegalGuardian,
        userData.cpf,
        userData.name,
        uploadedFiles.length,
        authenticatedUser.email,
        authenticatedUser.name,
        sendFilesToEmail,
        RequestTypeEnum.PARKING_SPOT,
        parkingSpot.code,
        gate.gate,
        condominium.name,
        token,
      );
      setIsSending(false);
      goToPageRouteById(MenuPageIdEnum.MY_SPOTS);
    } catch (errorMessage) {
      setIsSending(false);
      setOpenRequestConfirmationDialog(false);
      displaySnack(errorMessage, 'error');
    }
  };

  function submit(userData: UserDataInterface): Promise<void> {
    setUserData(userData);

    if(uploadedFiles.length <= 0){
      displaySnack('Pelo menos um arquivo deve ser anexado!', 'error');
      return;
    }

    if(!isTheLegalGuardian){
      displaySnack('A opção "Sou o responsável por esta vaga" deve ser marcada!', 'error');
      return;
    }
    
    if (isHeaderLoading) return;
    setOpenRequestConfirmationDialog(true);
  }

  return (
    <>
      <ContainerComponent>
        <form className={classes.form} onSubmit={handleSubmit(submit)}>
          {useMemo(
            () => (
              <DynamicSelectComponent
                inputName="condominiumId"
                title="Selecione o condomínio"
                attributeValue="id"
                attributeToDisplay="name"
                useFormMethods={useFormMethods}
                shouldLoadData={true}
                fullWidth={true}
                disabled={false}
                onLoadingError={onLoadingSelectError}
                onChange={onChangingCondominium}
                getEntityData={getCondominiumsForSelect}
              ></DynamicSelectComponent>
            ),
            [
              useFormMethods,
              onLoadingSelectError,
              onChangingCondominium,
              getCondominiumsForSelect,
            ],
          )}

          {useMemo(
            () => (
              <DynamicSelectComponent
                inputName="gateIndex"
                title="Selecione o acesso"
                attributeValue="index"
                attributeToDisplay="gate"
                useFormMethods={useFormMethods}
                shouldLoadData={!!condominium}
                fullWidth={true}
                disabled={!condominium}
                onLoadingError={onLoadingSelectError}
                onChange={onChangingGate}
                getEntityData={getGatesForSelect}
              ></DynamicSelectComponent>
            ),
            [
              useFormMethods,
              condominium,
              onLoadingSelectError,
              onChangingGate,
              getGatesForSelect,
            ],
          )}

          {useMemo(
            () => (
              <DynamicSelectComponent
                inputName="parkingSpotId"
                title="Selecione a vaga"
                attributeValue="id"
                attributeToDisplay="code"
                useFormMethods={useFormMethods}
                shouldLoadData={!!gate}
                fullWidth={true}
                disabled={!gate}
                onLoadingError={onLoadingSelectError}
                onChange={onChangingParkingSpot}
                getEntityData={getParkingSpotForSelect}
              ></DynamicSelectComponent>
            ),
            [
              useFormMethods,
              gate,
              onLoadingSelectError,
              onChangingParkingSpot,
              getParkingSpotForSelect,
            ],
          )}

          <div className={classesAux.buttonDiv}>
            <Button className={classesAux.buttonFindSpot} disabled={!!condominium ? false : true}
              onClick={(): void => setOpenFindSpot(!openFindSpot)}>
              Toque aqui e localize sua vaga
            </Button>
          </div>

          { openFindSpot ? 
            <CondominiumFloorPlanModalComponent
              open={openCondominiumFloorPlanModal}
              setOpen={setOpenCondominiumFloorPlanModal}
              condominiumParam={condominium}
            /> : null }

          <FormOwnerUserDataComponent
            useFormMethods={useFormMethods}
            title="Informe os dados do responsável"
            checkBoxText="Sou o responsável por esta vaga"
          />
          <Box className={classes.uploadFilesBox}>
            <UploadFileComponent
              onUpload={uploadFiles}
              onDelete={onFileDelete}
              disabled={!userRequestId || isHeaderLoading}
              text={
                !!userRequestId
                  ? 'Toque para adicionar até 3 arquivos que comprovem o vínculo com a vaga indicada. Sugerimos o DOC do condomínio ou outro documento que demonstre sua relação com o imóvel'
                  : 'Preencha todos os dados acima'
              }
            />
          </Box>
          <div className={classes.buttonDiv}>
            <Button
              type="submit"
              disabled={uploadedFiles.length === 0 ? true : false}
              className={classes.buttonSubmit}
            >
              Enviar Dados
            </Button>
          </div>
          <ConfirmDialogComponent
            acceptButtonText={'Aceitar'}
            declineButtonText={'Cancelar'}
            open={openRequestConfirmationDialog}
            onAccept={confirmCondominiumRegistration}
            onClose={(): void => setOpenRequestConfirmationDialog(false)}
            viewProgressBar={isSending}
            text={
              isSending
                ? 'Sua solicitação está sendo enviada...'
                : 'Sua solicitação de vínculo será enviada e você receberá uma resposta em breve. Outras vagas poderão ser vinculadas posteriormente, acessando o menu da aplicação.'
            }
            title={'Confirmar solicitação de vínculo'}
          />
        </form>
      </ContainerComponent>
    </>
  );
};

export default UserRequestParkingSpotPage;
