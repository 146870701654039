import React, { useState } from 'react';
import {
  SpeedButtonActionInterface,
  SpeedButtonPropsInterface,
} from '../interface/speed-button.interface';
import { Backdrop, makeStyles } from '@material-ui/core';
import { useMenuContext } from '../context/menu.context';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import { useAuthContext } from '../context/auth.context';

const useStyles = makeStyles(() => ({
  root: {
    transform: 'none',
    flexGrow: 1,
    display: 'flex',
    position: 'sticky',
    justifyContent: 'flex-end',
    flex: 1,
  },
  speedDial: {
    position: 'fixed',
    bottom: 30,
    cursor: 'pointer',
  },
  speedialAction: {
    margin: '10px',
  },
}));

const isPushNotificationSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window

export function SpeedButtonComponent(
  props: SpeedButtonPropsInterface,
): JSX.Element {
  const classes = useStyles();
  const { goToPageRouteById } = useMenuContext();
  const { userNotificationSubscription } = useAuthContext();
  const [openOptions, setOpenOptions] = useState(false);
  const [hiddenOption] = useState(false);

  if(isPushNotificationSupported()){
    if (Notification.permission === 'default' &&
    userNotificationSubscription.askForPermission)
    return null;
  };

  return (
    <div className={classes.root}>
      <Backdrop open={openOptions} />
      <SpeedDial
        FabProps={{
          style: {
            background: '#6abeff',
          },
        }}
        ariaLabel="Speed button"
        className={classes.speedDial}
        hidden={hiddenOption}
        icon={props.icon}
        onClose={(): void => setOpenOptions(false)}
        onOpen={(): void => setOpenOptions(true)}
        open={openOptions}
      >
        {props.actions.map((action: SpeedButtonActionInterface, index) => (
          <SpeedDialAction
            FabProps={{
              style: {
                fontWeight: 'bold',
              },
            }}
            className={classes.speedialAction}
            key={index}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={(): void =>
              goToPageRouteById(
                action.menuPageId,
                action?.param,
                action?.queries,
              )
            }
          />
        ))}
      </SpeedDial>
    </div>
  );
}
