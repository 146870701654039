import React, { useEffect } from 'react';
import { useMenuContext } from '../context/menu.context';
import { RouteComponentProps } from 'react-router-dom';
import { ContainerComponent } from '../component/container.component';

export function BuildingPage(props: RouteComponentProps): JSX.Element {
  const { setMenuPage, menuPages } = useMenuContext();
  const menuPage = menuPages.find(
    (menuPage) => menuPage.path === props.match.path,
  );

  useEffect(() => {
    if (menuPage) setMenuPage(menuPage);
  }, [menuPage, setMenuPage]);

  return (
    <ContainerComponent>
      <p>Página em construção...</p>
    </ContainerComponent>
  );
}
