import { useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import { ContainerComponent } from '../component/container.component';
import TransactionStatementComponent from '../component/transaction-statement.component';
import { useAuthContext } from '../context/auth.context';
import { useMenuContext } from '../context/menu.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';

const TransactionStatementPage: React.FC = () => {
  const { authenticatedUser } = useAuthContext();
  const { setMenuPageById } = useMenuContext();
  const isMobile = useMediaQuery('(max-width:800px)');
  useEffect(() => { setMenuPageById(MenuPageIdEnum.TRANSACTIONS_STATEMENT); },[]);
  return isMobile ? (
    <TransactionStatementComponent walletId={authenticatedUser?.wallet?.id} />
  ) : (
    <ContainerComponent>
      <TransactionStatementComponent walletId={authenticatedUser?.wallet?.id} />
    </ContainerComponent>
  );
};

export default TransactionStatementPage;
