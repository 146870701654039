import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import InputMask from 'react-input-mask';
import {
  TextField,
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Modal,
} from '@material-ui/core';
import { signup } from '../service/auth.service';
import { useSnackbarContext } from '../context/snackbar.context';
import { useMenuContext } from '../context/menu.context';
import { phoneRegExp } from '../common/regex.common';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { VagasIcon } from '../icons';

const useStyles = makeStyles({
  textField: {
    marginTop: '1em',
  },
  signupButton: {
    marginTop: '2em',
    paddingLeft: 1,
  },
  circularProgressContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgress: {
    fontSize: 70,
  },
  logo: {
    display: 'block',
    marginTop: '35px',
    marginBottom: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const schema = yup.object().shape({
  name: yup
    .string()
    .min(10, 'O nome deve ter no mínimo 10 letras')
    .required('O nome é obrigatório'),
  telephone: yup
    .string()
    .matches(phoneRegExp, {
      message: 'O número deve estar no formato (00) 00000-0000',
    })
    .length(15, 'Número inválido')
    .required('O telefone é obrigatório'),
  email: yup
    .string()
    .email('O email é inválido')
    .required('O email é obrigatório'),
  password: yup
    .string()
    .min(6, 'Sua senha deve ter no mínimo 6 dígitos')
    .required('A senha é obrigatória.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'As senhas são diferentes')
    .required('A confirmação da senha é obrigatória.'),
});

interface SignupInputInterface {
  name: string;
  telephone: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export function SignupComponent(): JSX.Element {
  const [open, setOpen] = useState<boolean>(false),
    [userName, setUserName] = useState<string>(''),
    [isComponentLoading, setComponentLoading] = useState<boolean>(false),
    classes = useStyles(),
    { goToPageRouteById } = useMenuContext(),
    { displaySnack } = useSnackbarContext(),
    { register, handleSubmit, errors } = useForm<SignupInputInterface>({
      resolver: yupResolver(schema),
      mode: 'all',
      shouldFocusError: true,
    });
  const onSubmit = async ({
    name,
    email,
    password,
    telephone,
  }: SignupInputInterface): Promise<void> => {
    try {
      setComponentLoading(true);
      const response = await signup(name, email, password, telephone);
      if (response.name && response.email) {
        setUserName(response.name);
        setOpen(true);
      }
    } catch (message) {
      setComponentLoading(false);
      displaySnack(`${message}`, 'error');
    }
  };

  const handleClose = (): void => {
    setOpen(false);
    goToPageRouteById(MenuPageIdEnum.LOGOUT);
  };

  return (
    <div>
      <Modal
        open={isComponentLoading}
        className={classes.circularProgressContainer}
      >
        <React.Fragment>
          <CircularProgress className={classes.circularProgress} />
        </React.Fragment>
      </Modal>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Bem vindo(a) ${userName}!`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Um email foi enviado para você com um link para confirmar seu
            cadastro.
            <br />
            Você só poderá acessar sua conta quando confirmar seu email, ok!?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            ENTENDI
          </Button>
        </DialogActions>
      </Dialog>

      <VagasIcon className={classes.logo} fill="lightgray" fontSize={125} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          className={classes.textField}
          label="Nome"
          fullWidth
          name="name"
          error={!!errors.name}
          helperText={errors?.name?.message}
          inputRef={register}
        />
        <InputMask placeholder="" mask="(99) 99999-9999">
          {(): JSX.Element => (
            <TextField
              className={classes.textField}
              label="Telefone"
              fullWidth
              name="telephone"
              error={!!errors.telephone}
              helperText={errors?.telephone?.message}
              inputRef={register}
            />
          )}
        </InputMask>

        <TextField
          className={classes.textField}
          label="Email"
          fullWidth
          name="email"
          error={!!errors.email}
          helperText={errors?.email?.message}
          inputRef={register}
        />
        <TextField
          className={classes.textField}
          label="Senha"
          name="password"
          fullWidth
          type="password"
          autoComplete="current-password"
          error={!!errors.password}
          helperText={errors?.password?.message}
          inputRef={register}
        />
        <TextField
          className={classes.textField}
          label="Confirmar Senha"
          name="confirmPassword"
          fullWidth
          type="password"
          autoComplete="current-password"
          error={!!errors.confirmPassword}
          helperText={errors?.confirmPassword?.message}
          inputRef={register}
        />

        <Button className={classes.signupButton} color="primary" type="submit">
          Cadastrar
        </Button>
      </form>
    </div>
  );
}
