import React from 'react';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '2px',
  },
});

export function ContainerComponent({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const classes = useStyles();
  return (
    <Container className={classes.container} maxWidth="md">
      {children}
    </Container>
  );
}
