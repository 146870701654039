import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import {
  TextField,
  makeStyles,
  Button,
  Typography,
  CircularProgress,
  Modal,
} from '@material-ui/core';
import { useAuthContext } from '../context/auth.context';
import { login } from '../service/auth.service';
import { useSnackbarContext } from '../context/snackbar.context';
import { MenuPageIdEnum } from '../enum/menu-page-id.enum';
import { useMenuContext } from '../context/menu.context';
import { VagasIcon } from '../icons';
import { ForgotMyPasswordComponent } from './forgot-password.component';
import { getUserApartmentRequests } from '../service/user.service';

const useStyles = makeStyles({
  textField: {
    marginTop: '1em',
  },
  circularProgressContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgress: {
    fontSize: 70,
  },
  forgotPasswordButton: {
    color: '#666666',
    display: 'flex',
    marginTop: '-30px',
    justifyContent: 'flex-end',
    fontSize: 15,
    cursor: 'pointer',
  },
  loginButton: {
    marginTop: '2em',
    paddingLeft: 1,
  },
  logo: {
    display: 'block',
    marginTop: '45px',
    marginBottom: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  termsOfServiceContainer: {
    width: '100%',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  termsOfService: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
  },
});

const schema = yup.object().shape({
  email: yup
    .string()
    .email('O email é inválido')
    .required('O email é obrigatório'),
  password: yup
    .string()
    .min(6, 'A senha possui no mínimo 6 digitos')
    .required('A senha é obrigatória.'),
});

interface LoginInputInterface {
  email: string;
  password: string;
}

export function LoginComponent(): JSX.Element {
  const classes = useStyles(),
    { setAuthenticatedUserAndToken } = useAuthContext(),
    [isComponentLoading, setComponentLoading] = useState<boolean>(false),
    { setCondominiums, setCondominium, goToPageRouteById } = useMenuContext(),
    { displaySnack } = useSnackbarContext(),
    [openForgotPasswordModal, setOpenForgotPasswordModal] = useState<boolean>(
      false,
    ),
    { register, handleSubmit, errors } = useForm<LoginInputInterface>({
      resolver: yupResolver(schema),
      mode: 'all',
      shouldFocusError: true,
    });
  const onSubmit = async (loginInput: LoginInputInterface): Promise<void> => {
    try {
      setComponentLoading(true);
      const result = await login(loginInput.email, loginInput.password);
      if (result.token && result.user) {
        setAuthenticatedUserAndToken(result.user, result.token);
        const { condominiums } = result.user;
        if (condominiums.length) {
          setCondominium(condominiums[0]);
          setCondominiums(condominiums);
          goToPageRouteById(MenuPageIdEnum.HOME);
        } else {
          const has = result.user.type === 'ADMIN' ? true : await getUserApartmentRequests(result.user.id, result.token);
          if(!has){
            goToPageRouteById(MenuPageIdEnum.USER_REQUEST_APARTMENT);
          }else{
            goToPageRouteById(MenuPageIdEnum.HOME);
          }
        }
      }
    } catch (message) {
      setComponentLoading(false);
      displaySnack(`${message}`, 'error', true);
    }
  };

  return (
    <div>
      <VagasIcon fontSize={125} fill="lightgray" className={classes.logo} />
      <Modal
        open={isComponentLoading}
        className={classes.circularProgressContainer}
      >
        <React.Fragment>
          <CircularProgress className={classes.circularProgress} />
        </React.Fragment>
      </Modal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          className={classes.textField}
          label="Email"
          fullWidth
          name="email"
          error={!!errors.email}
          helperText={errors?.email?.message}
          inputRef={register}
        />
        <TextField
          className={classes.textField}
          name="password"
          fullWidth
          label="Senha"
          type="password"
          autoComplete="current-password"
          error={!!errors.password}
          helperText={errors?.password?.message}
          inputRef={register}
        />
        <Button className={classes.loginButton} color="primary" type="submit">
          Entrar
        </Button>
        <Typography
          onClick={(): void => setOpenForgotPasswordModal(true)}
          className={classes.forgotPasswordButton}
        >
          Esqueceu a senha?
        </Typography>
      </form>
      <ForgotMyPasswordComponent
        open={openForgotPasswordModal}
        onConfirm={(): void => setOpenForgotPasswordModal(false)}
        onClose={(): void => setOpenForgotPasswordModal(false)}
      />
      <div className={classes.termsOfServiceContainer}>
        <div className={classes.termsOfService}>
          <p>
            Ao fazer login você concorda com nossos
            <br />
            <a href={'/terms'}>Termos de Uso</a>
          </p>
        </div>
      </div>
    </div>
  );
}
