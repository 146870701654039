import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { ContainerComponent } from '../component/container.component';

import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  detectDayOfWeekFullString,
  getTheNextStartTimeAt15MinuteInterval,
} from '../common/util.common';
import { CloseIcon, TrashCanIcon } from '../icons';
import HourOClockDotComponent from './hour-o-clock-dot.component';
import BrokenHourDotComponent from './broken-hour-dot.component';
import { ParkingSpotRentalTimeInterface } from '../interface/parking-spot-rental-time.interface';
import { useSnackbarContext } from '../context/snackbar.context';
import { useAuthContext } from '../context/auth.context';
import { AvailabilityIntervalInterface } from '../interface/availability-interval.interface';
import { editAvailabilitiesOfDate } from '../service/parking-spot.service';
import { useMenuContext } from '../context/menu.context';
import moment from 'moment';

const useStyles = makeStyles({
  header: {
    backgroundColor: '#6ABEFF',
    borderRadius: '0px',
    height: '20vh',
    color: 'white',
  },
  listContainer: {
    height: '50vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  reservationText: {
    color: '#6ABEFF',
  },
  inputsContainer: {
    padding: 10,
  },
  buttonBackground: {
    WebkitBoxShadow: '2px 2px 10px rgba(0,0,0,0.4)',
    MozBoxShadow: '2px 2px 10px rgba(0,0,0,0.4)',
  },
  removeButton: { borderRadius: 0, height: '100%' },
  closeButton: {
    width: 20,
    height: 60,
    marginLeft: 5,
    marginTop: 5,
    borderRadius: 100,
  },
  blueTextButton: {
    color: '#6ABEFF',
    width: '100%',
    height: 70,
  },
  daygrid: {
    width: '100%',
    marginLeft: '-80px',
  },
  day: {
    color: 'white',
    marginTop: -30,
    fontSize: 15,
    fontWeight: 'bold',
  },
  infoText: {
    marginLeft: '5%',
    marginRight: '5%',
  },
});

interface CancelReservationAlertProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const CancelReservationAlertComponent: React.FC<CancelReservationAlertProps> = ({
  open,
  handleClose,
  handleConfirm,
}: CancelReservationAlertProps) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      Você deseja cancelar esta reserva?
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Ao confirmar, você estará cancelando esta reserva que acontecerá na sua
        vaga, ela não poderá ser retomada.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleConfirm} color="primary">
        Confirmar
      </Button>
      <Button onClick={handleClose} color="primary" autoFocus>
        Fechar
      </Button>
    </DialogActions>
  </Dialog>
);

interface HeaderEditAvailabilityProps {
  date: string;
  onClose: () => void;
}

const HeaderEditAvailabilityComponent: React.FC<HeaderEditAvailabilityProps> = ({
  date,
  onClose,
}: HeaderEditAvailabilityProps) => {
  const classes = useStyles();
  const matchScreenSize = useMediaQuery('(min-width: 400px)');
  return (
    <Paper elevation={2} className={classes.header}>
      <Grid
        container
        direction="column"
        justify="center"
        style={{ height: '100%' }}
      >
        <Grid item xs={12}>
          <Button className={classes.closeButton} onClick={onClose}>
            <CloseIcon height={20} fill="white" />
          </Button>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.daygrid}
          item
          xs={12}
        >
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {detectDayOfWeekFullString(
                new Date(date + 'T00:00:00').getDay(),
                matchScreenSize,
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" className={classes.day}>
              {moment(date).format('DD/MM')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

class IntervalClass {
  id: string;
  startTime: Date;
  endTime: Date;
  reservationId: number;

  constructor(
    startTime: Date,
    endTime: Date,
    reservationId?: number,
    id?: string,
  ) {
    this.id = id || '_' + Math.random().toString(36).substr(2, 9);
    this.startTime = startTime;
    this.endTime = endTime;
    this.reservationId = reservationId || null;
  }

  private formatTime(hour: number, minute: number): string {
    return `${hour < 10 ? '0' + hour : hour}:${
      minute < 10 ? '0' + minute : minute
    }`;
  }

  getEndTime(): string {
    const minutes = this.endTime.getMinutes();
    const hours = this.endTime.getHours();
    return this.formatTime(hours, minutes);
  }

  getStartTime(): string {
    const minutes = this.startTime.getMinutes();
    const hours = this.startTime.getHours();
    return this.formatTime(hours, minutes);
  }

  getTimes(): AvailabilityIntervalInterface {
    return {
      startTime: this.getStartTime(),
      endTime: this.getEndTime(),
      reservationId: this.reservationId,
    };
  }
}

interface TimeSlotFormItemProps {
  isOldTime: boolean;
  onRemoveItem: () => void;
  onChangeItem: (interval: IntervalClass) => void;
  timesValue: IntervalClass;
}

const TimeSlotFormItemComponent: React.FC<TimeSlotFormItemProps> = ({
  onRemoveItem,
  timesValue,
  onChangeItem,
  isOldTime,
}: TimeSlotFormItemProps) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid
        item
        xs={2}
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <HourOClockDotComponent
          style={{ marginTop: !!timesValue.reservationId ? 22 : 5 }}
        />
        <BrokenHourDotComponent
          style={{
            height: 6,
            display: 'flex',
            alignItems: 'center',
          }}
        />
        <BrokenHourDotComponent
          style={{
            height: 6,
            display: 'flex',
            alignItems: 'center',
          }}
        />
        <BrokenHourDotComponent
          style={{
            height: 6,
            display: 'flex',
            alignItems: 'center',
          }}
        />
        <HourOClockDotComponent isAvailable={false} isReserved={false} />
      </Grid>
      <Grid item xs={7} className={classes.inputsContainer}>
        {!timesValue.reservationId || (
          <Typography className={classes.reservationText} variant="caption">
            Horário Reservado
          </Typography>
        )}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            fullWidth
            ampm={false}
            style={{ textAlign: 'right' }}
            format="HH:mm"
            placeholder="Início"
            value={timesValue.startTime}
            minutesStep={15}
            disabled={!!timesValue.reservationId || isOldTime}
            onChange={(value): void =>
              onChangeItem(
                new IntervalClass(
                  value,
                  timesValue.endTime,
                  timesValue.reservationId,
                  timesValue.id,
                ),
              )
            }
          />
          <TimePicker
            fullWidth
            ampm={false}
            format="HH:mm"
            placeholder="Fim"
            value={timesValue.endTime}
            minutesStep={15}
            disabled={!!timesValue.reservationId || isOldTime}
            onChange={(value): void =>
              onChangeItem(
                new IntervalClass(
                  timesValue.startTime,
                  value,
                  timesValue.reservationId,
                  timesValue.id,
                ),
              )
            }
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={3}>
        <Button
          fullWidth
          onClick={onRemoveItem}
          variant="contained"
          color="secondary"
          className={classes.removeButton}
        >
          <TrashCanIcon height={30} fill="white" />
        </Button>
      </Grid>
    </Grid>
  );
};

interface EditAvailabilityComponentProps {
  open: boolean;
  onClose: () => void;
  date: string;
  parkingSpotRentalTimes: ParkingSpotRentalTimeInterface[];
  parkingSpotId: number;
}

const EditAvailabilityComponent: React.FC<EditAvailabilityComponentProps> = ({
  open,
  onClose,
  date,
  parkingSpotRentalTimes,
  parkingSpotId,
}: EditAvailabilityComponentProps) => {
  const classes = useStyles();
  const [intervals, setIntervals] = useState<IntervalClass[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [reservationIntervalId, setReservationIntervalId] = useState<string>(
    '',
  );
  const { token } = useAuthContext();
  const { setHeaderLoading, isHeaderLoading } = useMenuContext();
  const { displaySnack } = useSnackbarContext();

  const sortRentalTimesByTimeSlotId = (
    rentalTimeA: ParkingSpotRentalTimeInterface,
    rentalTimeB: ParkingSpotRentalTimeInterface,
  ): number => {
    if (rentalTimeA.timeSlotId < rentalTimeB.timeSlotId) return -1;
    if (rentalTimeA.timeSlotId > rentalTimeB.timeSlotId) return 1;
    return 0;
  };

  // eslint-disable-next-line complexity
  useEffect(() => {
    parkingSpotRentalTimes?.sort(sortRentalTimesByTimeSlotId);
    const intervalsArray: IntervalClass[] = [];
    for (
      let currentIndex = 0;
      currentIndex < parkingSpotRentalTimes?.length;
      currentIndex++
    ) {
      let intervalInstance: IntervalClass;
      const currentRentalTime = parkingSpotRentalTimes[currentIndex];
      let finalTime = '';
      let auxiliaryIndex = 0;
      for (
        let nextIndex = currentIndex;
        nextIndex < parkingSpotRentalTimes?.length;
        nextIndex++
      ) {
        const nextRentalTime = parkingSpotRentalTimes[nextIndex];
        if (
          nextRentalTime.timeSlotId ===
            currentRentalTime.timeSlotId + auxiliaryIndex &&
          nextRentalTime.reservationId === currentRentalTime.reservationId
        )
          finalTime = nextRentalTime.finalTime;
        else if (finalTime !== '') {
          currentIndex = nextIndex - 1;
          auxiliaryIndex++;
          intervalInstance = new IntervalClass(
            new Date(`${date}T${currentRentalTime.initialTime}:00`),
            new Date(`${date}T${finalTime || currentRentalTime.finalTime}:00`),
            currentRentalTime.reservationId,
          );
          break;
        }
        if (nextIndex === parkingSpotRentalTimes.length - 1) {
          currentIndex = parkingSpotRentalTimes.length;
          intervalInstance = new IntervalClass(
            new Date(`${date}T${currentRentalTime.initialTime}:00`),
            new Date(`${date}T${finalTime || currentRentalTime.finalTime}:00`),
            currentRentalTime.reservationId,
          );
          break;
        }
        auxiliaryIndex++;
      }
      intervalsArray.push(intervalInstance);
    }

    setIntervals(
      intervalsArray.length === 0
        ? [
            new IntervalClass(
              new Date(
                `1999-01-01T${
                  date === moment().format('yyyy-MM-DD')
                    ? getTheNextStartTimeAt15MinuteInterval(
                        moment().format('HH:mm'),
                      )
                    : '00:00'
                }:00`,
              ),
              new Date('1999-01-01T00:00:00'),
            ),
          ]
        : intervalsArray,
    );
  }, [open]);

  const handleCloseModal = (): void => {
    setIntervals([]);
    onClose();
  };

  const midnightCondition = (realTime: string): string =>
    realTime === '00:00' ? '24:00' : realTime;

  const pastHoursCondition = (interval: IntervalClass): boolean =>
    date === moment().format('yyyy-MM-DD') &&
    (midnightCondition(interval.getEndTime()) <= moment().format('HH:mm') ||
      midnightCondition(interval.getStartTime()) < moment().format('HH:mm'));

  const onSubmitForm = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();
    let errors = 0;
    for (const currentInterval of intervals) {
      if (
        midnightCondition(currentInterval.getEndTime()) <=
        currentInterval.getStartTime()
      ) {
        errors++;
        break;
      }
      for (let nextIndex = 0; nextIndex < intervals.length; nextIndex++) {
        const nextInterval = intervals[nextIndex];
        if (intervals.indexOf(currentInterval) === nextIndex) nextIndex++;
        else if (
          !(
            midnightCondition(currentInterval.getEndTime()) <=
              nextInterval.getStartTime() ||
            currentInterval.getStartTime() >=
              midnightCondition(nextInterval.getEndTime())
          )
        ) {
          errors++;
          break;
        }
      }
      if (
        moment(currentInterval.getStartTime()).format('HH:mm') <
          moment(new Date()).format('HH:mm') &&
        moment(currentInterval.getStartTime()).format('yyyy-MM-DD') === moment(new Date()).format('yyyy-MM-DD')) {
        errors++;
        break;
      };
    }
    if (errors !== 0) {
      displaySnack('Intervalos inválidos!', 'error');
      return;
    }

    try {
      setHeaderLoading(true);
      const successMessage = await editAvailabilitiesOfDate(
        parkingSpotId,
        date,
        intervals.map((interval) => interval.getTimes()),
        token,
      );
      displaySnack(successMessage, 'success');
    } catch (errorMessage) {
      displaySnack(errorMessage, 'error');
    } finally {
      handleCloseModal();
      setHeaderLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <ContainerComponent>
        <Paper elevation={3}>
          <CancelReservationAlertComponent
            open={dialogOpen}
            handleClose={(): void => {
              setReservationIntervalId('');
              setDialogOpen(false);
            }}
            handleConfirm={(): void => {
              setIntervals(
                intervals.filter(
                  (interval) => interval.id !== reservationIntervalId,
                ),
              );
              setReservationIntervalId('');
              setDialogOpen(false);
            }}
          />
          <HeaderEditAvailabilityComponent
            date={date}
            onClose={handleCloseModal}
          />
          <form onSubmit={onSubmitForm}>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.buttonBackground}
            >
              <Button
                type="submit"
                className={classes.blueTextButton}
                disabled={isHeaderLoading}
              >
                Salvar
              </Button>
            </Grid>
            <div className={classes.listContainer}>
              {intervals.map((inputInterval, index) => (
                <TimeSlotFormItemComponent
                  isOldTime={
                    date === moment().format('yyyy-MM-DD') &&
                    midnightCondition(inputInterval.getEndTime()) <=
                      moment().format('HH:mm')
                  }
                  key={inputInterval.id}
                  timesValue={inputInterval}
                  onChangeItem={(interval: IntervalClass): void => {
                    if (pastHoursCondition(interval)) {

                      displaySnack('Disponibilidade inválida', 'error', false);
                      return;
                    }

                    setIntervals((oldIntervals) => {
                      const auxiliaryIntervals = [...oldIntervals];
                      auxiliaryIntervals.splice(index, 1, interval);
                      return auxiliaryIntervals;
                    });
                  }}
                  onRemoveItem={(): void => {
                    if (!!inputInterval.reservationId) {
                      setReservationIntervalId(inputInterval.id);
                      setDialogOpen(true);
                    } else if (
                      date === moment().format('yyyy-MM-DD') &&
                      midnightCondition(inputInterval.getEndTime()) <=
                        moment().format('HH:mm')
                    ) {
                      displaySnack(
                        'Você não pode editar disponibilidades antigas',
                        'error',
                        false,
                      );
                    } else if (!isHeaderLoading)
                      setIntervals(
                        intervals.filter(
                          (interval) => interval.id !== inputInterval.id,
                        ),
                      );
                  }}
                />
              ))}
            </div>
          </form>
          <div className={classes.infoText}>
            <Typography color="textSecondary" variant="caption">
              ATENÇÃO: Você só poderá cancelar uma disponibilidade que tenha
              sido reservada, até 24 horas antes do seu início.
            </Typography>
          </div>
          <Button
            className={classes.blueTextButton}
            fullWidth
            disabled={isHeaderLoading}
            onClick={(): void => {
              let time = moment().format('HH:mm');
              time = getTheNextStartTimeAt15MinuteInterval(time);
              setIntervals((oldState) => [
                ...oldState,
                new IntervalClass(
                  new Date(`${date}T${time}:00`),
                  new Date(date + 'T00:00:00'),
                ),
              ]);
            }}
          >
            adicionar intervalo
          </Button>
        </Paper>
      </ContainerComponent>
    </Modal>
  );
};
export default EditAvailabilityComponent;
