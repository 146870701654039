import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  progressBar: {
    marginTop: 30,
    marginBottom: 20,
  },
});

interface ConfirmDialogComponentProps {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
  title: string;
  text: string;
  declineButtonText: string;
  acceptButtonText: string;
  viewProgressBar: boolean;
}

export const ConfirmDialogComponent: React.FC<ConfirmDialogComponentProps> = ({
  open,
  title,
  text,
  onClose,
  onAccept,
  declineButtonText,
  acceptButtonText,
  viewProgressBar,
}: ConfirmDialogComponentProps) => {
  const classes = useStyles();

  /*   const onCloseButton = (): void => {
    onClose();
  }; */
  /*   const onAcceptButton = (): void => {
    onAccept();
  }; */
  return (
    <Dialog
      open={open}
      onClose={(): void => onClose()}
      aria-labelledby={title}
      aria-describedby={text}
      disableBackdropClick
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        {viewProgressBar ? (
          <LinearProgress className={classes.progressBar} />
        ) : null}
      </DialogContent>
      {viewProgressBar ? null : (
        <DialogActions>
          <Button onClick={(): void => onClose()} color="primary">
            {declineButtonText}
          </Button>
          <Button onClick={(): void => onAccept()} color="primary" autoFocus>
            {acceptButtonText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
